body {
  color      : $textColor;
  font-family: $pageFont;
  font-size  : 100%;
  line-height: $lineHeight;
  background : $bodyBg;
}

p {
  color        : $paraColor;
  font-size    : $fontSize;
  line-height  : 24px;
  font-weight  : $normal;
  font-family  : $pageFont;
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 2rem;
}

.bgWhite {
  background-color: #fff;
}

.white {
  color: #ffffff !important;
}

.align {
  &.left {
    text-align: left !important;
  }

  &.right {
    text-align: right !important;
  }

  &.center {
    text-align: center !important;
  }
}

.clr {
  clear: both;
}


.bannerText {
  position: relative;
  z-index : 1;
}

.sectionTitle {
  font-size  : $font48px;
  line-height: 64px;
  font-weight: $bold;
}

.sectionTitleMedium {
  font-size  : $font34px;
  line-height: 51px;
  font-weight: 600;
  word-wrap  : break-word;
}

.sectionTitle2 {
  font-size  : $font24px;
  color      : $darkColor;
  font-weight: 700;
  line-height: 44px;
}

.sectionpara,
.sectionpara p {
  color        : $paraColor;
  font-size    : 1rem;
  line-height  : 24px;
  margin-top   : 1rem;
  margin-bottom: 1.75rem;
}

ul {
  padding   : 0px;
  margin    : 0px;
  list-style: none;
}

ol {
  margin: 0px;
}

.a_link {
  font-size      : 1rem;
  line-height    : 22px;
  text-decoration: none !important;
  color          : $primaryColor !important;
  cursor         : pointer;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color  : $placeholderColor !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $placeholderColor !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $placeholderColor !important;
}

.accordion {
  .card {
    border       : 0px;
    position     : relative;
    border-bottom: 1px solid #000 !important;

    .card-header {
      background: $bodyBg;
      box-shadow: none;
      padding   : 0px;

      button {
        padding      : 1.5rem 1rem;
        padding-right: 35px;
        display      : block;
        width        : 100%;
        text-align   : left;
        background   : transparent;
        border       : 0px !important;
        outline      : none;
        font-size    : 18px;
        line-height  : 27px;
        color        : #000000;
        border-bottom: 1px solid #ddd !important;
      }
    }

    .card-body {
      background : $bodyBg;
      font-size  : 15px;
      line-height: 1.5;
      color      : #6e6e6e;
      text-align : justify;
    }
  }

  .blew-shrink {
    top             : 0;
    position        : absolute;
    right           : 0;
    background-color: #f5f8ff;
    height          : 28px;
    font-size       : 0.875rem;
    width           : 28px;
    display         : flex;
    border-radius   : 100%;
    align-items     : center;
    justify-content : center;

    &:after {
      content            : "";
      height             : 12px;
      width              : 12px;
      background-image   : url("/assets/images/carsouelArrow.png");
      background-repeat  : no-repeat;
      background-size    : contain;
      background-position: center;
    }
  }

  .card.active .blew-shrink {
    transform: rotate(-90deg);
  }
}

.alert-block {
  text-align: center;

  .alert {
    display: inline-block;
  }
}

.loading-wrapper {
  min-height: 500px;
  position: relative;
  display: flex;
}

.sectionpara2 {
  font-size  : $font16px;
  line-height: 21px;
  color      : $paraColor;
}

.toast {
  position       : fixed;
  top            : 30px;
  border         : 0px;
  box-shadow     : none;
  width          : 100%;
  border-radius  : 0px;
  justify-content: center;
  left           : 0px;
  max-width      : 100%;
  flex-basis     : 100%;
  background     : transparent;
  border-radius  : 0px;

  &.show {
    display: inline-flex !important;
  }

  .toast-header {
    display      : inline-flex;
    border-radius: 0px;
    background   : #000;
    color        : #fff;
    padding      : 15px 35px;

    button.close {
      display: none;
    }
  }
}

.closeIcon {
  top     : 30px;
  position: absolute;
  right   : 30px;
  cursor  : pointer;
}

.slick-slide div:focus {
  outline: none;
}

.faqul {
  li {
    list-style : disc;
    margin-left: 20px;
  }
}

.faqWhite {
  background: #fff;

  .faqAccordion.accordion {
    .card {

      &,
      .card-header,
      .card-body {
        background: $bodyBg;
      }
    }
  }
}

.faqAccordion.accordion {
  .card {
    padding      : 15px 20px;
    border-radius: 4px;
    border       : 0;
    margin-bottom: 1rem;
    background   : #fff;
    border-bottom: none !important;

    .card-header,
    .card-body {
      background: #fff;
    }

    .card-body {
      padding    : 1rem 0px;
      color      : $paraColor;
      font-size  : $font14px;
      line-height: 24px;
    }

    .card-header {
      border     : 0px !important;
      font-size  : 1rem;
      line-height: 24px;
      font-weight: 700;

      button {
        padding: 0px;
        border : 0px !important;

        span {
          font-size    : $font16px;
          line-height  : 24px;
          font-weight  : 700;
          padding-right: 20px;
          display      : inline-block;
        }

        .blew-shrink {
          top          : 15px;
          right        : 15px;
          padding-right: 0px;
          display      : flex;
        }
      }
    }
  }
}

.toastOverride {
  z-index: 1051;
  top    : 100px;
}

.formDesign {
  padding      : 20px 30px 40px 30px;
  background   : #fff;
  border-radius: 8px;
  box-shadow   : $boxShadow;
  background   : #0000007a;

  .formtexts {
    .formTitle {
      font-weight: bold;
      font-size  : $font20px;
      line-height: 30px;
      text-align : center;
      margin-top : 20px;
      color      : #fff;
    }

    .formPara {
      color      : #fff;
      font-size  : $font14px;
      line-height: 21px;
      text-align : center;
      width      : 75%;
      margin     : 20px auto;
      margin-top : 10px;
    }
  }

  .label {
    color      : #fff;
    font-size  : $font14px;
    line-height: 21px;
  }
}

.engineerForm {
  width   : 70%;
  float   : right;
  position: relative;
  z-index : 1;
}

.nextBatchRow {
  background: $bodyBg;
  position  : absolute;
  width     : 100%;
  bottom    : 70px;
  padding   : 2rem 0px;

  .container {
    display    : flex;
    align-items: center;

    span {
      font-size  : $font20px;
      line-height: 30px;
      color      : $darkColor;
      font-weight: 600;
    }

    .label {
      font-size    : $font16px;
      line-height  : 22px;
      color        : #fff;
      background   : $yellowColor;
      border-radius: 27px;
      padding      : 12px 30px;
      margin-left  : 1rem;
      font-weight  : 600;
    }
  }
}

.highlightCard {
  text-align: center;

  .h_title {
    font-size  : $font16px;
    margin     : 0.5rem auto 1rem;
    font-weight: 600;
    // width: 192px;
  }

  .h_para {
    font-size    : $font14px;
    color        : $paraColor;
    margin-bottom: 1.5rem;
  }

  .h_icon {
    margin-top: 1rem;
  }
}

.iconsprite {
  background-image : url("/assets/images/sprite_icons.webp");
  background-repeat: no-repeat;
  height           : 23px;

  &.closeIcon {
    background-position: -320px -2px;
    height             : 30px;
    width              : 30px;
  }

  &.calendarIcon {
    background-position: -39px -40px;
    width              : 25px;
  }

  &.clockIcon {
    background-position: -163px -38px;
    width              : 23px;
  }

  &.settingsIcon {
    background-position: -240px -5px;
    width              : 23px;
  }

  &.jobIcon {
    background-position: -40px -3px;
    width              : 25px;
    height             : 25px;
  }

  &.projectIcon {
    background-position: -160px -1px;
    width              : 24px;
    height             : 29px;
  }

  &.trainerIcon {
    background-position: -362px -6px;
    width              : 32px;
  }

  &.fileContentIcon {
    background-position: -259px -41px;
    width              : 23px;
    height             : 25px;
  }

  &.certifiedIcon {
    background-position: -224px -40px;
    width              : 23px;
    height             : 30px;
  }

  &.headsetIcon {
    background-position: -292px -45px;
    width              : 29px;
    height             : 30px;
  }

  &.whiteCallIcon {
    background-position: -130px -40px;
    width              : 27px;
    height             : 20px;
    display            : inline-block;
    position           : relative;
  }

  &.linkedin {
    width              : 20px;
    background-position: -220px -83px;
  }
}

.border-card {

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    border-right : 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
  }

  &:nth-child(4) {
    border-bottom: 1px solid $borderColor;
  }

  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    border-right: 1px solid $borderColor;
  }
}

.batch-modal {
  .custom-arrows .slick-arrow {
    &.slick-prev {
      z-index: 1;
      left   : 48%;
    }
  }

  .modal-body {
    padding: 35px;

    .topicslist {
      li {
        flex     : 0 0 33.33%;
        max-width: 33.33%;
      }
    }
  }

  .modalSmallTitle {
    padding-bottom: 10px;
    font-size     : $font14px;
    line-height   : 27px;
    color         : $darkColor;
  }
}

.slick-slider.batchCarsouel {
  margin: 0px 15px;

  .slick-current {
    .modal-batch-card {
      background: $primaryColor;
      color     : #fff;
      transform : scale(1);

      .btn {
        display   : block;
        background: #fff !important;
        color     : $primaryColor;
      }
    }

    .batch-title,
    ul li p {
      color: #fff;
    }
  }
}

.slick-slider.batchCarsouel .slick-active.slick-current,
.hightlight_card {
  ul {
    li {
      &:before {
        transform: scale(0.8);
        opacity  : 1;
      }

      &.calendar {
        &:before {
          background-position: -5px -80px !important;
        }
      }

      &.class {
        &:before {
          background-position: -73px -84px !important;
        }
      }

      &.clock {
        &:before {
          background-position: -334px -46px !important;
        }
      }
    }
  }
}

.batchCardIcons {
  li {
    position: relative;

    &:before {
      background-image : url("/assets/images/sprite_icons.webp");
      transform        : scale(0.7);
      opacity          : 0.6;
      content          : "";
      position         : absolute;
      left             : 0px;
      top              : 5px;
      height           : 25px;
      width            : 25px;
      background-repeat: no-repeat;
    }

    &.calendar {
      &:before {
        background-position: -40px -80px;
      }
    }

    &.class {
      &:before {
        background-position: -110px -83px;
      }
    }

    &.clock {
      &:before {
        background-position: -370px -45px;
      }
    }
  }
}

.infoContent {
  .batchCardIcons {
    li {
      padding-left: 30px !important;
      padding-top : 8px !important;

      &.calendar {
        &:before {
          background-position: -3px -80px;
        }
      }

      &.class {
        &:before {
          background-position: -72px -83px;
        }
      }

      &.clock {
        &:before {
          background-position: -334px -45px;
        }
      }
    }
  }
}

.modal-batch-card {
  background   : #f5f8ff;
  border-radius: 8px;
  padding      : 20px;
  margin-top   : 30px;
  transform    : scale(0.9);
  cursor       : pointer;

  .batch-title {
    border-bottom : 1px solid #d8d8d8;
    text-align    : center;
    padding-bottom: 8px;
    margin-bottom : 10px;
    color         : $darkColor;
    font-size     : $font16px;
    font-weight   : 600;
    line-height   : 24px;
  }

  .btn {
    width     : 100%;
    min-width : auto;
    margin-top: 1rem;
    display   : none;
  }

  ul {
    li {
      margin-bottom: 1.3rem;
      position     : relative;
      padding-left : 35px;
      padding-top  : 3px;

      &:last-child {
        margin-bottom: 0px;
      }

      &:before {
        content          : "";
        position         : absolute;
        left             : 0px;
        top              : 5px;
        background-repeat: no-repeat;
      }

      &.calendar {
        &:before {
          background-image   : url("/assets/images/sprite_icons.webp");
          background-position: -370px -45px;
          height             : 25px;
          width              : 25px;
        }
      }

      &.class {
        &:before {
          background-image   : url("/assets/images/sprite_icons.webp");
          background-position: -40px -80px;
          height             : 25px;
          width              : 25px;
        }
      }

      &.clock {
        &:before {
          background-image   : url("/assets/images/sprite_icons.webp");
          background-position: -110px -83px;
          height             : 25px;
          width              : 25px;
        }
      }

      label {
        font-size  : $font14px;
        line-height: 22px;
        color      : $darkColor;
        font-weight: 600;
      }

      p {
        font-size  : $font14px;
        line-height: 21px;
        color      : $paraColor;
      }
    }
  }
}

.topicsUl {
  display  : flex;
  flex-wrap: wrap;
  padding  : 0px 15px;

  li {
    color        : $paraColor;
    position     : relative;
    padding-left : 27px;
    margin-bottom: 1rem;
    font-size    : $font14px;
    line-height  : 21px;
    padding-right: 15px;
    max-width    : 50%;
    flex         : 0 0 50%;

    &:before {
      content      : "";
      position     : absolute;
      height       : 10px;
      width        : 10px;
      background   : $primaryColor;
      border-radius: 100%;
      top          : 4px;
      left         : 3px;
    }
  }
}


.enroll-text {
  margin-top    : 5px;
  color         : #fff;
  background    : $diamond-color;
  font-size     : 12px;
  letter-spacing: .5px;
  display       : inline-block;
  padding       : 5px 12px;
  border-radius : 6px;
  margin-bottom : 15px;
}

.engg_tuition {
  &.commonsection {
    padding: 30px 0px;
  }

  .enroll-text {
    border-radius   : 40px;
    background-color: #FFC300;
    margin-bottom   : 30px;
    font-size       : 14px;
    padding         : 10px 20px;
  }
}

.height50 {
  height: 55px;
}


.cardEngineering {
  border-radius: 0.5rem !important;
  margin-top   : 120px !important;
  border       : none !important;
  box-shadow   : 0px 0px 16px rgba(0, 0, 0, 0.14) !important;

  .sectionTitlemobile {
    text-align : center;
    color      : #241f48;
    margin-top : 12px;
    font-weight: bold;
    font-size  : 16px;
  }

  .sectionparamobile {
    padding-top: 14px;
    text-align : center;
    font-size  : 12px;
    color      : $paraColorMobile;
  }

  .button_load {
    display        : flex;
    justify-content: space-around;

    .call {
      margin-top: 20px;
      min-width : 13px !important;
      height    : 43px !important;
      background: none !important;
      color     : $primaryColor !important;
      border    : 1px solid #F5F8FF !important;
      font-size : $font12px !important;
    }

    .spiriteicon.phonecallicon {
      background-position: -152px -114px;
      transform          : scale(0.7);
      position           : relative;
      top                : 1px;
      height             : 30px;
    }

    .courseshow {
      margin-top: 20px;
      min-width : 13px !important;
      height    : 43px !important;
      font-size : $font12px !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .border-card {

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5) {
      border-right : 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
    }

    &:nth-child(3),
    &:nth-child(6) {
      border-right : 0px;
      border-bottom: 1px solid $borderColor;
    }

    &:nth-child(7),
    &:nth-child(8) {
      border-right: 1px solid $borderColor;
    }
  }

  .batch-modal {
    .modal-dialog {
      max-width: 98%;
    }

    .modal-body {
      padding: 1rem;
    }
  }

  .formDesign {
    .formtexts {
      .formTitle {
        color: $darkColor;
      }

      .formPara {
        color: $paraColor
      }
    }

    .label {
      color: $paraColor
    }
  }

  .closeIcon {
    transform: scale(0.7);
  }
}

.bgBody {
  background-color: $bodyBg;
}


.scrollcss {
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width           : 5px;
    background-color: #EC5578;
  }

  &::-webkit-scrollbar-thumb {
    border-radius   : 45px;
    background-color: #EC5578;
  }
}

.schooltuition,
.engineeringtution,
.corporatePage,
.NeetPage,
.abcdPage,
.placementPage,
.getmeplaced,
.schoolcoding,
.internship,
.cedPage,
.prdPage,
.amdPage,
.pdPage,
.vlsiPage,
.dvPage,
.icdPage,
.jeeHome,
.BootCampPage,
.mechPage {
  .callbackbtn {
    right: 0px;
  }
}


.callbackbtn {
  position : fixed;
  right    : 20px;
  transform: rotate(-90deg) translate(0px, 84px);
  width    : auto;
  height   : auto;
  top      : 40%;
  z-index  : 1;

  .btn {
    border-radius: 8px 8px 0px 0px;

    &.courseshow {
      display: none;
    }
  }

  .learnerCard {
    .learnerDesc {
      .label {
        font-size: $font14px !important;
      }
    }
  }

  .course-track-tab-group .slick-slider {
    margin: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .border-card {
    border-right : 0px !important;
    border-bottom: 1px solid $borderColor !important;

    &:last-child {
      border: 0px !important;
    }
  }

  .form-modal .engineerForm {
    padding: 10px 20px;
  }

  .formDesign .formtexts .formPara {
    width: 100%;
  }

  .topicsUl {
    li {
      max-width: 100%;
      flex     : 0 0 100%;
    }
  }

  .closeIcon {
    top  : 15px;
    right: 15px;
  }

  .height50 {
    height: 0px;
  }

  .callbackbtn,
  .callbackbtnbootcamp {
    display: none;
  }

  .placementPage {
    footer {
      padding-bottom: 250px !important;
    }
  }

  .highlightCard {
    .h_para {
      font-size    : $font12px;
      color        : $paraColorMobile;
      margin-bottom: 0.5rem;
    }
  }

  .getmeplaced {
    footer {
      padding-bottom: 16rem !important;
    }
  }

  
  .callbackbtn {
    right          : inherit;
    transform      : rotate(0deg) translate(0px, 0px);
    left           : 0px;
    background     : #fff;
    padding        : 10px;
    top            : inherit;
    z-index        : 2;
    display        : flex;
    justify-content: space-around;
    bottom         : 0px;
    width          : 100%;
    text-align     : center;
    box-shadow     : 0px -6px 8px #00000036;

    .btn {
      border-radius: 4px;
      min-width    : auto;
      padding      : 5px 1rem;
      height       : auto;
      font-size    : $font14px;

      &.call {
        min-width : 13px !important;
        height    : 43px !important;
        background: none !important;
        color     : $primaryColor !important;
        border    : 1px solid #F5F8FF !important;
        font-size : $font12px !important;
      }

      &.courseshow {
        display  : inline-flex;
        min-width: 13px !important;
        height   : 43px !important;
        font-size: $font12px !important;
      }
    }
  }

  main.engineeringtution,
  main.corporatePage,
  main.abcdPage,
  main.schooltuition,
  main.NeetPage,
  main.cedPage {
    footer {
      padding-bottom: 6rem !important;
    }
  }

  .learnsection .sectionTitleMedium,
  .learnebox .sectionTitleMedium{
    font-size  : $font16px;
    line-height: 24px;
    font-weight: bold;
  }

  .pdPage,
  .dvPage,
  .icdPage{
    .courseName .courseHead{
      font-size  : $font16px;
    }
  }

  main.schooltuition,
  main.placementPage,
  main.engineeringtution,
  main.getmeplaced,
  main.abcdPage,
  main.NeetPage,
  main.schoolcoding,
  main.cedPage,
  main.prdPage,
  main.pdPage,
  main.amdPage,
  main.vlsiPage,
  main.schoolPage,
  main.dvPage,
  main.jeeHome,
  main.BootCampPage,
  main.icdPage{
    .sectionTitle,
    .sectionTitle2,
    .sectionTitleMedium {
      font-size  : $font16px;
      line-height: 24px;
      font-weight: bold;
    }

    .placement_assistance_wrapper {
      .bg {
        margin: auto;
      }

      .sml_text {
        font-size: $font14px;
      }
    }
  }

  main.getmeplaced {
    footer {
      padding-bottom: 16rem !important;
    }
  }

  main.schooltuition {
    .sectionpara {
      color      : $paraColorMobile;
      font-size  : $font12px;
      line-height: 18px;
    }

    .highlightCard {
      text-align: center;
      width     : 92%;
      margin    : 15px 8px 20px 8px;
      padding   : 5px 15px;
      min-height: 300px;
    }
  }

  .enggTuitionHighlightProgram {
    .slick-slider {
      margin: 0px !important;
    }

    .slick-arrow {
      display: none !important;
    }
  }

  .placementPage .bottom-arrows .slick-arrow {
    bottom: -50px !important;
  }

  .learnerTestimonial .custom-arrows.bottom-arrows .slick-arrow {
    bottom: -70px !important;
  }

  .readMoreHighlight {
    font-weight: bold;
    white-space: nowrap;
  }

  .btn.btn_primary.modalbutton {
    min-width    : 100% !important;
    margin-top   : 8px;
  }
}

.confirmHeading {
  margin: 5px 0px 1rem;

  ~.btn {
    margin-bottom: 1.5rem;
  }
}

.tab-content {
  em {
    font-style: normal;
  }

  span,
  div,
  p,
  em,
  b,
  i,
  u {
    font-family: $fontName !important;
  }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-position: right calc(1em + 0.1875rem) center;
}

.pgmlistsection{
  min-height: 600px;
}

@media only screen and (max-width: 991px){
  .pgmlistsection{
    min-height: auto;
  }
}


@mixin screenPaddingTop {
  padding-top: 8em;

  @include for_media(mobileScreen) {
    padding-top: 0;
  }
}

@mixin for_media($breakpoints) {
  $conditions : ();
  @each $breakpoint in $breakpoints {
      // If the key exists in the map
      $conditions: append(
          $conditions,
          #{inspect(map-get($media_queries, $breakpoint))},
          comma
      );
  }

  @media #{$conditions} {
      @content;
  }
}