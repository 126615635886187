@import "assets/scss/main.scss";

h2 {
  font-family: $fontName;
  font-weight: bold;
}

.mainContent {
  text-align: center !important;

  h2 {
    color: $colorViolet;
  }
  

  @include for_media(mobileScreen) {
    padding: 0.5em !important;
  }

  h2,
  h4,
  p,
  .buttonContainer {
    margin-top: 1.8rem;

    @include for_media(mobileScreen) {
      margin-top: 0.8rem;
    }
  }

  h2 {
    font-size: 3.2rem;
    font-weight: bold;
    padding: 1.5rem 4rem 1rem 4rem;

    @media only screen and (max-width:768px) {
      font-size: 24px !important;
      padding: 1em;
      padding-bottom: 0;
    }
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 600;
    color: $paraColor;

    @media only screen and (max-width:768px) {
      font-size: 17px;
      line-height: 24px;
      margin-bottom: 1.5em !important;
      font-weight: 600;
      color: $paraColor;
    }
  }

  p {
    font-size: 1.2rem;
    @media only screen and (max-width:768px) {
      line-height: 24px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 1rem !important;
  }
}

.CampusContainer,
.integrateContainer,
.BlogContainer {
  display: flex;
  margin-top: 5rem;

  h1 {
    font-size: 36px !important;
  }

  h2 {
    font-size: 28px;
  }

  p {
    font-size: 16px;
    padding-right: 5em;

    @media only screen and (max-width:768px) {
      padding-right: 0em;
    }
  }

  @include for_media(mobileScreen) {
    margin-top: 2rem;
    flex-direction: column;
  }

  padding: 1rem;
  gap: 1rem;

  div {
    display: flex;
    padding: 0 5rem 0 0;
    flex-direction: column;
    justify-content: center;

    @include for_media(mobileScreen) {
      padding: 0;

      li {
        font-family: $fontName;
        color: $paraColor;
      }

      h2,
      h4,
      p {
        text-align: center;
      }

      h2 {
        text-align: center;
      }
    }
  }
}

.CampusContainer {
  margin-top: 4rem;
  position: relative;
  @media only screen and (max-width:768px) {
   margin-top: 1em;
   ol{
    margin-left: -14px !important;
   }
  }

  .leftFloatImg {
    position: absolute;
    left: 0;
    top: 0;
  }

  .rightFloatImg {
    position: absolute;
    right: 0;
    top: 0;
  }

  div {
    padding-left: 1rem;
    font-size: 1.2rem;

    @media only screen and (max-width:768px) {
      padding-left: 0em;
    }

    h2 {
      font-size: 2rem;
      padding: 0 0 0rem 2rem;

      @media only screen and (max-width:768px) {
        font-size: 24px;
        text-align: left;
        padding: 0 0.5em;
      }
    }

    li {
      font-family: $fontName;
      color: $paraColor !important;
      margin: 10px 0 !important;

      @media only screen and (max-width:768px) {
        font-size: 16px;
        line-height: 1.5;
        margin: 1em 0em !important;
      }
    }
  }
}

.featuresContainer {
  display: flex;
  flex-direction: column;
  gap: 5rem;

  .featureHeading {
    text-align: center;
    font-size: 32px;
    text-transform: capitalize;
    margin-bottom: 4rem;
  }

  .plateformContainer {
    .featureHeading {
      text-align: center;
      margin-bottom: 4rem;
    }

    @include for_media(mobileScreen) {
      flex-direction: column;
      gap: 1rem;
    }

    div {

      p {
        cursor: pointer;
        font-size: 1.2rem;
      }
    }

    .content {
      width: 90%;

      .contentdiv {
        width: 100%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 50px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        padding: 0.8rem;
      }

      padding: 0.5rem;
      border: 3px dashed;
      border-radius: 10px;

      h2 {
        font-size: large;
        font-weight: bold;
      }

      p {
        font-size: small;
        line-height: 1.5;
      }
    }
  }
}


.activemodelLi {
  background: rgba(255, 65, 110, 0.1);
  border-radius: 12px;
  color: $primaryColor;
  font-weight: 700;
  font-size: 16px;
  padding: 15px 10px;
  cursor: pointer;
}

.activePlatformLi {
  color: $colorViolet;
  padding: 15px 10px;
  background: #F2EAFF;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.platformLi {
  color: $paraColor;
  padding: 15px 10px;
  font-size: 16px;
  cursor: pointer;
}

.platformHeader {
  text-align: center !important;
  @include for_media(mobileScreen){
    margin-top: 0em !important;
  }
}

.plateformContainer {
  .modelHeading {
    color: $primaryColor;
    @include for_media(mobileScreen) {
      font-size: 20px;
   }
  }

  .platformHeading {
    color: $colorViolet;
    @include for_media(mobileScreen) {
       font-size: 20px;
    }
  }

  h1 {
    text-align: center !important;
    width: 100%;
    margin-bottom: 1.5em;
  }

  img {
    border-radius: 12px !important;
    @include for_media(mobileScreen) {
      margin: 1em 0em;
    }
  }

  .clr20 {
    height: 60px;
    clear: both;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
  }
}

.svgContainer {
  display: flex;
  justify-content: center;
}

.BlogContainer {
  gap: 1em;
  align-items: center;
  margin-top: 1em;
  .blogImgSection{
    padding: 1em !important;
    width: 30%;
    @include for_media(mobileScreen){
      width: 100%;
    }
    img{
      width: 100% !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      transition: box-shadow 0.3s ease;
    }
  }
  .blogTextSection{
    width: 70%;
    @include for_media(mobileScreen){
      width: 100%;
    }
  }
  h1 {
    font-family: $fontName;
    text-align: center !important;
    font-weight: bold;
    margin-bottom: 1rem;

    @media only screen and (max-width:768px) {
      padding: 0 4rem 0rem 4rem;
    }
  }

  @media only screen and (max-width:768px) {
    h1 {
      font-size: x-large;
      margin-bottom: 0em;
    }

    h2 {
      font-size: 24px;
    }

    img {
      width: auto !important;
      margin-bottom: 2em !important;
    }
  }
}

.programSection {
  background-image: url("../../../../assets/images/home/programBg.webp");
  padding: 6% 20%;
  margin-top: 6rem;
  background-size: 100% 100%;
  font-family: 'Lato';
  font-style: normal;

  .header {
    color: $colorViolet !important;
    font-family: $fontName !important;
    line-height: 24px !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    margin-bottom: 0;
  }

  .subHeader {
    color: $grayColor7 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: 400 !important;
    margin-bottom: 1.2rem;
    margin-top: 10px;
  }

  p {
    font-size: 14px !important;
    line-height: 19px !important;
  }
}

.programTxt {
  font-family: $fontName;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  // letter-spacing: 0.29em;
  text-transform: capitalize;
  color: $black;
  text-align: center;
  margin-bottom: 0.5em;

  @include for_media(mobileScreen) {
    margin-bottom: 0em !important;
  }
}

.programMobSection {
  padding: 1em;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
  margin: 1em;

  .header {
    color: $colorViolet !important;
    font-family: $fontName !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    margin-bottom: 0;
  }

  .subHeader {
    color: $grayColor7 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0;
    line-height: 1.5;
  }

  p {
    font-size: 16px !important;
  }

  img {
    margin-bottom: 1em;
  }
}

.SlideBtnContiner {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;

}

.btn_primary{
  padding: 1em 2em;
  color: white !important;
  background-color: $primaryColor;
}