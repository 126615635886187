@import "assets/scss/config.scss";

.commonsection.bannerSection1 {
  padding-top: 90px;
  position: relative;
  padding-bottom: 90px;
  background-color: #fff;
  .bannerText {
    position: relative;
    z-index: 1;
  }
  .sectionpara, .sectionpara p {
    color: $paraColor;
    font-size: 1rem;
    line-height: 24px;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .btn-section .btn {
    min-width: 186px;
    margin-right: 10px;
  }
  &:after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: right;
    height: 100%;
    width: 100%;
    background-size: auto 100%;
    top: 0px;
    background-image: url("/assets/images/banner-9.png") !important;
  }
}

.btn-section {
  display: flex;
}

.commonsection {
  padding: 70px 0px;
}


.numberFlex {
  display: flex;
  li{
     max-width: 20%;
     flex: 0 0 20%;
     text-align: center;
     .label{
        color: $yellowColor;
        font-size: $font48px;
        font-weight: 700;
        margin: 0px;
     }
     p{
        margin: 0px;
        font-size: $font20px;
        color: $darkColor;
     }
  }
}

.half-centered-section {
  width: 50%;
  margin: 0px auto;
}


.learnCard {
  margin: 1rem auto;
  width: 95%;
  .label{
      font-size: $font20px;
      line-height: 30px;
      font-weight: 700;
      color: $darkColor;
      width: 85%;
  }
  p{
     font-size: $font14px;
     color: $paraColor;
  }
  img{
    background: #fff;
    border-radius: 100%;
  }
}


.centerPara {
  text-align: center;
  margin: auto;
  width: 70%;
  margin-top: 1rem;
  margin-bottom: 3rem;
}


.skillCourse {
  padding-bottom: 70px;
  .row{
    justify-content: center;
  }
}

.bannerTitle{
  font-size: 1.8rem;
  line-height: 65px;
}

.bannerSection {
  line-height: 40px !important;
  font-size: 17px;
}

.mobileProgram{
  padding-top: 80px;
  .center-navs {
    display: block;
    width: 100%;
  }
  & ~ #pgmsection{
    padding-top: 0px;
  }
}
.buyCourseBtn{
  padding:45px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  text-align: center;
}
