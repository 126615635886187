@import 'assets/scss/main.scss';

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.container {
  // width: 100%;
  // max-width: 100%;
  padding: 0; /* Avoid unnecessary padding */
  margin: 0 auto;
}

img, video {
  max-width: 100%;
  height: auto;
}

* {
  box-sizing: border-box; /* Include padding and borders in width calculations */
  // outline: 1px solid red;
}


.bannerHeaderText {
  color: #522e8f;
}

.heroBanner {
  text-align: center;
  padding: 30px;
  margin-top: 5em;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  .heroBannerImage {
    width: 60%;
  }
  .heroTxt{
    padding: 0% 20%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  h1{
    font-size: 36px;
    color: #522e8f;
    margin: 0%;
  }
  h2{
    margin: 0%;
  }
}



.stripSection {
  text-align: center;
  padding: 20px 10%;
  background-color: #522e8f;
  margin: 24px 0px;
 

  p {
    font-size: 20px;
    color: white;
  }
}

.programHighlights {
  text-align: center;
  padding: 24px;

  h1 {
    margin-bottom: 1.5em;
    font-size: 2em;
    font-weight: bold;
  }

  .productDetailsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;

    .productItem {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      border-radius: 10px;
      padding: 20px;
      width: 40%;
      transition: transform 0.3s, box-shadow 0.3s;
      text-align: left;
      background-color: #fbfbfb;
      cursor: pointer; // Makes the card look clickable

h3{
  margin: 0.6em 0em;
}

      h2{
        color: #522e8f !important;
      }

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      }

      .productImage {
        width: 30px;
        height: auto;
        margin-bottom: 1em;
      }

      h2 {
        font-size: 1.5em;
        color: #333;
        margin-bottom: 0.5em;
      }

      h3 {
        font-size: 1.2em;
        color: #666;
        margin-bottom: 1em;
      }

      ul {
        list-style-type: none;
        padding-left: 1.5em;

        li {
          font-size: 1em;
          margin-bottom: 0.5em;
          color: #444;
        }
      }
    }
  }
}
.benefitDiv{
  padding: 24px 6%;
}
.benefitDivOuter {
  align-self: center;
  text-align: center;
  background-image: url('../../../assets/images/Commerce/watercolorBG.webp');
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;

  h3 {
    color: #522e8f;
  }

  .benefitDivInner {
    display: flex;
    justify-content: center;
  }

  .benefitDivCont {
    margin-bottom: 16px; // Add some space between the items on smaller screens
  }

  // Mobile styles (for screens <= 768px)
  @media (max-width: 768px) {
    .benefitDivInner {
      flex-direction: column; // Stack the items vertically on smaller screens
      align-items: center; // Center-align the items
    }

    .benefitDivCont {
      width: 80%; // Increase the width to take up more space
    }
  }

  // Mobile styles for very small screens (for screens <= 480px)
  @include for_media(mobileScreen) {
    padding: 16px; // Reduce padding on very small screens
    margin: 16px 4%; // Adjust margin to fit smaller screens

    .benefitDivCont {
      width: 100%; // Ensure each item takes up the full width
    }

    h3 {
      font-size: 1.2rem; // Reduce font size for smaller screens
    }
  }
}


// Core Learning Modules Section
.coreLearningModules {
    
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

    gap: 20px;  // Space between the cards
    margin-top: 40px;  // Margin above the section
    padding: 20px 6%;  // Padding inside the section
  }
  
  // Individual Learning Module Card
  .learningModuleCard {
    background-color: #fff;  // White background for cards
    border-radius: 8px;  // Rounded corners
    overflow: hidden;  // Ensure the image doesn't overflow
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  // Soft shadow for cards
    transition: transform 0.3s ease-in-out;  // Smooth transition for hover effect
    padding: 10px;
    text-align: center;  // Center content
    width: 340px;
  }
  
  .learningModuleCard:hover {
    transform: translateY(-5px);  // Hover effect: lift card slightly
  }
  
  // Image inside each card
  .moduleImage {
    width: 100%;
    height: 200px;  // Set a fixed height for the images
    object-fit: cover;  // Ensure the image covers the area without distortion
    border-radius: 5px;  // Optional: Slightly round the corners of the image
  }
  
  // Title of the learning module
  .learningModuleCard h3 {
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;  // Dark color for text
    margin: 15px 0 10px;  // Space around the title
  }
  
  // List of points in each card
  .learningModuleCard ul {
    list-style-type: none;
    padding: 0;
    margin: 0;  
  }
  
  .learningModuleCard li {
    font-size: 1rem;
    color: #666;  // Lighter text color for the points
    margin: 8px 0;  // Space between each point
    line-height: 1.5;  // Increase line height for better readability
  }
  
  // Responsive adjustments (for mobile and small screens)
  @media (max-width: 768px) {
    .productItem{
      width: 100%;
    }
    .coreLearningModules {
      grid-template-columns: 1fr 1fr;  // 2 columns on medium screens
    }
  }
  
    @include for_media(mobileScreen) {
    .productItem{
      width: 100%;
    }

    .coreLearningModules {
      grid-template-columns: 1fr;  // 1 column on small screens
    }
  
    .learningModuleCard h3 {
      font-size: 1.2rem;  // Adjust title size on smaller screens
    }
  
    .learningModuleCard li {
      font-size: 0.9rem;  // Adjust list item size on smaller screens
    }
  }
  
  .integratedToolsContent{
    display: flex;
    justify-content: center;
    gap: 32px;
    
  }
// Tool Scroll Section
.toolScrollSection {
  text-align: center;
  background-color: #f5f5f5;
  margin: 24px 0px;
  padding: 20px;
}

.toolScrollContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;  // Hide logos when they are out of the container
  position: relative;
  width: 100%;  // Full width to enable scrolling across the screen
  animation: scrollTools 30s linear infinite;  // Continuous scroll without delay
  overflow-x: hidden; // Set on parent containers.

}

.toolScroll {
  width: auto;  // Adjust logo width as needed
  height: 60px;
  margin-right: 60px;  // Space between logos
}

// Tool Scroll Animation (Smooth Scroll)
@keyframes scrollTools {
  0% {
    transform: translateX(0);  // Start from the left
  }
  100% {
    transform: translateX(-100%);  // Move to the left, scrolling all logos out of view
  }
}

// Apply animation to the container
.toolScrollContainer {
  display: flex;
  animation: scrollTools 30s linear infinite;  // Continuous animation for scrolling
}

// Prevent logos from shrinking
.toolScrollContainer img {
  flex-shrink: 0;  // Prevent logos from shrinking
}

// Ensure container's width is sufficient for continuous scroll
.toolScrollContainer {
  width: max-content;  // Make the container width the total width of all logos
}

// Duplicate the logos for seamless scrolling
.toolScrollContainer {
  display: flex;
  flex-wrap: nowrap;  // Ensure no wrapping
}

.toolScrollContainer::after {
  content: "";
  width: 0;  // Ensure no extra space
  flex-shrink: 0;
}

.autoEvalutionDivOuter{
display: flex;
align-items: center;
justify-content: center;
gap: 3em;
background-image: url('../../../assets/images/Commerce/aecBG.webp');
}

.AutoEvaluationTitle{
  position: relative;

}

.AutoEvaluationTitleTxt{

  position: absolute;
  display: flex;
  flex-direction: column;
  top: 42%;
  width: 100%;
  text-align: center;
  font-size: 18px;
  h1{
    font-size: 1.5em;
    color: white;
    margin: 0px;

  }
}

.aeContentRight{
  text-align: left;
}

.autoEvaluationContent{
  display: flex;
  gap: 1em;
  flex-direction: column;
  width: 50%;
  h1{
    font-size: 2em;
    color: #9a9a9a;
  }
  h2{
    font-size: 1.2em;
    color: #522e8f;
  }
}
.autoEvaluationContent1,.autoEvaluationContent2,.autoEvaluationContent3{
  gap: 8px;
  width: 380px;
  display: flex;
  background-color: white;
  border-radius: 12px;
  box-shadow: 2px 2px 5px #80008061;
  padding: 1em 2em;
  align-items: center;
  h1{
    margin: 0px;
  }
  h2{
    margin: 0px;
  }
}

.autoEvaluationContent1{
  align-self: center;
}

.autoEvaluationContent2{
  align-self: flex-start;

}

.autoEvaluationContent3{
  align-self: flex-end;

}

.coreLearningModulesOuter{
  background-image: url('../../../assets/images/Commerce/watercolorBG.webp');
  text-align: center;
  padding: 24px;
}

.integratedToolOuter{
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 24px;
  gap: 20px;
  h1{
    font-size: 24px;
    margin: 0%;
  }
  h2{
    margin: 0%;
    font-size: 24px;
    color: #626262;

  }
  h3{
    color: #522e8f;
    margin: 0%;
  }
  p{
    font-size: 16px;
    color: #626262;
  }
}
.forWhom{
display: flex;
justify-content: center;
gap: 8px;
padding: 24px;
background-image: url('../../../assets/images/Commerce/watercolorBG.webp');
margin: 20px 0px;
img{
  width: 90%;
}
h2{
  font-size: 24px;
  color: #522e8f;
}
ul{
  list-style: circle;
  padding: 12px;
}
li{
  color: #626262;
}
}
.forWhomInner{
  padding: 20px;
  width: 42%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 2px 2px 5px #80008061;

}

.aeWorks{
  text-align: center;
  margin: 24px;
}

.aeWorksInner{
  display: flex;
gap: 16px;
justify-content: center;
padding: 20px;
align-items: center;
text-align: left;
p{
  color: #522e8f;
}
h1{
  font-size: 72px;
  color: #9a9a9a;
}
  
}
.vertLine{
  width: 3px;
  background-color: #c0c0c0;
  border-radius: 4px;
  height: 100px;
}
.aeWorksCont{
  display: flex;
  width: 260px;
  align-items: center;
  gap: 8px;
}

.programDeliveryDiv{
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-image: url('../../../assets/images/Commerce/watercolorBG.webp');
 margin: 24px 0px;
 h3{
  color: #522e8f;

 }
h2{
  color: #626262;
}
 p{
  color: #626262;
 }
 img{
  width: 30px ;
  height: auto;
 }
}

.subInner{
  display: flex;
  justify-content: center;
  gap:64px;
}

.subCont{
  width: 300px;
}
// General mobile styles for all sections

@media (max-width: 768px) {
  
  .productItem{
    width: 100% !important;
  }
  .vertLine{
    height: 2px;
    width: 60%;
  }
  // Hero Banner
  .heroBanner {
    padding: 20px;
    margin-top: 2em;
    .heroBannerImage {
      width: 80%;
    }
    .heroTxt {
      padding: 0 10%;
    }
    h1 {
      font-size: 1.8em; // Reduced font size
    }
    h2 {
      font-size: 1.3em; // Adjusted for mobile screens
    }
  }

  // Strip Section
  .stripSection {
    padding: 20px 5%;
  }

  // Program Highlights Section
  .programHighlights .productDetailsContainer {
    flex-direction: column;
    gap: 1em;
  }

  .programHighlights .productItem {
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .programHighlights .productItem h2 {
    font-size: 1.4em;
  }

  .programHighlights .productItem h3 {
    font-size: 1em;
  }

  .programHighlights .productItem ul {
    padding-left: 1em; // Reduced padding for smaller screens
  }

  // Core Learning Modules
  .coreLearningModules {
    grid-template-columns: 1fr 1fr; // 2 columns
  }

  .learningModuleCard {
    width: 90%; // Smaller cards
    padding: 15px;
  }

  .learningModuleCard h3 {
    font-size: 1.3rem;
  }

  .learningModuleCard li {
    font-size: 0.9rem;
  }

  // Integrated Tools Content
  .integratedToolsContent {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  // Tool Scroll Section
  .toolScrollContainer {
    width: 200%; // Ensures smooth scroll even on smaller screens
  }

  // Auto Evaluation Section
  .autoEvalutionDivOuter {
    flex-direction: column;
    gap: 2em;
  }

  .autoEvaluationContent {
    width: 90%;  // Take up more space on mobile
  }

  .autoEvaluationContent1, .autoEvaluationContent2, .autoEvaluationContent3 {
    width: 90%;
    padding: 1.5em;
  }

  .autoEvaluationContent1, .autoEvaluationContent2, .autoEvaluationContent3 {
    gap: 12px;
  }

  // For Whom Section
  .forWhom {
    flex-direction: column;
    gap: 1em;
  }

  .forWhomInner {
    width: 90%;
  }

  // AE Works Section
  .aeWorksInner {
    flex-direction: column;
    align-items: center;
  }

  .aeWorksCont {
    flex-direction: column;
    width: 80%;
    text-align: center;


  }

  // Program Delivery Section
  .programDeliveryDiv {
    padding: 20px;
  }

  .subInner {
    flex-direction: column;
    gap: 32px;
  }

  .subCont {
    width: 80%;
  }
  .programHighlights .productDetailsContainer {
    flex-direction: column;
    gap: 1em;
  }

  .programHighlights .productItem {
    width: 100%; // Ensure the item takes full width
    padding: 10px;
    text-align: center;
    box-sizing: border-box; // Ensure padding doesn't cause overflow
    margin: 0 auto; // Center the product item
  }

  .programHighlights .productItem h2 {
    font-size: 1.4em;
  }

  .programHighlights .productItem h3 {
    font-size: 1em;
  }

  .programHighlights .productItem ul {
    padding-left: 1em;
    margin: 0 auto; // Prevent overflow from list items
  }

  // Auto Evaluation Section
  .autoEvaluationContent {
    width: 100%;  // Ensure it fits within screen width
    padding: 1em; // Adjust padding to prevent overflow
  }

  .autoEvaluationContent1, .autoEvaluationContent2, .autoEvaluationContent3 {
    width: 100%; // Ensure full width for all auto evaluation content
    padding: 1.5em;
    box-sizing: border-box; // Avoid overflow due to padding
    margin: 0 auto; // Center the content
  }

  .autoEvaluationContent1, .autoEvaluationContent2, .autoEvaluationContent3 {
    gap: 12px;
  }

  // Adjust for For Whom Section
  .forWhom {
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    align-items: center;
  }

  .forWhomInner {
    width: 90%; // Ensure the item fits within mobile screen
    padding: 15px;
  }
}

// Mobile Styles for screens <= 480px (phones)
  @include for_media(mobileScreen) {

  .productItem{
    width: 100% !important;
  }

  .vertLine{
    height: 2px;
    width: 60%;
  }
  // Hero Banner
  .heroBanner {
    padding: 15px;
    margin-top: 1.5em;
    .heroBannerImage {
      width: 90%;
    }
    .heroTxt {
      padding: 0 5%;
    }
    h1 {
      font-size: 1.5em; // Adjusted for small screens
    }
    h2 {
      font-size: 1.2em; // Adjusted for smaller screens
    }
  }

  // Program Highlights Section
  .programHighlights .productItem {
    width: 100%;
    padding: 8px;
  }

  .programHighlights .productItem h2 {
    font-size: 1.2em;
  }

  .programHighlights .productItem h3 {
    font-size: 0.9em;
  }

  .programHighlights .productItem ul {
    padding-left: 1em;
  }

  // Core Learning Modules
  .coreLearningModules {
    grid-template-columns: 1fr; // 1 column for very small screens
  }

  .learningModuleCard {
    width: 100%;
    padding: 12px;
  }

  .learningModuleCard h3 {
    font-size: 1.1rem;
  }

  .learningModuleCard li {
    font-size: 0.8rem;
  }

  // Auto Evaluation Section
  .autoEvaluationContent {
    width: 100%;
  }

  .autoEvaluationContent1, .autoEvaluationContent2, .autoEvaluationContent3 {
    width: 100%;
    padding: 1em;
  }

  .forWhom {
    justify-content: center;
    align-items: center;

    flex-direction: column;
    padding: 10px;
  }

  .forWhomInner {
    width: 90%;
  }

  // AE Works Section
  .aeWorksInner {
    flex-direction: column;
    padding: 10px;
  }

  .aeWorksCont {
    flex-direction: column;
    gap: 8px;
    width: 80%;
    text-align: center;
  }

  // Program Delivery Section
  .programDeliveryDiv {
    padding: 15px;
  }

  .subInner {
    flex-direction: column;
    gap: 16px;
  }

  .subCont {
    width: 90%;
  }

  .programHighlights .productItem {
    width: 100%;  // Ensure the item takes full width
    padding: 8px; // Adjust padding for better fit
    box-sizing: border-box;
  }

  .programHighlights .productItem h2 {
    font-size: 1.2em;
  }

  .programHighlights .productItem h3 {
    font-size: 0.9em;
  }

  .programHighlights .productItem ul {
    padding-left: 1em;
    margin: 0 auto;
  }

  // Auto Evaluation Section
  .autoEvaluationContent {
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
  }

  .autoEvaluationContent1, .autoEvaluationContent2, .autoEvaluationContent3 {
    width: 100%; // Ensure content fits on small screens
    padding: 1em;
    box-sizing: border-box;
  }

  // Adjust for For Whom Section
  .forWhom {
    flex-direction: column;
    padding: 10px;
  }

  .forWhomInner {
    width: 90%; // Ensure the item fits within mobile screen
    padding: 15px;
  }
}