@import "assets/scss/config.scss";

.learnsection {
  .step-card {
    text-align: left;
    display: flex;
    align-items: center;
    width: 95%;
    margin: 1rem 0px;
    .step-title {
      font-size: 1.25rem;
      line-height: 30px;
      font-family: $fontName;
      font-weight: $bold;
      margin: 0px 0px 0.5rem;
    }
    .step-image {
      background: $grayColor6;
      max-width: 90px;
      flex: 0 0 90px;
      height: 90px;
      margin-right: 2rem;
      border-radius: 100%;
      padding: 10px;
    }
    .step-para {
      font-size: 1rem;
      color: $paraColor;
      line-height: 24px;
      width: 100%;
      margin: auto;
    }
  }
}

.learnbox_light {
  .learnimg {
    .learnSprite {
      margin: auto;
    }
  }
}

.learnimg {
  .learnSprite {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 100%;
  }
}

.learnSprite {
  background-image: url("/assets/images/sprite_learn.webp");
  background-repeat: no-repeat;
  height: 75px;
  width: 75px;
  &.iLearn {
    background-position: -329px -7px;
  }
  &.iDesign {
    background-position: -159px -13px;
  }
  &.iExplore {
    background-position: -240px -8px;
  }
  &.iAssess {
    background-position: -80px -7px;
  }
  &.iAnalyse {
    background-position: -8px -3px;
  }
  &.learn1 {
    background-position: -419px 3px;
    margin: auto;
  }
  &.learn2 {
    background-position: -519px 3px;
    margin: auto;
  }
  &.learn3 {
    background-position: -619px 3px;
    margin: auto;
  }
  &.learn4 {
    background-position: -719px 3px;
    margin: auto;
  }
  &.learn5 {
    background-position: -813px 3px;
    margin: auto;
  }
  &.learn6 {
    background-position: -909px -2px;
    margin: auto;
  }
  &.learn7 {
    background-position: -1008px 1px;
    margin: auto;
  }
  &.learn8 {
    background-position: -1107px 8px;
    margin: auto;
  }
  &.highlight1 {
    background-position: -257px -82px!important;
    margin: auto;
  }
  &.highlight2 {
    background-position: -166px -86px;
    margin: auto;
  }
  &.highlight3 {
    background-position: -734px -4px !important;
    margin: auto;
  }
  &.highlight4 {
    background-position: -433px -5px!important;
    margin: auto;
  }
  &.highlight5 {
    background-position: -84px -89px!important;
    margin: auto;
  }
  &.highlight6 {
    background-position: -634px -5px!important;
    margin: auto;
  }
  &.highlight7 {
    background-position: -330px -90px;
    margin: auto;
  }
  &.highlight8 {
    background-position: -6px -84px !important;
    margin: auto;
  }
  &.highlight9 {
    background-position: -735px -11px;
    margin: auto;
  }
  &.highlight10 {
    background-position: -830px -87px!important;
    margin: auto;
    transform: scale(0.9);
  }
  &.highlight11 {
    background-position: -1017px -95px !important;
    margin: auto;
  }
  &.highlight12 {
    background-position: -1090px -95px;
    margin: auto;
  }
  &.coding-1 {
    background-position: -651px -91px;
    margin: auto;
  }
  &.coding-2 {
    background-position: -573px -95px;
  }
  &.coding-3 {
    background-position: -487px -94px;
  }
  &.coding-4 {
    background-position: -410px -92px;
  }
  &.drawing1 {
    background-position: -572px -95px;
  }
  &.drawing2 {
    background-position: -489px -95px;
  }
  &.drawing3 {
    background-position: -413px -91px;
  }
  &.neet1 {
    background-position: -445px -184px;
    margin: auto;
  }
  &.neet2 {
    background-position: -573px -89px;
    margin: auto;
  }
  &.neet3 {
    background-position: -693px -187px;
    margin: auto;
  }
  &.neet4 {
    background-position: -766px -182px;
    margin: auto;
  }
  &.neet5 {
    background-position: -847px -184px;
    margin: auto;
  }
  &.neet6 {
    background-position: -823px -8px;
    margin: auto;
  }
  &.neet7 {
    background-position: -1011px -88px;
    margin: auto;
  }
  &.abcd1 {
    background-position: -287px -181px;
    margin: auto;
  }
  &.abcd2 {
    background-position: -446px -188px;
    margin: auto;
  }
  &.abcd3 {
    background-position: -606px -185px;
    margin: auto;
  }
  &.abcd4 {
    background-position: -520px -183px;
    margin: auto;
  }
  &.abcd5 {
    background-position: -366px -183px;
    margin: auto;
  }
  &.abcd6 {
    background-position: -207px -185px;
    margin: auto;
  }
  &.cedHighlight1{
    background-position: -116px -287px;
    margin: auto;
    width: 95px;
  }
  &.cedHighlight2{
    background-position: -336px -22px;
    margin: auto;
  }
  &.cedHighlight3{
    background-position: -735px -11px;
    margin: auto;
  }
  &.cedHighlight4{
    background-position: -250px -295px;
    margin: auto;
  }
  &.cedHighlight5{
    background-position: -90px -99px;
    margin: auto;
  }
  &.cedHighlight6{
    background-position: -836px -16px;
    margin: auto;
  }
  &.cedHighlight7{
    background-position: -664px -104px;
    margin: auto;
  }
  &.cedHighlight8{
    background-position: -1019px -102px;
    margin: auto;
  }
  &.campuses1 {
    background-position: -664px -104px;
    margin: auto;
  }
  &.campuses2 {
    background-position: -336px -22px;
    margin: auto;
  }
  &.campuses3 {
    background-position: -504px -105px;
    margin: auto;
  }
  &.campuses4 {
    background-position: -584px -112px;
    margin: auto;
  }
  &.campuses5 {
    background-position: -425px -103px;
    margin: auto;
  }
}
// .enggTuitionHighlightProgram{
//   background-image: url('/assets/images/engineeringtuitions/sprite_learn.webp');
//   background-repeat:  no-repeat;
//   height: 75px;
//   width: 75px;
//   &.iLearn{
//     background-position: -329px -7px;
//   }
//   &.iDesign{
//     background-position: -159px -13px;
//   }
// }
.h_title.whylearn {
  padding: 0px !important;
}

@media only screen and (max-width:768px){
  .learnimg{
    margin-top: -20px;
  }
}