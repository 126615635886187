@import "assets/scss/config.scss";
@import "assets/scss/main.scss";

.course-page-header {
  min-height: 300px;
  position: relative;
  // padding-top: 50px;
  @include for_media(mobileScreen){
    padding-top: 0px;
  }
}
.pgmsection {
  background-color: #ffffff;
  padding-top: 70px;
  padding-bottom: 70px;
  .centerPara {
    text-align: center;
    margin: auto;
    width: 70%;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .sectionpara {
    color: #7c7ca2;
    font-size: 1rem;
    line-height: 24px;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .center-navs {
    text-align: center;
  }
  .course-group {
    margin-top: 4em;
    h4 {
      color: $grayColor1;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: $bold;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      font-size: 1.8vw;
      margin-bottom: 1.5em;
      @media only screen and (max-width: $mobileScreen) {
        font-size: large;
      }
    }
  }
}
.catalogue-wrapper {
  display: flex !important;
  justify-content: center;
  gap: 1em;

  @media only screen and (max-width: $tabletScreen) {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
  }
}
.active-catlog-button {
  background-color: #ff416e;
  color: white;
  padding: 0.5em 1.5em 0.5em 1.5em;
  border-radius: 5px;
  cursor: pointer;

  @media only screen and (max-width: $tabletScreen) {
    display: flex;
    justify-content: center;
  }
}
.catalogue-button {
  border: 2px solid #ff416e;
  border-radius: 5px;
  color: #ff416e;
  padding: 0.5em 1.5em 0.5em 1.5em;
  cursor: pointer;

  @media only screen and (max-width: $tabletScreen) {
    display: flex;
    justify-content: center;
  }
}
.catalogue-button:hover {
  background: none !important;
  border: 2px solid #ff416e !important;
  color: #ff416e !important;
}
// .active-catlog-button:hover {
//   background: none !important;
//   border: 2px solid #ff416e !important;
//   color: white !important;
//   background-color: #ff416e !important;
// }

@media only screen and (max-width: $tabletScreen) {
  .course-page-header {
    .commonsection {
      padding: 40px 0;
    }
    .centerPara {
      width: 100%;
    }
  }
}
