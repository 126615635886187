@import "path";

.eboxicon {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
}

.eboxicon.eboxicon-prev-arrow {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$svgIconColor}' viewBox='0 0 443 443'> #{$eboxicon-prev-arrow} </svg>");
  background-size: 20px 16px;
  width: 20px;
  height: 16px;
}
.eboxicon.eboxicon-next-arrow {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$svgIconColor}' viewBox='0 0 443 443'> #{$eboxicon-next-arrow} </svg>");
  background-size: 20px 16px;
  width: 20px;
  height: 16px;
}
.eboxicon.eboxicon-lecturevideos {
  background-image: url("/assets/images/icons/video.svg");
}
.eboxicon.eboxicon-knowledge-based {
  background-image: url("/assets/images/icons/knowledge.svg");
}
.eboxicon.eboxicon-assessment-problem {
  background-image: url("/assets/images/icons/assessment.svg");
}
.eboxicon.eboxicon-practice-problem {
  background-image: url("/assets/images/icons/skill.svg");
}
.eboxicon.eboxicon-code-analysis {
  background-image: url("/assets/images/icons/code.svg");
}
.eboxicon.eboxicon-thumbs-up {
  background-image: url("/assets/images/icons/lifetime-access.svg");
}
.eboxicon.eboxicon-clock {
    background-image: url("/assets/images/icons/clock.svg");
}
.eboxicon.eboxicon-INR {
  background-image: url("/assets/images/icons/currency/rupee.svg");
}
.eboxicon.eboxicon-USD {
  background-image: url("/assets/images/icons/currency/dollar.svg");
}
.eboxicon.eboxicon-certificate{
  background-image: url("/assets/images/icons/certificate.svg");
}
