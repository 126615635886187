.callFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .sectionTitle2 {
    margin-right: 1rem;
  }
  img {
    margin-right: 5px;
  }
  .mar15 {
    margin: 0px 15px;
  }
}
@media only screen and(max-width: 768px) {
  .btn.btn_primary.querycontact {
    min-width: 13px !important;
    height: 43px !important;
    font-size: 0.75rem !important;
  }
}
