@import "assets/scss/config.scss";

@media only screen and(max-width: 991px) {
  .schoolPage {
    .request-form {
      margin: 10% 0px;
    }
    .webView {
      display: none;
    }
    .mobileView {
      display: block;
    }
    .abcdPage .rec-carousel .carsouelArrow {
      top: 50px;
    }
    .abcdPage .tagline {
      margin-bottom: 50px;
    }
    .btn_round {
      padding: 10px 15px;
      font-size: 14px;
    }
    .topSection {
      .section_title {
        width: 100%;
        font-size: 35px;
        margin-top: 0rem;
        line-height: 1.5;
        margin-bottom: 2rem;
      }
      .section_title2 {
        text-align: left;
        width: 100%;
        font-size: 20px;
      }
    }
    .request-form .form-group {
      padding: 0rem 0rem;
    }
  }
  .placementCard .officerDesignation {
    height: auto;
  }
  .placementOfficers .slick-slider {
    margin-top: 50px;
  }
  .schoolPage {
    .pgmlistsection:after {
      background-image: url("/assets/images/school/schoolmini.png");
      background-size: 100%;
      left: 0px;
      background-position: top;
    }
    .bannerText {
      padding-top: 0%;
    }
    .pgmlistsection {
      padding: 40px 0px;
    }
    .talkbtn {
      display: block;
    }
  }
  .cardRow {
    margin-top: 0rem;
  }
  .placementPacks {
    background: #fff;
  }

  .batch-modal {
    .sectionTitle2 {
      width: 80%;
      margin: auto;
      line-height: 1.5;
    }
    .closeIcon {
      transform: scale(0.6);
      top: 10px;
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }
  .form-modal.engineerForm-modal .modal-content {
    width: 100%;
    margin: auto;
  }
  main.abcdPage .highlightCard {
    text-align: center;
    width: 92%;
    margin: 15px 8px 18px 14px;
    padding: 5px 15px;
    min-height: 200px;
    .h_para {
      font-size: 0.75rem;
      color: $paraColor;
      margin-bottom: 0.5rem;
    }
    .h_title {
      font-size: 12px;
      margin: 0.5rem auto 4px;
      font-weight: bold;
    }
  }
}
@media only screen and(max-width: 768px) {
  .abcdPage {
    .sectionTitle {
      font-size: 1.5rem;
      line-height: 41px;
      padding-top: 36px;
    }
  }
  .courseModal {
    .modal-dialog {
      max-width: 100%;
    }
    .included {
      .placement-flex {
        display: block;
      }
      .placement-item {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
        margin-right: 0px;
        width: 100%;
      }
    }
    .modal-top .courseclose {
      width: 13px;
    }
  }
  .topicslist li {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .carsouel-card ul li span {
    flex-grow: 1;
  }
  .schoolPage {
    .benefit_Card.mobileappcard {
      background-image: none;
      padding-left: 30px;
      height: auto;
      p {
        flex-grow: initial;
      }
      .google-badge {
        display: block;
      }
    }
    .commonsection {
      padding: 28px 10px;
      &.learnerTestimonial {
        padding-bottom: 90px;
      }
    }
    .phone {
      margin-top: 20px;
    }
  }
  .batchCarsouel.custom-arrows .slick-arrow.slick-next {
    right: 30%;
  }
  .batchCarsouel.custom-arrows .slick-arrow.slick-prev {
    left: 35%;
  }
  main.abcdPage .highlightCard {
    min-height: 250px;
    margin: 15px 8px 20px 8px;
  }
  .emiText.batchEmiText1 {
    text-align: center;
    padding-top: 0px;
  }
  .vsliSectionTitle2 {
    padding-top: 1rem;
  }
}

@media only screen and(max-width: 600px) {
  .abcdPage .carsouel-card {
    width: 92% !important;
  }
}

@media only screen and(max-width: 1200px) {
  .border-card1:nth-child(1) {
    border-bottom: 1px solid #dee2e6;
    height: 190px;
    padding-top: 9px;
  }
  .border-card1:nth-child(2) {
    border-bottom: 1px solid #dee2e6;
    height: 176px;
  }
  .border-card1:nth-child(3) {
    border-bottom: 1px solid #dee2e6;
    height: 176px;
  }
  .border-card1:nth-child(4) {
    border-bottom: 1px solid #dee2e6;
    height: 177px;
  }
  .begineerModule .beginner1 {
    height: 177px;
  }
  .begineerModule .advanced1 {
    height: 165px;
  }
  .begineerModule .integrated1 {
    height: 177px;
  }
}
