@import "assets/scss/config.scss";

.signatureBlendLayout {
    background-image: url("../../../assets/images/amphiSignatureBlend.webp") !important;
    // width: 100%;
    // height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 2em 4em;
    font-family: "Lato" !important;
    @media only screen and (max-width: 560px) {
        background-image: none !important;
        background-color: $colorBlack;
        height: auto;
        padding: 2em;

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .introducing {
        color: $colorWhite;
        font-size: 2.5vw;
        font-weight: 800;

        @media only screen and (max-width: 991px) {
            font-size: 3vw;
            padding-bottom: 0.5em;
        }
        @media only screen and (max-width: 768px) {
            font-size: 3vh;
        }
        @media only screen and (max-width: 560px) {
            display: flex;
            justify-content: center;
            font-size: 9.5vw;
        }
    }

    .amphiSignatureBlend {
        @media only screen and (max-width: 560px) {
            padding-top: 20px !important;
        }
        .amphi {
            color: #fea64c !important;
            font-size: 1.8vw;
            font-weight: 800;
            @media only screen and (max-width: 768px) {
                font-size: 2vh;
            }
            @media only screen and (max-width: 560px) {
                font-size: 5.2vw;
            }
        }

        .amphiSignatureBlendImg {
            position: relative;
            .signature {
                width: 20vw !important;

                @media only screen and (max-width: 560px) {
                    width: 70vw !important;
                }
            }
        }

        .blend {
            padding-left: 16vw !important;
            color: $colorWhite;
            font-size: 1.8vw;
            font-weight: 800;

            position: absolute;
            top: 2.5em;
            @media only screen and (max-width: 768px) {
                font-size: 2vh;
                top: 1.8em;
            }
            @media only screen and (max-width: 560px) {
                padding-left: 55vw !important;
                font-size: 5.2vw;
                top: 3em;
            }
        }
    }

    .descContainer {
        padding-top: 3em !important;
        width: 50% !important;
        color: $colorWhite;
        @media only screen and (max-width: 991px) {
            padding-top: 1em !important;
            width: 70% !important;
        }
        @media only screen and (max-width: 768px) {
            width: 60% !important;
        }
        @media only screen and (max-width: 560px) {
            padding-top: 3em !important;
            width: 100% !important;
        }

        .desc {
            font-weight: 800;
            font-size: 1.8vw;
            @media only screen and (max-width: 991px) {
                font-size: 2.5vh;
                padding-top: 1em;
            }
            @media only screen and (max-width: 768px) {
                padding-top: 0.5em;
                font-size: 2vh !important;
            }

            @media only screen and (max-width: 560px) {
                font-size: 5vw;
            }
        }
    }

    .learnMoreBtn {
        padding-top: 1em !important;
        width: 60% !important;
        display: flex !important;
        gap: 1em !important;
        justify-content: flex-end !important;
        align-items: center !important;
        // color: $colorWhite !important;

        @media only screen and (max-width: 560px) {
            width: 100% !important;
            justify-content: flex-end !important;
        }

        .btn {
            background-color: $colorWhite;
            padding: 0.3em 0.8em 0.3em 0.8em;
            border-radius: 40px;
            font-weight: 800;
            font-size: 1.4vw;
            cursor: pointer;

            color: $colorBlack !important;
            @media only screen and (max-width: 991px) {
                font-size: 2vw;
            }
            @media only screen and (max-width: 768px) {
                padding: 0.25em 0.5em 0.25em 0.5em;
                font-size: 1.8vh;
            }
            @media only screen and (max-width: 560px) {
                font-size: 4.5vw;
            }
        }
    }
}
