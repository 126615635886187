@import "assets/scss/config.scss";

.learnsection {
  .step-card {
    text-align: left;
    display: flex;
    align-items: center;
    width: 95%;
    margin: 1rem 0px;

    .step-title {
      font-size: 1.25rem;
      line-height: 30px;
      font-family: $fontName;
      font-weight: $bold;
      margin: 0px 0px 0.5rem;
    }

    .step-image {
      background: $grayColor6;
      max-width: 90px;
      flex: 0 0 90px;
      height: 90px;
      margin-right: 2rem;
      border-radius: 100%;
      padding: 10px;
    }

    .step-para {
      font-size: 1rem;
      color: $paraColor;
      line-height: 24px;
      width: 100%;
      margin: auto;
    }
  }
}

.knowMoreBtn {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 50px;
}

.learnbox_light {
  .learnimg {
    .learnSprite {
      margin: auto;
    }
  }
}

.learnimg {
  .learnSprite {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 100%;
  }
}

.learnSprite {
  background-image: url('/assets/images/sprite_learn.webp');
  background-repeat: no-repeat;
  height: 75px;
  width: 60px;

  &.iLearn {
    background-position: -329px -7px;
  }

  &.iDesign {
    background-position: -159px -13px;
  }

  &.iExplore {
    background-position: -240px -8px;
  }

  &.iAssess {
    background-position: -80px -7px;
  }

  &.iAnalyse {
    background-position: -8px -3px;
  }

  &.learn1 {
    background-position: -419px 3px;
  }

  &.learn2 {
    background-position: -519px 3px;
  }

  &.learn3 {
    background-position: -619px 3px;
  }

  &.learn4 {
    background-position: -719px 3px;
  }

  &.learn5 {
    background-position: -813px 3px;
  }

  &.learn6 {
    background-position: -909px -2px;
  }

  &.learn7 {
    background-position: -1008px 1px;
  }

  &.learn8 {
    background-position: -1107px 8px;
  }

  &.highlight1 {
    background-position: -251px -88px;
    margin: auto;
  }

  &.highlight2 {
    background-position: -159px -86px;
    margin: auto;
  }

  &.highlight3 {
    background-position: -736px -11px;
    margin: auto;
  }

  &.highlight4 {
    background-position: -428px -5px;
    margin: auto;
  }

  &.highlight5 {
    background-position: -77px -89px;
    margin: auto;
  }

  &.highlight6 {
    background-position: -627px -5px;
    margin: auto;
  }

  &.highlight7 {
    background-position: -330px -90px;
    margin: auto;
  }

  &.highlight8 {
    background-position: 0px -90px;
    margin: auto;
  }

  &.highlight10 {
    background-position: -826px -95px;
    margin: auto;
  }

  &.highlight11 {
    background-position: -1010px -95px;
    margin: auto;
  }

  &.highlight12 {
    background-position: -1090px -95px;
    margin: auto;
  }

  &.highlight_1 {
    background-position: -1015px -189px;
    margin: auto;
  }

  &.highlight_2 {
    background-position: -336px -3px;
    margin: auto;
  }

  &.highlight_3 {
    background-position: -735px -3px;
    margin: auto;
  }

  &.highlight_4 {
    background-position: -250px -273px;
    margin: auto;
  }

  &.highlight_5 {
    background-position: -90px -88px;
    margin: auto;
  }

  &.highlight_6 {
    background-position: -836px -8px;
    margin: auto;
  }

  &.highlight_7 {
    background-position: -664px -90px;
    margin: auto;
  }

  &.highlight_8 {
    background-position: -1019px -90px;
    margin: auto;
  }

  &._highlight_1 {
    background-position: -259px -101px;
    margin: auto;
  }

  &._highlight_2 {
    background-position: -168px -103px;
    margin: auto;
  }

  &._highlight_3 {
    background-position: -735px -11px;
    margin: auto;
  }

  &._highlight_4 {
    background-position: -435px -21px;
    margin: auto;
  }

  &._highlight_5 {
    background-position: -90px -99px;
    margin: auto;
  }

  &._highlight_6 {
    background-position: -636px -16px;
    margin: auto;
  }

  &._highlight_7 {
    background-position: -341px -105px;
    margin: auto;
  }

  &._highlight_8 {
    background-position: -831px -102px;
    margin: auto;
  }

  &.coding-1 {
    background-position: -654px -91px;
  }

  &.coding-2 {
    background-position: -573px -95px;
  }

  &.coding-3 {
    background-position: -487px -94px;
  }

  &.coding-4 {
    background-position: -410px -92px;
  }
}

// .enggTuitionHighlightProgram{
//   background-image: url('/assets/images/engineeringtuitions/sprite_learn.webp');
//   background-repeat:  no-repeat;
//   height: 75px;
//   width: 75px;
//   &.iLearn{
//     background-position: -329px -7px;
//   }
//   &.iDesign{
//     background-position: -159px -13px;
//   }
// }

@media only screen and (max-width: $tabletScreen) {
  .learnsection {
    .step-card {
      flex-direction: row;
      text-align: left;
      width: 100%;

      .step-image {
        margin-right: 0;
      }

      .stepDesc {
        padding-left: 15px;
      }

      .step-title {
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
      }

      .step-para {
        font-size: $font12px;
        line-height: 18px;
      }
    }
  }
}

.highlightabcdsection {
  margin: 0px !important;
}

.cardDescContainer {
  margin: 18px 5px;
  text-align: justify;
}

.cardDesc {
  font-size: 16px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  word-wrap: break-word;
  text-align: center;
  color: #1B1C1E;
}

.videoContainer {
  display: flex;
  justify-content: space-between;
}

.videoItem {
  flex: 1;
  margin-right: 20px;
}

.videoItem:last-child {
  flex: 1;
  margin-right: 0px;
}

.playIconSize {
  height: 50px !important;
  width: 50px !important;
}

.blogVideo {
  border-radius: 10px;
}

.blogDescItem {
  font-size: 1.1rem;
  color: #1B1C1E;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.2rem;
  margin-top: 1rem;
}

.blogContItem {
  background-color: #211a4e;
  border-radius: 10px;
  color: white;
  width: 70%;
}

.blogImgMarg {
  margin-bottom: 0px !important;
}

.vidDur {
  font-size: 0.875rem;
  color: #7C7CA2;
  margin-bottom: 1.5rem;
}

.respBlogContItem {
  width: 100% !important;
}

.contactLabel {
  display: flex;
  justify-content: flex-start;

  .callUsText {
    background-color: #ffc300;
    border-top-left-radius: 10px;
    border: 1px solid #ffc300;
    padding: 10px 20px;
  }
}

.contactDetails {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 40px;

  .contactDesc {
    font-size: 24px;
    font-weight: bold;
    flex: 1;
    text-align: left;
  }

  .contactNumbers {
    flex: 1;
    border-left: 1px solid grey;

    .contactNumber {
      border-left: 8px solid #463a9f;
      width: 70%;
      font-weight: bold;
      text-align: left;
      margin-left: 30px;

      .marLeft {
        margin-left: 30px;
      }
    }

    .contactNumber:first-child {
      margin-bottom: 10px;
    }
  }
}

.respContactDetails {
  display: flex;
  flex-direction: column;

  .respContactNumbers {
    width: 100%;
    margin-top: 20px;

    .contactNumber {
      width: 90%;
    }
  }
}

.firstHalf {
  border-right: 1px solid #8B96A5;
  display: flex;
  flex: 1;
}

.contactText {
  font-size: 20px;
  font-weight: bold;
  align-self: center;
}

.contactWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.contactName {
  font-weight: 600;
  font-weight: 600;
  font-size: 16px;
}

.featureSecond {
  margin-top: 30px;
}

.featureCard {
  padding: 0px 20px !important;

  .featureTop {
    display: flex;
    align-items: center;

    .featureImgWrap {
      width: 50px;
      height: 50px;
      background-color: #FF416E;
      border-radius: 6px;
      padding: 5px 5px 5px 5px;

      .featureImg {
        width: 100%;
        height: 100%;
      }
    }

    .featureTopText {
      font-size: 24px;
      color: #39424E;
      margin: 0px;
      padding-left: 20px;
      font-weight: bold;
    }

    margin-bottom: 10px;

    // .knowMoreBtn {
    //     float: right;
    // }
  }

  .featureSideText {
    color: #1B1C1E;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .featureText {
    font-size: 16px;
    color: #39424E;
    line-height: 25px;
  }
}

@media only screen and (max-width: $tabletScreen) {
  .featureSecond {
    margin-top: 30px;
  }
}

@media only screen and (max-width: $mobileScreen) {
  .blogDescItem {
    font-size: 16px !important;
  }

  .sectionHead {
    font-size: 16px;
    line-height: 30px;
  }

  .featureCard {
    margin-bottom: 30px;
    padding: 0px 20px !important;

    .featureTop {
      .featureTopText {
        font-size: 16px;
      }

      margin-bottom: 20px;
    }

    .featureSideText {
      font-size: 14px;
      font-weight: 600;
    }

    .featureText {
      font-size: 14px;
    }
  }

  .featureSecond {
    margin-top: 0px;
  }

  .mobileMargin {
    margin-top: 10px;
  }

  .share-blog {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .share-blog-text {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .learnsection {
    .step-card {
      flex-direction: row;
      text-align: left;
      width: 100%;

      .step-image {
        margin-right: 0;
      }

      .stepDesc {
        padding-left: 15px;
      }

      .step-title {
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
      }

      .step-para {
        font-size: $font12px;
        line-height: 18px;
      }
    }
  }

  .step-title {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
  }

  .step-para {
    font-size: $font12px;
    line-height: 18px;
  }

  .contactText {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .detailed-heading {
    font-size: 24px !important;
    line-height: 50px !important;
  }

  .expert-blog-image {
    width: 100%;
  }
}

@media only screen and (max-width: $mobileScreen) {
  .margTop {
    margin-top: 0px !important;
  }

  .firstHalf {
    border-right: none;
    display: flex;
    flex: 1;
  }

  .contactWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .contactUs {
    padding: 0px 0px 20px 0px;
  }

  .detailed-heading {
    font-size: 16px !important;
    line-height: 30px !important;
  }
}

.step-para-clr {
  color: #7C7CA2;
}

.section-title {
  font-size: 34px;
  color: #000;
  line-height: 48px;
  font-weight: 900;
  width: 80%;
  margin-bottom: 20px;
}

.blog-section,
.snippet-section {
  padding: 30px 0px;
}

.heading {
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: normal;
}

.blog-section .blog-lists .heading {
  margin-bottom: 30px;
}

.blog-detailed a {
  color: #39424e !important;
  font-size: 16px;
}

.detailed-heading {
  text-align: center;
  font-size: 42px;
  line-height: 72px;
  font-weight: bold;
  margin: 30px 0px;
}

.blog-photo img {
  max-width: 100%;
  height: 100%;
  border-radius: 8px;
  margin: 0 auto !important;
  display: flex;
}

.blog-photo {
  margin: 30px 0px;
}

.left-blog-photo {
  float: left;
  margin-right: 30px;
  width: 215px;
  overflow: hidden;
  margin-bottom: 40px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;

}

.left-blog-photo img {
  width: 100%;
}

.left-align-ques {
  margin-top: 45px;
}

.question {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  color: #39424e;
  margin-bottom: 20px;
}

.answer p {
  font-size: 16px;
  line-height: 24px;
  color: #39424e;
  margin-bottom: 20px;
}

.question-set {
  margin-bottom: 40px;
}

.answer ol {
  font-size: 16px;
  line-height: 24px;
  color: #39424e;
  padding-left: 20px;
}

.shared-links {
  text-align: center;
}

.shared-links a,
.share-blog a {
  text-decoration: none !important;
}

.shared-links img,
.share-blog img {
  height: 56px;
  margin-right: 10px;
}


.share-blog {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.share-blog p {
  margin: 0px;
  margin-right: 15px;
  font-size: 16px;
}

.expert-blog-image {
  border-radius: 8px 8px 0px 0px;
}

.blog-image {
  text-align: center !important;
}

.featureCard {
  padding: 0px 30px;
}

.margTop {
  margin-top: 40px;
}

.people_name {
  margin-right: 10px;
  font-size: 16px;
  margin-bottom: 3px;
}

.padTopReduce {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.rmBg {
  background: none !important;
}

@media(max-width:991px) {
  .knowMoreBtn {
    justify-content: center;
  }
}