@import "assets/scss/main.scss";

.headerImg {
    display: flex;
    justify-content: center;
    align-items: center;

    .earlyYearsImg {
        width: 100%;
    }
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0 2rem 0;
    text-align: center;
    h2{
        margin:0;
        letter-spacing: 0.3em;
        justify-content: space-between;
        font-size: xx-large !important;
    }
    .firstTitle{
        color: $black !important ;
      }
      .secondTitle{
        color: $primaryColor !important ;
      }
    @media screen and (max-width: 600px) {
        letter-spacing: 20px;
        font-size: 30px;
        justify-content: center;
        padding: 0;
    }
}



.clr20{
    clear: both;
    height: 20px;
}

.clr10{
    clear: both;
    height: 10px;
}

.imageWrapper{
    position: relative;
    .star{
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
        width: 10% !important;
        @media only screen and (max-width:768px) {
           display: none !important;
          }
    }
    
    .imageFrame{
        position: absolute !important;
        right: 0 !important;
        top: 0 !important;
        width: 10% !important;
        @media only screen and (max-width:768px) {
            display: none !important;
           }
    }
}