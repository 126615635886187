@import "assets/scss/config.scss";
.NeetPage {
  .bannerText {
    p {
      margin-bottom: 20px;
      font-size: 1em;
    }
  }
  .btn_primary {
    color: #fff !important;
  }
  .commonsection {
    padding: 80px 0px;
  }
  .marTopNeet{
    padding: 210px 0px 80px 0px;
    @media only screen and (max-width: 768px) {
      margin-top: 280px;
    }
    @media only screen and (max-width: 575px) {
      margin-top: 350px;
    }
    @media only screen and (max-width: 375px) {
      margin-top: 370px;
    }
    @media only screen and (max-width: 320px) {
      margin-top: 470px;
    }
  }
  .pgmlistsectionneet {
    &:after {
      content: "";
      position: absolute;
      background-image: url("/assets/images/neet/banner-1.webp");
      background-repeat: no-repeat;
      background-position: right;
      height: 88%;
      width: 100%;
      background-size: contain;
      top: 0px;
    }
  }
  .callbackbtneet {
    position: fixed;
    right: 25px;
    transform: rotate(-90deg) translate(0px, 84px);
    width: auto;
    height: auto;
    top: 40%;
    z-index: 1;
    .btn {
      border-radius: 8px 8px 0px 0px;
    }
  }
  .sectionTitle {
    font-size: 2rem;
    line-height: 51px;
    margin-bottom: 11px;
  }
  .center-show {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom-arrows .slick-arrow {
    top: inherit;
    bottom: -60px;
    &.slick-prev{
      right: inherit;
      left: 42%;
    }
    &.slick-next{
      right: 48%;
    }
  }

  .placementsection {
    .slick-slider {
      margin-top: 80px;
    }
  }

  .top-arrows {
    .slick-arrow {
      top: -40px;
      bottom: inherit;
      &.slick-next {
        right: 10px;
      }
      &.slick-prev {
        right: 60px;
        left: inherit;
      }
    }
  }

  .imagesection {
    display: block;
    width: 100%;
    background-position: 0px 0px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .section_title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .section_title2 {
    font-size: 24px;
    text-align: center;
    line-height: 32px;
    margin-bottom: 30px;
    color: $paraColor;
  }

  .secondtxt {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .benefit_Card {
    padding: 30px;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 8px;
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      margin-top: 10px;
    }
    p {
      font-size: 14px;
      color: $paraColor;
      margin-top: 10px;
      line-height: 24px;
      &.bandwidth_p {
        min-height: 73px;
      }
    }
    &.card-color1 {
      background: #fdf3da;
    }
    &.card-color2 {
      background: #eec2ff;
    }
    &.card-color3 {
      background: #d4f5ff;
    }
    &.card-color4 {
      background: #dce1ff;
    }
    &.card-color5 {
      background-color: #c2ffd0;
    }
    &.mobileappcard {
      background-image: url("/assets/images/placement/mobile-app-support.webp");
      height: 230px;
      background-repeat: no-repeat;
      padding-left: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-position: 0px 30px;
    }
  }

  .benefit_C_content {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    background: #f7f7f7;
    padding: 20px 0px;
    margin-top: 50px;
  }

  /* For Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* Webkit browsers like Safari and Chrome */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .card {
    background: #fff;
    box-shadow: 0 11px 11px #e6e6e6b8;
  }
  .padding_card {
    padding: 30px 25px;
  }

  .commontbl {
    width: 65%;
    margin: 0px auto;
  }

  .table-bordered {
    border: 1px solid #ddd;
    th {
      background-color: #ceccdb;
      font-size: 13px;
      line-height: 15px;
      color: #482c7d;
      padding: 10px 19px;
      border-color: #c2c0ce;
      text-transform: uppercase;
      vertical-align: middle;
    }
    td {
      background-color: #fff;
      font-size: 15px;
      color: #000;
      line-height: 20px;
      padding: 10px 15px;
      font-weight: 400;
      vertical-align: middle;
    }
  }

  .tagline {
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 30px;
  }

  .whatsection {
    padding: 100px 0px;
  }

  img {
    max-width: 100%;
  }

  .contact-detail .contact-row {
    width: 850px;
    margin: 0 auto;
  }

  .contact-info {
    h1 {
      font-size: 32px;
      font-weight: 600;
      line-height: normal;
    }
    ul {
      list-style: none;
      margin: 30px 0 0;
      li {
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 15px;
        span {
          margin-right: 20px;
        }
      }
    }
  }

  .salient-features {
    margin-top: 35%;
    width: 80%;
  }

  .number-card {
    background: #ffffff;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px #00000014;
    padding: 30px;
    text-align: center;
    h3 {
      font-size: 28px;
      font-weight: 600;
      margin-top: 25px;
      margin-bottom: 0px;
    }
    p {
      color: $paraColor;
      font-size: 20px;
      margin-top: 20px;
    }
  }

  li {
    list-style: none;
  }

  .subpara {
    color: $paraColor;
    font-size: 20px;
    text-align: center;
    line-height: 27px;
    margin: 30px 0 100px;
  }

  .number_p {
    text-align: left !important;
    margin-bottom: 0px;
  }

  .grey_section {
    background: $bodyBg;
  }

  .app-link a {
    color: #000 !important;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none !important;
    span {
      margin-right: 10px;
    }
  }

  .placements--lists {
    overflow: hidden;
    position: relative;
    .wrapper {
      -webkit-animation: 10s merchantlist linear infinite;
      animation: 10s merchantlist linear infinite;
      width: 2500px;
      margin: 0px;
      &:after {
        content: "";
        clear: both;
        display: table;
      }
      li {
        height: 55px;
        float: left;
        width: 150px;
        margin: 0px 30px;
        position: relative;
        padding: 10px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  @-webkit-keyframes merchantlist {
    from {
      -webkit-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
    }

    to {
      -webkit-transform: translate3d(-20%, 0px, 0px);
      transform: translate3d(-20%, 0px, 0px);
    }
  }

  @keyframes merchantlist {
    from {
      -webkit-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
    }

    to {
      -webkit-transform: translate3d(-20%, 0px, 0px);
      transform: translate3d(-20%, 0px, 0px);
    }
  }

  .imginfo {
    cursor: pointer;
    margin-top: 4px;
    margin-left: 10px;
    max-width: 14px;
    flex: 0 0 14px;
  }

  .btn-pack {
    margin: 1rem 0px;
    text-align: center;
  }

  .placementTextBlock {
    padding: 0px 10px;
    display: flex;
    height: 95%;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    .placementText {
      font-size: 24px;
      line-height: 36px;
      color: #39424e;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  .placements-card {
    padding: 20px 0px;
    position: relative;
    box-shadow: 0px 3px 6px #00000029;
    background: #fff;
    border-radius: 8px;
    margin-top: 2rem;
    .packName {
      font-size: 24px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      padding-top: 10px;
      margin-bottom: 1rem;
    }
    .placement-middle {
      color: #fff;
      font-size: $font24px;
      line-height: 36px;
      text-align: center;
      font-weight: bold;
    }
    .packUl {
      margin-bottom: 40px;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 0.6rem;
        &:last-child {
          margin-bottom: 0rem;
        }
        .packIcon {
          width: 28px;
          height: 25px;
          text-align: center;
          background-image: url("/assets/images/sprite_icons.webp");
          &.calendar {
            background-position: -4px -80px;
          }
          &.mentor {
            background-position: -294px -47px;
          }
          &.interview {
            background-position: -73px -84px;
          }
        }
        span {
          font-size: 32px;
          line-height: 36px;
          margin: 0px 10px;
          color: #fff;
          font-weight: 800;
        }
        p {
          margin: 0px;
          font-size: 12px;
          line-height: 16px;
          color: #fff;
        }
      }
    }
    .placement-row {
      display: flex;
      &:after {
        content: "";
        clear: both;
        display: block;
      }
    }
    .placement-th,
    .placement-td {
      flex: 0 0 22%;
      max-width: 22%;
      padding-left: 1%;
      &:first-child {
        flex: 0 0 33%;
        max-width: 33%;
        padding: 0px;
      }
    }
    .placement-head {
      padding: 0px 15px;
      background: #fff;
      border-radius: 32px;
      position: sticky;
      top: 0px;
      &:after {
        content: "";
        clear: both;
        display: table;
      }
    }
    .placement-body {
      margin-bottom: 20px;
      .placement-row {
        padding: 0px 25px;
        &:last-child {
          .placement-td {
            border: 0px;
          }
        }
        .placement-td {
          color: #888;
          padding: 13px 0px;
          border-bottom: 1px solid #e7e7e7;
          &.diamond-column {
            border-left: 1px solid #e7e7e7;
            border-right: 1px solid #e7e7e7;
          }
          &:first-child {
            font-size: $font16px;
            line-height: 20px;
          }
        }
      }
      .tickRound {
        background-image: url("/assets/images/sprite_icons.webp");
        background-position: -179px -79px;
        background-repeat: no-repeat;
        height: 23px;
        width: 30px;
        margin: auto;
      }
    }
  }

  .intern-card {
    width: 90%;
    min-height: 160px;
    margin-bottom: 30px;
    .intern-img {
      margin-bottom: 30px;
    }
    label {
      font-size: 20px;
      line-height: 20px;
    }
    p {
      margin: 0px;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .panel-group .panel + .panel {
    margin: 0px;
  }

  .panel-group {
    .panel {
      margin: 0px;
      border: 0px;
      border-bottom: 1px solid #bcbdc2;
      background: #fafbfc;
      .panel-title {
        color: #000;
        padding: 25px 15px;
        font-size: 20px;
      }
      > .panel-heading {
        cursor: pointer;
        position: relative;
        background: #fafbfc;
        border-radius: 4px;
        & + .panel-collapse > .panel-body {
          padding: 15px;
          border: 0px;
          font-size: 16px;
        }
      }
      &.active {
        .caret-awesome {
          transform: rotate(180deg);
          top: 25px;
        }
      }
    }
    .caret-awesome {
      top: 35px;
      position: absolute;
      right: 20px;
      font-size: 20px;
      color: #000;
      // background-image: url('assets/images/placement/caret-down.svg');
      background-size: contain;
      background-repeat: no-repeat;
      height: 15px;
      width: 15px;
    }
  }
  .carsouel-card {
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    width: 97% !important;
    margin: 15px;
    ul {
      li {
        display: flex;
        .imginfo {
          max-width: 16px;
          flex: 0 0 16px;
        }
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      width: auto;
      span {
        margin-right: 15px;
      }
      img {
        width: auto;
      }
    }
    ul {
      li {
        padding: 15px;
      }
    }
    .btn-section {
      padding: 20px;
    }
  }

  .google-badge {
    margin-top: 20px;
    width: 50%;
  }

  .btn span {
    margin-right: 8px;
  }

  #placements-Carsouel {
    display: none;
  }

  .svgwhite {
    display: inline-flex;
    align-items: center;
  }

  #testimonial-modal .modal-content {
    overflow: hidden;
    width: 550px;
    margin: 0 auto;
    background: #fff;
    padding-bottom: 50px;
    box-shadow: 0 4px 8px #ccc;
    margin-top: 50px;
    border: 0px;
    border-radius: 0px;
    padding: 0px;
  }
  #testimonial-modal .modal-content {
    width: 100%;
    box-shadow: none;
    padding: 35px;
  }
  #testimonial-modal .modal-header {
    border: 0px !important;
    padding: 0px;
    right: 0px;
    display: block;
    position: static;
  }
  #testimonial-modal button.close {
    position: absolute;
  }
  #testimonial-modal .modal-body {
    padding: 0px;
    text-align: left;
  }
  #testimonial-modal .success-block {
    display: flex;
    flex-direction: column;
  }
  #testimonial-modal .short-desc p {
    height: auto;
  }

  #testimonial-modal .modal-content button.close {
    position: absolute;
    z-index: 99;
    color: #717171;
    right: 10px;
    top: 10px;
    opacity: 0.6;
    font-size: 25px;
    border: 2px solid #717171;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  #testimonial-modal .modal-content button.close span {
    display: block;
    margin-top: -3px;
  }
  .placement-track-info {
    background-color: transparent;
    border: none;
    &:focus {
      outline: none;
    }
    .glyphicon {
      color: #a0a0a0;
      font-size: 13px !important;
      margin-left: 6px;
    }
  }
  .popover {
    border-radius: 3px;
  }
  .info-text {
    color: #4d4d4d;
    font-size: 15px;
  }

  .numberlist {
    margin: 0px;
    padding-left: 15px;
    li {
      list-style: decimal;
      line-height: 26px;
    }
  }

  .courseclose {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .placement-head {
    .gold-block,
    .diamond-block,
    .platinum-block {
      border-radius: 8px;
      padding: 15px;
      position: relative;
      overflow: hidden;
    }
  }

  .breadcrumb-section {
    padding: 20px 0;
    background: #ebf3ff;
    label {
      margin: 0px;
      font-size: 16px;
    }
  }

  .btn_round {
    display: inline-block;
    background: #ec5578;
    color: #fff;
    border-radius: 108px;
    padding: 20px 25px;
    font-size: 20px;
    font-weight: 600;
  }

  .phone {
    margin-top: 40px;
    display: flex;
    align-items: center;
    font-weight: 700;
    a {
      font-size: 25px;
      color: #000000;
      text-decoration: none !important;
    }
    img {
      width: 30px;
      margin-right: 5px;
    }
    .text-wrapper {
      position: relative;
      display: inline-block;
      padding-top: 0.1em;
      padding-right: 0.05em;
      padding-bottom: 0.15em;
    }
    .line {
      opacity: 0;
      position: absolute;
      left: 0;
      height: 100%;
      width: 3px;
      background-color: #ec5578;
      transform-origin: 0 50%;
    }
    .line1 {
      top: 0;
      left: 0;
    }
    .letter {
      display: inline-block;
      line-height: 1em;
      color: #39424e !important;
    }
  }

  .talkbtn {
    display: none;
    position: fixed;
    bottom: 0px;
    left: 0px;
    text-align: center;
    background: #fff;
    width: 100%;
    padding: 10px 0px;
    z-index: 3;
    box-shadow: 0px -6px 8px #00000036;
    .btn {
      margin: 0px;
    }
    .testimonialsection {
      padding: 0px;
      .slick-track {
        .slick-slide {
          &:nth-child(3n - 3) {
            .ctc {
              color: $yellowColor;
              background: #fffaeb;
            }
          }
          &:nth-child(3n - 2) {
            .ctc {
              color: $diamond-color;
              background: #dad5ff;
            }
          }
        }
      }
      .testimonial-card {
        display: flex !important;
        flex-direction: column-reverse;
        margin: 15px auto;
        padding: 10px 20px;
        .testi-desc {
          display: none;
        }
        .ctc {
          padding: 5px 14px;
          background: #dcf2ff;
          border-radius: 4px;
          color: #067bc2;
          font-size: $font16px;
          font-weight: normal;
          &:before {
            content: none;
          }
        }
        .testimonial-details {
          display: flex !important;
          align-items: center;
          .testimonial-image {
            height: 56px;
            max-width: 56px;
            margin: 0px;
            flex: 0 0 56px;
          }
          h3 {
            font-size: $font16px;
            text-align: left;
          }
        }
      }
      .slick-arrow {
        display: none !important;
      }
    }
  }

  .limelink {
    text-align: center;
    margin-bottom: 1rem;
  }

  .request-form {
    padding: 20px;
    background: $bodyBg;
    .navbar-brand {
      display: none;
      margin-right: 10px !important;
    }
    .alert {
      width: 90%;
      margin: 0px auto 20px;
    }
    .form-group label {
      color: #1b1820;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 0.75rem;
    }
    .form-group {
      padding: 0rem 2rem;
      margin: 1rem 0px;
    }
    .form-control {
      border-radius: 4px;
      outline: none;
      box-shadow: none;
      background: #f5f5f5;
      &:focus {
        background: #fff;
        border-color: #ccc;
      }
      &.error {
        border-color: red;
      }
    }
    &.active {
      top: 10%;
      .formbg {
        top: 0px;
      }
      .registerClass {
        display: block;
      }
    }
    .registerClass {
      display: none;
      position: fixed;
      cursor: pointer;
      right: 30px;
      width: 24px;
      top: 30px;
    }
    .formTitle {
      color: #0c0c0c;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      margin-top: 20px;
    }
    .formPara {
      color: #1b1820;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      width: 80%;
      margin: 20px auto 0px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
  }

  .btn-section {
    text-align: center;
    margin-bottom: 1rem;
    .btn {
      height: 40px;
      width: 180px;
      line-height: 20px;
      padding: 8px 15px;
      border-radius: 4px;
      font-size: 16px;
    }
  }

  .topSection {
    display: flex;
    align-items: center;
  }
  .bannersection {
    padding: 0px;
  }

  .request-form {
    .register-details {
      &.float-form {
        position: fixed;
        top: 0px;
        width: 100%;
        left: 0px;
        z-index: 3;
        box-shadow: 0px 3px 8px #e7e7e7;
        .navbar-brand {
          display: block;
        }
        .form {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          .formtexts {
            .formTitle {
              margin: 0px;
              text-align: left;
              font-size: 16px;
              padding-left: 5px;
            }
            .formPara {
              display: none;
            }
          }
        }
        .formSets {
          display: flex;
          margin: 8px -15px;
        }
        .form-wrapper {
          display: flex;
          justify-content: space-between;
        }
        .form-group {
          margin: 0px;
        }
        .btn-section {
          display: flex;
          align-items: flex-end;
        }
        .alert {
          width: 90%;
          margin: 0px auto 20px;
          position: fixed;
          width: auto;
          right: 20px;
        }
      }
    }
  }

  .topSection {
    .section_title {
      width: 70%;
    }
    .section_title2 {
      text-align: left;
      width: 85%;
    }
  }

  // .btn {
  //   outline: none !important;
  //   border: 0;
  //   font-size: 14px;
  //   padding: 9px 20px;
  //   height: 48px;
  //   line-height: 28px;
  //   background: transparent;
  //   overflow: hidden;
  //   position: relative;
  //   border-radius: 4px;
  //   min-width: 150px;
  //   border: 1px solid transparent;
  //   cursor: pointer;
  //   color: #ec5578;
  //   &.btn_grey {
  //     background-color: $primaryColor;
  //     color: #fff;
  //   }
  // }

  .bgBody {
    &,
    .bgWhite {
      background-color: $bodyBg;
    }
  }

  .testimonial-card {
    width: 93% !important;
    margin: 15px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    .testimonial-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .testimonial-details {
      .testimonial-image {
        height: 96px;
        width: 96px;
        background: #fbe99f;
        border-radius: 100%;
        overflow: hidden;
        margin: 1rem auto;
      }
      h3 {
        margin: 0px;
        font-size: $font16px;
        color: #39424e;
        line-height: 24px;
        text-align: center;
        padding: 10px;
        font-weight: bold;
      }
      p {
        font-size: $font14px;
        line-height: 24px;
        color: #888888;
        text-align: center;
        padding: 0px 25px;
      }
    }
    .testi-desc {
      min-height: 200px;
      p {
        margin: 0px;
      }
    }
    .owner-cmp {
      width: 100px;
      padding: 7px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 100%;
      }
    }
  }
  .rotateLeft {
    transform: rotate(180deg);
  }
  .rec-carousel {
    .carsouelArrow {
      position: absolute;
      height: 40px;
      width: 40px;
      background: #fff;
      border-radius: 100%;
      opacity: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -60px;
      &.carsouelNext {
        right: 25px;
      }
      &.carsouelPrev {
        right: 75px;
      }
      &:hover {
        box-shadow: $boxShadow;
      }
    }
    .rec-slider-container {
      margin: 0px;
    }
  }

  .accordion {
    .card {
      box-shadow: none;
    }
  }
  .form-group .form-control.error,
  .form-group .form-control.is-invalid {
    border: 1px solid #ff0043 !important;
  }
  .mobileView {
    display: none;
  }

  .packNavs {
    border-radius: 8px;
    background: #fff;
    box-shadow: $boxShadow;
    .nav-tabs {
      background: $bodyBg;
      border: 0px;
      .nav-link {
        font-size: 16px;
        color: $darkColor !important;
        font-weight: bold;
        max-width: 33.33%;
        flex: 0 0 33.33%;
        text-align: center;
        border: 0px;
        border-radius: 0px;
        padding: 10px;
        &.active {
          color: #fff !important;
          &:first-child {
            background: $yellowColor;
            border-top-left-radius: 8px;
          }
          &:nth-child(2) {
            background: $diamond-color;
          }
          &:last-child {
            background: $corporateTag;
            border-top-right-radius: 8px;
          }
        }
      }
    }
    .tab-content {
      .packName,
      .placement-middle {
        display: none;
      }
      .placement-item {
        background: transparent;
        padding: 15px;
      }
      .tab-pane {
        overflow: hidden;
        position: relative;
      }
      .packUl {
        li {
          display: flex;
          align-items: center;
          margin: 1rem 10px;
          span,
          p {
            color: $darkColor;
            font-size: $font16px;
            color: #39424e;
          }
          p {
            margin: 0px;
          }
          span {
            margin-right: 5px;
            margin-left: 10px;
          }
          .packIcon {
            width: 28px;
            height: 25px;
            text-align: center;
            background-image: url("/assets/images/sprite_icons.webp");
            opacity: 0.6;
            transform: scale(0.7);
            &.calendar {
              background-position: -40px -80px;
            }
            &.mentor {
              background-position: -147px -82px;
            }
            &.interview {
              background-position: -110px -83px;
            }
          }
        }
      }
    }
  }
}

.courseModal {
  .modal-content {
    border-radius: 4px;
  }
  .modal-dialog {
    max-width: 80%;
  }
  .modal-top {
    background: #4a187e;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 40px 30px;
    display: flex;
    align-items: center;
    .modal-img {
      max-width: 90px;
      flex: 0 0 90px;
    }
    .courseclose {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }
  .course-top {
    color: #fff;
    .label {
      font-size: 20px;
      line-height: normal;
      font-weight: bold;
      margin: 0px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      margin-bottom: 0px;
      margin-top: 20px;
    }
  }
  .modal-body {
    padding: 20px 30px;
    .mod-tit {
      font-size: 20px;
      line-height: 56px;
      font-weight: 600;
      color: #212b48;
    }
    .mod-p {
      margin: 0px;
      color: #111111;
      font-size: 14px;
      line-height: 28px;
    }
  }
  .panel-group .panel .panel-title {
    color: #212b48;
    padding: 13px;
    font-size: 16px;
    font-weight: bold;
    background: #fafbfc;
    padding-right: 45px;
    .caret-awesome {
      top: 12px;
      right: 20px;
    }
  }
}

.include-ul {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    border-left: 1px solid #f7f7f7;
    height: 100%;
    left: -20px;
  }
  li {
    font-size: 14px;
    line-height: 30px;
    color: #212b48;
    position: relative;
    padding-left: 25px;
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0px;
      background-image: url("/assets/images/placement/greentick.svg");
      height: 15px;
      width: 15px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

// neet Know More card styles
.neetKnowMoreWrapper{
  background-color: #463A9F;
  border-radius: 22px;
  color: #ffffff;
  padding: 30px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 578px) {
    flex-wrap: wrap;
  }
}

.eligiblityHeadText{
  font-size: 24px;
  color: #FFFFFF;
  text-align: left;
  font-weight: 700;
}

.eligibleDescText{
  color: #D8D8D8;
  font-size: 16px;
  @media only screen and (max-width: 1024px) {
    margin-right: 10px;
  }
}

.eligibilityModal{
  background-color: #483F99;
}

.contentWrapper{
  padding: 20px;
  @media only screen and (max-width: 575px) {
    padding: 0px;
  }
}

.modalHeaderStyle{
  background-color: #483F99;
  border-bottom: none;
}

.modalSize{
  // width: 100% !important;
  @media (min-width: 1200px){
    .modal-xl{
     width: 90% !important;
    }
  }
}

.modalHeadWrapper{
  display: flex;
  justify-content: space-between;
}

.ModalHeadText{
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
  margin: 0px;
  @media only screen and (max-width: 1024px) {
    flex-basis: 70%;
  }
}

.headWrap{
  display: flex;
}

.aboutWrapper{
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.aboutHead{
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
}

.aboutTextWrap{
  flex-basis: 50%;
  @media only screen and (max-width: 1024px) {
    flex-basis: 100%;
    padding-bottom: 20px;
  }
}

.aboutContent{
  font-size: 14px;
  color: #ffffff;
  padding-top: 10px;
}

.yellowDivWrap{
  background-color: #FFC300;
  border-radius: 16px;
  padding: 20px;
  flex-basis: 20%;
  min-width: 215px;
  @media only screen and (max-width: 1024px) {
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
    margin: 0px 0px 20px 0px;
  }
}

.blueDivWrap{
  background-color: #067BC2;
  border-radius: 16px;
  padding: 20px;
  flex-basis: 20%;
  min-width: 215px;
  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
  }
}

.colorDivHead{
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
}

.colorDivText{
  font-size: 20px;
  color: #ffffff;
  margin: 0px;
  padding-top: 10px;
  font-weight: 700;
}

.criteriaWrapper{
  padding-top: 30px;
}

.ageCardWrap{
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.ageHeadText{
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  margin: 0px;
  padding: 10px 0px 20px 0px;
}

.ageHeadText1{
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  margin: 0px;
  padding: 0px 0px 30px 0px;
}

.eligibilityWrapper{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.ageLimitWrap{
  flex-basis: 48%;
  @media only screen and (max-width: 1024px) {
    flex-basis: 100%;
    padding-bottom: 20px;
    max-width: 100%;
  }
}

.ageCard{
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  flex-basis: 47%;
  min-height: 250px;
  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
    margin-bottom: 15px;
    min-height: auto;
  }
}

.secondCard{
  flex-basis: 100%;
  padding: 0px;
}

.firstEnd{
  padding-bottom: 10px;
}

.secHead{
  padding-top: 10px;
  border-top: 1px solid #D8D8D8;
}

.ageCriteria{
  font-size: 18px;
  color: #FF416E;
  font-weight: 700;
  margin: 0px;
  padding-bottom: 10px;
}

.ageDescText{
  font-size: 14px;
  color: #5F6368;
  margin: 0px;
}

.markTable{
  border-radius: 10px;
  th{
    border-top: none;
    padding: 20px 25px 10px 20px;
    background-color: #F5F8FF;
    border-radius: 10px;
  }
  th:first-child{
    width: 200px;
  }
  th:last-child{
    padding: 20px 20px 10px 0px;
    text-align: center;
  }
  td{
    text-align: center;
    padding: 10px;
    border-top: 1px solid #D8D8D8;
    vertical-align: middle;
  }
  td:first-child{
    text-align: left;
    padding: 10px 10px 10px 20px;
  }
}

.tableHeadText{
  text-transform: uppercase;
  font-size: 13px;
  color: #39424E;
  margin: 0px;
  font-weight: 700;
}

.tableDescText{
  margin: 0px;
  font-size: 14px;
  color: #888888;
}

.percentText{
  margin: 0px;
  font-size: 14px;
  color: #1B1C1E;
  font-weight: 700;
}

.examPatternWrap{
  padding-top: 50px;
}

.aboutHeadExam{
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
  padding-bottom: 20px;
}

.patternWrapper{
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  padding-bottom: 10px;
}

.questionIcon{
  background-color: #FFC300;
  border-radius: 50%;
  padding: 10px 10px;
  margin-right: 15px;
  width: 45px;
  height: 45px;
  flex-basis: 45px;
  min-width: 45px;
}

.translateIcon{
  background-color: #FFC300;
  border-radius: 50%;
  padding: 9px 9px;
  width: 45px;
  height: 45px;
  margin-right: 15px;
  flex-basis: 45px;
  min-width: 45px;
}

.examImg{
  width: 35px;
  height: auto;
}

.examIcon{
  width: 45px;
  height: 45px;
  background-color: #FFC300;
  border-radius: 50%;
  padding: 10px 13px;
  margin-right: 15px;
  flex-basis: 45px;
  min-width: 45px;
}

.examPatternHead{
  margin: 0px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
}

.examPatternText{
  font-size: 14px;
  color: #D8D8D8;
  margin: 0px;
  padding-top: 5px;
}

.stepsDivWrap{
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
}

.stepWrapper{
  align-self: flex-start;
  @media only screen and (max-width: 1024px) {
    display: flex;
    margin-bottom: 20px;
  }
}

.stepCardWrap{
  position: relative;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  min-width: 251px;
  margin-right: 15px;
  padding: 10px;
  @media only screen and (max-width: 1024px) {
    margin: 0px 0px 0px 0px;
    width: 85%;
    border-radius: 0px 10px 10px 0px;
  }
}

.stepCardWrap ::after{
  content: '';
  position: absolute;
  left: 15%;
  top: -29px;
  height: 27px;
  width: 1px;
  border-left: 2px dashed #D8D8D8;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.stepCircle{
  position: relative;
  background-color: #FF416E;
  border-radius: 50%;
  padding: 27px 13px;
  width: 80px;
  height: 80px;
  display: inline-block;
  @media only screen and (max-width: 1024px) {
    border-radius: 10px 0px 0px 10px;
    flex-basis: 12%;
    height: auto;
  }
}

.stepCircle1{
  position: relative;
  background-color: #FF416E;
  border-radius: 50%;
  padding: 27px 13px;
  width: 80px;
  height: 80px;
  display: inline-block;
  @media only screen and (max-width: 1024px) {
    border-radius: 10px 0px 0px 10px;
    flex-basis: 12%;
    height: auto;
  }
}

.stepCircle ::before{
  content: '';
  display: block;
  width: 186px;
  height: 2px;
  background: #D8D8D8;
  right: 80px;
  top: 50%;
  position: absolute;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.stepText{
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
}

.stepHead{
  margin: 0px;
  font-size: 14px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #241F48;
  border-bottom: 1px solid #D8D8D8;
}

.stepDescText{
  font-size: 14px;
  margin: 0px;
  color: #39424E;
}

.pinkDescText{
  color: #FF416E;
}

.greyDecText{
  color: #888888;
  font-size: 14px;
}

.pointWrapper{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.greyDot{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #888888;
  align-self: center;
  display: inline-block;
  margin: 0px 5px 3px 5px;
}

.pointDiv{
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #FF416E;
  align-self: flex-start;
  margin-right: 10px;
  margin-top: 8px;
}

.stepMoney{
  font-size: 14px;
  color: #241F48;
  font-weight: 700;
}

.mrTop{
  padding-top: 20px;
}

.padBot{
  padding-bottom: 10px;
}

.padTop{
  padding-top: 10px;
  border-top: 1px solid #D8D8D8;
}

.closeIcon{
  width: 20px;
  height: auto;
}

///////////////////////////////////////////////

.grey-tit {
  font-size: 12px;
  line-height: 46px;
  color: #9aa1a9;
  text-transform: uppercase;
}

.big-tit {
  color: $darkColor;
  font-size: 24px;
  text-align: center;
  line-height: 20px;
  margin-bottom: 2rem;
  font-weight: bold;
}

.included {
  .placement-item {
    margin-bottom: 20px;
    flex: 0 0 32%;
    max-width: 32%;
    border-radius: 4px;
    padding: 15px;
    margin-right: 15px;
    &:last-child {
      margin-right: 0px;
    }
  }
  .placement-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0px 15px;
    .placement-bottom {
      text-align: center;
      margin: 20px 0px;
    }
    .gold-block {
      .placement-bottom {
        margin-top: 50px;
      }
    }
  }
}

.gold-block {
  .placement-bottom {
    margin-top: 40px;
  }
}

.topicslist {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -15px;
  li {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0px 15px;
    margin-bottom: 4px;
    span {
      color: $darkColor;
      padding: 13px;
      font-size: $font14px;
      font-weight: bold;
      background: #fafbfc;
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
}
.gold-block {
  background: $yellowColor;
}
.diamond-block {
  background: $diamond-color;
}
.platinum-block {
  background: $corporateTag;
}

.placement-bottom {
  .btn {
    span {
      margin-right: 8px;
    }
  }
}

.placement-bottom {
  .btn_grey {
    background-color: $primaryColor;
    color: #fff;
  }
}

.clr20 {
  height: 20px;
}

.placementCard {
  border-radius: 8px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000029;
  width: 92% !important;
  margin: 15px;
  .placementImage {
    height: 96px;
    width: 96px;
    margin: auto;
    margin-bottom: 1rem;
    img {
      border-radius: 100%;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
    }
  }
  .officerName {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    img {
      height: 13px;
      margin-left: 5px;
    }
  }
  .officerDesignation {
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
    height: 70px;
    color: $paraColor;
  }
}

.center-arrows {
  .slick-arrow {
    &.slick-next {
      right: -14px;
    }
    &.slick-prev {
      left: -14px;
      z-index: 1;
    }
  }
}

.ctc {
  background: #067bc2;
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  position: relative;
  padding: 5px 15px 4px 40px;
  margin-top: 10px;
  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 25px solid #fff;
    left: 0px;
    top: 0px;
  }
}

.cardRow {
  margin-top: 3rem;
}

.courseOffer {
  color: $darkColor;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2.4px;
  background: $bodyBg;
  padding: 10px 25px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.testimonial-block {
  .slick-track {
    .slick-slide {
      &:nth-child(3n - 3) {
        .ctc {
          background: $yellowColor;
        }
      }
      &:nth-child(3n - 2) {
        .ctc {
          background: $diamond-color;
        }
      }
    }
  }
}

.slick-slider.batchCarsouel .slick-current .modal-batch-card {
  background: #ff416e;
  color: #fff;
  transform: scale(1);
}

.slick-slider.batchCarsouel .slick-current .batch-title,
.slick-slider.batchCarsouel .slick-current ul li p {
  color: #fff;
}

.slick-slider.batchCarsouel .slick-current .modal-batch-card .btn {
  display: block;
  background: #fff !important;
  color: #ff416e;
}

.testimonialsection {
  margin-top: -15px;
  background: #fff;
  padding-bottom: 50px;
}

.highlightsSection {
  background: #fff;
}

.form-modal .engineerForm {
  width: 100%;
  background: transparent;
  box-shadow: none;
  position: static;
}
.engg_tution_modal.form-modal .engineerForm {
  .formTitle {
    color: #000;
  }
  .label,
  .formPara {
    color: #756f6f;
  }
}

.placementPacks {
  background: #f5f8ff !important;
  .neetpacksubject {
    margin-top: 0px !important;
    background: $bodyBg;
  }
}

.packQuote {
  padding: 25px 65px 0px 25px;
  font-size: 0.875rem;
  color: #888888;
}

.rateText {
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
}

.packBtn {
  padding: 20px 30px;
  border-top: 1px solid #ccc;
  text-align: center;
  .btn {
    margin: 1rem 0px;
  }
}

.batchCarsouel {
  &.slick-slider {
    margin: 0px;
    margin-bottom: 60px;
  }
  &.custom-arrows .slick-arrow {
    &.slick-prev {
      left: 42%;
      right: inherit;
    }
    &.slick-next {
      right: 42%;
    }
  }
}

.topics-modal .topicsUl {
  margin: 2rem 0px;
}

.tagtext {
  position: absolute;
  color: #fff;
  right: -31px;
  transform: rotate(45deg);
  background: red;
  padding: 2px 22px;
  top: 22px;
  font-size: 15px;
}
.form-modal {
  &.engineerForm-modal {
    .modal-content {
      width: 60%;
      margin: auto;
    }
  }
}

.cardplacement {
  border-radius: 0.5rem !important;
  margin-top: 184px !important;
  border: none !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14) !important;
  .sectionTitlemobile {
    text-align: center;
    color: #241f48;
    margin-top: 12px;
    font-weight: bold;
    font-size: 16px;
  }
  .sectionparamobile {
    padding-top: 4px;
    text-align: center;
    font-size: 12px;
    color: #7c7ca2;
  }
}
.spiriteicon {
  background-image: url("/assets/images/sprite_icons.webp");
  background-repeat: no-repeat;
  &.phonecallicon {
    background-position: -102px -43px;
    width: 28px;
    height: 17px;
    margin-left: -6px;
  }
}
.highlightCardmobileschool {
  width: 90% !important;
  text-align: center;
  margin: 20px;
  border: none !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14) !important;
}
.col-sm-12.sliderparent {
  padding-bottom: 3rem;
}

.highlightCardmobileneet.card {
  width: 100% !important;
  text-align: center;
  margin-top: 20px;
  border: none !important;
  box-shadow: none !important;
  background-color: #f9fbff !important;
  .highlightCardneet {
    display: flex;
    .h_title {
      text-align: left;
      font-size: 14px;
      margin: 27px 0px 0px 0px;
    }
  }
}
.highlightsSection {
  padding-bottom: 0rem !important;
}

.button_load .btn {
  max-width: 47%;
  flex: 0 0 47%;
  padding: 10px 1rem;
}

.neetpacksubject {
  margin-top: 50px;
}

.subjectcard {
  margin-top: 20px;
  .cardneet {
    border: none !important;
    box-shadow: 0px 3px 6px #00000029;
    padding: 15px;
    margin-bottom: 25px;
    .headingcontentcard {
      background-color: #fff3f6;
      width: 75px;
      height: 28px;
      padding: 5px;
      text-align: center;
      margin-bottom: 12px;
      font-size: 12px;
      color: #ff416e;
      font-weight: bold;
    }
    .icondisplay {
      display: flex;
    }
    .icon {
      height: 7px;
      max-width: 7px;
      background-color: #ff416e;
      border-radius: 50%;
      margin-top: 8px;
      margin-right: 15px;
      flex: 0 0 7px;
    }
  }
}

.highlightCardmobileneethightlights {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
  display: flex !important;
  margin: 15px;
  width: 92% !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14) !important;
  border: none !important;
}
.col-sm-12.sliderparent {
  padding-bottom: 3rem;
}

.highlightCardmobileneethightlights.card {
  margin: 20px auto;
  .highlightCardneethighlights {
    text-align: center;
    min-height: 230px;
    .h_title {
      margin: 5px 0px 4px 0px;
      font-weight: bold;
      font-size: 12px;
      text-align: center;
    }
    .h_para {
      font-size: 12px;
      color: $paraColor;
    }
  }
}

.neetlearnCard {
  width: 90% !important;
  text-align: center;
  margin: 20px;
  border: none !important;
  margin: auto;
}
.neetlearnCard.card {
  .highlightCardlearn {
    .h_title {
      margin: 10px 0px 0px 0px;
      height: 40px;
      font-size: 16px;
      padding-top: 10px;
    }
    .h_icon {
      width: 34px;
      height: 34px;
      margin: auto;
      margin-bottom: 20px;
      padding-top: 10px;
    }
  }
}

.highlightCardmobileneetvideo {
  width: 100% !important;
  margin: 20px;
  border: none !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14) !important;
}
.col-sm-12.sliderparent {
  padding-bottom: 3rem;
}

.highlightCardmobileneetvideo.card {
  margin-top: 20px;
  .highlightCardneethighlights {
    .subjects {
      background-color: #fff3f6;
      width: 75px;
      height: 28px;
      padding: 5px;
      text-align: center;
      margin-bottom: 12px;
      font-size: 10px;
      color: black;
      font-weight: bold;
    }
    .heading {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }
    p {
      text-align: left;
      color: #888888;
    }
  }
}

.neetprogram {
  padding-top: 0px !important;
  .center-navs {
    display: block;
    width: 100%;
  }
  & ~ #pgmsection {
    padding-top: 0px;
  }
  .center-navs .nav-pills .nav-link.active,
  .center-navs .nav-pills .show > .nav-link {
    background-color: #ff416e;
    color: #fff;
  }
  .center-navs .nav-pills .nav-link {
    color: #5f6368;
    padding: 10px 25px;
    border-radius: 0.25rem;
    white-space: nowrap;
  }
}

.videotestimonial {
  margin-left: 0px;
  width: 90%;
}

.row.cardRowlearn {
  display: flex;
  justify-content: center;
}

.rowLearncontent {
  padding: 0px 0px;
  margin-top: 20px;
  max-width: 25%;
  flex: 0 0 25%;
}
.neetlearnCard.card {
  box-shadow: none !important;
}

.NeetPage .sectionTitle {
  font-size: 2rem;
  line-height: 51px;
  margin-bottom: 11px;
  margin-top: 25px;
}
.highlightslider {
  margin: 0px !important;
}

.border-card2 {
  &:nth-child(1),
  &:nth-child(2) {
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
  }
  &:nth-child(3) {
    border-bottom: 1px solid $borderColor;
  }
  &:nth-child(4) {
    border-right: 1px solid $borderColor;
  }
  &:nth-child(5),
  // &:nth-child(6),
  &:nth-child(7) {
    border-right: 1px solid $borderColor;
  }
}

.videoSlider {
  margin: 0px !important;
}

.cardbodyneet.card-body {
  padding: 0.25rem !important;
}
.imageloader {
  height: 156px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 10px;
  position: relative;
  span {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    display: flex;
    align-items: center;
    img {
      margin: auto;
    }
  }
  .imageperson {
    border-radius: 5px;
  }
}
.videomodalleading {
  padding: 0px 0px 23px 0px !important;
  background-color: black;
}
.videomodal {
  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: none !important;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    background: black;
    height: 14px;
  }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1.6rem -1rem -1rem auto;
    color: white;
  }
}
.cardimageicon.card-body {
  padding: 0px !important;
}
.subjectpadding {
  padding: 13px;
}
.emiTextneet {
  margin-top: 10px;
  text-align: center;
}
.emiTextneetweb {
  margin-top: 10px;
  text-align: left;
}

.learning_modes {
  margin: 0px !important;
}
.highlightneetrow {
  margin: 0px !important;
}
.btn.btn_primary.courseshowneet {
  font-size: 12px;
  height: 38px !important;
  min-width: 170px;
  margin-top: 8px;
}
.emiTextneetmobile {
  display: none;
}

.neetLearner{
  .learnerDesc{
    .learnerImage{
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      img {
        height: 80px;
        max-width: 80px;
        flex: 0 0 80px;
        margin-left: 0px;
        margin-right: 15px;
      }
    }
  }
}


.name-cmp span {
  font-size: 12px;
}
.emi_neet_Btns{
  min-width: 800px;
}
.neetEnrollLayer{
  display: flex;
  flex-direction: row;
}

.mr20{
  margin-left: 20px;
}

.vertical_line{
  width: 1px !important;
  height: 150px;
  margin: 0 4px;
  background-color: #7C7CA2;
}
.list_detail{
  display: list-item;
    margin-left: 25px;
}

.neetEnrollBtn{
  padding: 5px 5px;
  height: 50px;
}

.neetEnrollSec{
  margin-right: 20px;
}

.batch_for{
  background: #48237e;
  height: 30px;
  color: white;
  align-items: center;
  font-size: 12px;
  font-weight: 800;
  border-radius: 35px;
  padding: 5px 10px;
}
.batch_wrap{
  padding: 20px 0;
}
.text_center_neet{
  text-align: center;
}

.batch_wrap_mobile{
  text-align: center;
  padding: 20px 0;
}
.batch_for_mobile{
  margin: 5px 0px 4px 0px;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    padding: 5px 0;
    border-bottom: 1px solid lightgrey;
}

.batchWrap{
  padding: 10px 0;
}