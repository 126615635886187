@import 'assets/scss/config.scss';

footer{
  padding: 70px;
  background-color: $footerBg;
  .footer-flex{
      display: flex;
      justify-content: space-between;
      .footerLeft {
            align-items: flex-start;
            flex-direction: column;
            display: flex;
            max-width: 70%;
            flex: 0 0 70%;
            .moto-title {
                margin-top: 20px;
                width: 80%;
                color: #fff;
                font-size: 1rem;
                line-height: 1.5;
            }
            img{
              width: 122px;
            }
        }
      .footerRight{
          flex-grow: 1;
          a{
            padding: 0px;
          }
          .termsCondition{
            margin-top: 4px;
            color: #fff;
            text-decoration: underline;
          }
          .termsCondition:hover{
            text-decoration: underline ;
          }
          .title{
              color: #fff;
              font-size: $font20px;
              text-decoration: none;
          }
          ul {
              padding: 0px;
              margin: 0px;
              list-style: none;
              display: flex;
              li {
                  margin: 1rem 1.25rem 1rem 0px;
              }
          }
      }
  }
}


.footer{
  left: 0px;
    width: 100%;
    background: #1A093A;
    text-align: center;
    padding: 50px 20px;
    color: #fff;
    .logo-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      img {
        width: 150px;
      }
      .footerLeft{
        .moto-title {
          margin-top: 20px;
        }
      }
    }
}
