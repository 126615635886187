.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    font-size: $font16px;
    padding: 10px 1.5rem;
    background: transparent;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    border: 1px solid transparent;
    cursor: pointer;
    height: 56px;
    line-height: 34px;
    min-width: 218px;
    outline: none !important;
    box-shadow: none;
}

.btn_block{
    width: 100%;
}

.btn.focus,
.btn:focus,
.btn:hover {
    box-shadow: $buttonShadow;
}

.btn_primary.focus,
.btn_primary:focus,
.btn_primary:hover {
    color: #fff;
}


.btn_primary{
    background-color: $primaryColor !important;
    border: 1px solid transparent !important;
    color: #fff;
}

.btn_secondary{
    background-color: $secondaryColor;
    color: #000;
}


.btn_primary_outer{
    background: #fff;
    color: $primaryColor !important;
    border: 1px solid $primaryColor !important;
}

.btn_disabled{
    background: #AAAAAA;
    color: #fff;
    pointer-events: none;
    cursor: not-allowed;
}

.btn_lg{
    min-width: 200px;
}

.btn_teritary{
    background-color: transparent;
    font-weight: 500;
    color: $primaryColor;
    font-size: 14px;
    letter-spacing: 0;
}

.btn_caps{
    text-transform: uppercase;
}

.btn_outer {
    border-color: #fff;
    color: #fff !important;
    min-width: 200px;
}

.btn_dark_outer {
    border-color: #000000;
    color: #000000;
    font-weight: bold;
    margin-top: 10px;
    display: block;
    width: 100%;
    min-width: 100%;
    &:hover{
        color: #fff;
        background: #000;
    }
}


.btn_small {
    min-width: 100px;
    font-size: 16px;
}

.btn_google {
    background: #F2F2F2;
}

.btn_facebook {
    background: #295396;
    color: #fff;
}


.btn_icon {
    height: 36px;
    width: 36px;
    max-width: 36px;
    flex: 0 0 36px;
    border-radius: 100%;
}

.btn_disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: not-allowed;
}

.btn_white {
    background: #fff;
}

.btn_grey {
    background-color: #E8EAED;
    color: #000;
}