@import "assets/scss/config.scss";

@media only screen and(max-width: 991px) {
  .commonsection.bannerSection:after {
    background-size: 500px 100%;
  }

  .sectionTitle {
    font-size  : 2rem;
    line-height: 44px;
    font-weight: bold;
  }

  .numberFlex {
    display        : flex;
    flex-wrap      : wrap;
    align-items    : center;
    justify-content: center;

    li {
      margin-bottom: 2rem;
      max-width    : 33.33%;
      flex         : 0 0 33.33%;

      &:last-child {
        margin-bottom: 0px;
      }

      p {
        line-height: 1.5;
      }
    }
  }

  .half-centered-section {
    width: 100%;
  }

  .programCard {
    min-height: 330px !important;

    .pgmSet {
      .pgmTitle {
        font-size: $font16px !important;
      }

      p {
        font-size: 0.98rem !important;
      }
    }
  }

  .exclusiveCard{
    width: 100% !important;
  }

  .learnCard .label{
    width: 100%;
    text-align: center;
    font-size: $font14px;
  }

  .learnerCard {
    flex-direction : column-reverse;
    align-items    : center;
    justify-content: center;
    text-align     : center;

    .learnerImage {
      margin-bottom: 1rem;
    }

    .name-cmp .cmp {
      margin-left: 0px;
      margin     : auto;
    }
  }
}

@media only screen and(max-width: 768px) {
  .commonsection {

    &,
    &.bannerSection {
      padding: 20px 10px 30px;
    }
  }

  .numberFlex li {
    max-width: 50%;
    flex     : 0 0 50%;
  }

  .center-navs {
    border       : 0px;
    background   : #fff;
    border-radius: 8px;
    box-shadow   : 0px 0px 16px rgba(0, 0, 0, 0.14);
    padding      : 20px;
    flex-wrap    : nowrap;
    overflow     : auto;

    .nav-pills {
      background   : transparent !important;
      border-radius: 0px !important;
      box-shadow   : none !important;
      padding      : 0px !important;
      flex-wrap    : nowrap;
    }
  }

  .commonsection.bannerSection {
    &:after {
      opacity        : 0.1;
      background-size: 100%;
    }

    .btn-section {
      flex-direction: column;

      .btn {
        min-width    : 100%;
        margin-right : 0px;
        margin-bottom: 1rem;
      }
    }
  }

  .learnCard {
    text-align: center;
  }

  .learnCard .label {
    width : 100%;
    margin: 1rem 0px;
  }

  .learnimg .learnSprite {
    margin: auto;
  }

  .commonsection .sectionTitleMedium1 {
    font-size  : 1.4rem ;
    line-height: 1.5;
  }

  .learnerTestimonial {
    .sectionTitleMedium1 {
      margin-top: 0%;
    }
  }

  footer {
    padding: 20px !important;

    .footer-flex {
      flex-direction: column;

      .footerLeft {
        max-width: 100% !important;
        flex     : 0 0 100% !important;
        margin   : 1rem 0px;

        .moto-title {
          width: 100%;
        }
      }
    }
  }

  .pgmsection .programCard {
    min-height: initial !important;
  }

  .centerPara {
    width: 100%;
  }

  .mobileProgram {
    padding-top: 30px;

    .center-navs {
      display      : block;
      width        : 100%;
      border-radius: 0px;
      background   : transparent;
      box-shadow   : none;
      padding-top  : 0px;

      .nav-pills {
        .nav-item {
          margin-right: 20px;

          &:last-child {
            margin-right: 0px;
          }

          .nav-link {
            border: 1px solid $secondaryColor;
          }
        }
      }
    }
  }

  #program .program-group {
    margin-top: 1rem;
  }
}

@media(max-width:600px) {
  .commonsection.bannerSection {
    &:after {
      content: none;
    }
  }
}