@import 'assets/scss/config.scss';

.evangelistPage{
  .tagline {
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 30px;
  }
  .main-header{
    position: relative;
    width: 100%;
    top: 0px;
    z-index: 1;
    background: #ffffff;
  }
  .accordion {
    .card,
    .card-header,
    .card-body {
      background: #fff;
    }
    .card-body {
      padding: 1rem;
    }
  }
  .accordion .blew-shrink {
    top: 24px;
  }
  .pgmlistsection{
    &.evangelist{
      &.commonsection{
        padding: 90px 0px;
      }
    }
  }
}

.evangelist.pgmlistsection:after {
    content: '';
    position: absolute;
    background-image: url('/assets/images/evangelist/banner-1.png');
    background-repeat: no-repeat;
    background-position: right;
    height: 100%;
    width: 100%;
    background-size: 700px 100%;
    top: 0px;
    
}

.stepsection{
  .step-card {
    margin-bottom: 2rem;
    text-align: left;
    display: flex;
    align-items: center;
    width: 85%;
    .step-image {
      max-width: 100px;
      flex: 0 0 100px;
      margin-right: 2rem;
    }
    .stepDesc{
      .step-title {
        font-size: $font24px;
        line-height: 2rem;
        font-weight: bold;
        margin: 1rem 0px;
      }
      .step-para {
        font-size: $font16px;
        color: $paraColor;
        line-height: 24px;
        width: 100%;
        margin: auto;
      }
    }
  }
  .col-sm-6.form-col {
    width: 43%;
    float: right;
  }
}

.center-form {
  background: #fff;
  padding: 30px 40px;
  border-radius: 8px;
  box-shadow: $boxShadow;
  width: 87%;
  float: right;
  .label {
    font-size: 0.8rem;
    line-height: 21px;
    font-weight: normal;
    color: $paraColor;
    margin: 10px 0px 0px;
  }
  .sectionTitle2 {
    margin-bottom: 1rem;
  }
}

.evangelistForm {
  margin-top: 2rem;
}

.mt20{
  margin-top: 1rem;
}

.form-group{
  margin-bottom: 1rem;
  .label{
    margin-bottom: 0.5rem;
  }
  .form-control.error,
  .form-control.is-invalid {
    border: 1px solid #FF0043 !important;
  }
}

.stepsection {
  .sectionTitleMedium {
    margin-top: 5%;
  }
}

.spreadFlex {
  display: flex;
  margin: 0px -15px;
  .spreadText {
    max-width: 45%;
    flex: 0 0 45%;
    padding: 0px 15px;
  }
  .shareIcons {
    flex-grow: 1;
    padding: 0px 15px;
  }
  .shareIcons {
    ul {
      display: flex;
      li {
        max-width: 25%;
        flex: 0 0 25%;
        margin: 2rem 0px;

      }
    }
  }
  .copyIcon {
    max-width: 20%;
    flex: 0 0 20%;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    .orpart {
      position: relative;
      top: 10px;
      left: -25px;
    }
    ul {
      li {
        background: #EFECF8;
        border-radius: 100%;
        height: 64px;
        max-width: 64px;
        flex: 0 0 64px;
        margin: 1.5rem 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

.successLink {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 15px;
  a {
    color: $primaryColor;
  }
}

.customerror {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}