@import "assets/scss/config.scss";

.blogSegment{
  padding: 4vw 10vw;
  background-image: url('../../../assets/images/blogsBg.webp');
  background-size: cover;
  background-repeat: no-repeat;
  @media only screen and (max-width: $mobileScreen) {
    padding: 6vw 0vw 8vw !important;
    overflow: hidden;
  }
  @media only screen and (max-width: $tabletScreen) {
    padding: 6vw 0vw 8vw !important;
    overflow: hidden;
  }
}
.catalogueWrapper{
  display: flex !important;
  justify-content: center;
  gap: 1vw;
  @media only screen and (max-width: $mobileScreen){
    margin: 0vw !important;
  }
  @media only screen and (max-width: $tabletScreen){
    margin: 0vw !important;
  }
  .activeCatlog {
    background-color: $colorViolet;
    color: $white;
    padding: 0.5em 2.5em 0.5em 2.5em;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.3vw;
    font-weight: bold;
  
    @media only screen and (max-width: $bigTabletScreen) {
      font-size: 2vw;
    }
    @media only screen and (max-width: $tabletScreen) {
      display: flex;
      justify-content: center;
      font-size: 3vw;
    }
    @media only screen and (max-width: $mobileScreen) {
      font-size: 3.2vw;
    }
  }
  .catalogueBtn {
    border-radius: 5px;
    color: $colorViolet;
    padding: 0.5em 1.5em 0.5em 1.5em;
    cursor: pointer;
    font-size: 1.3vw;
    font-weight: bold;
    
    @media only screen and (max-width: $bigTabletScreen) {
      font-size: 2vw;
    }
    @media only screen and (max-width: $tabletScreen) {
      display: flex;
      justify-content: center;
      font-size: 3vw;
    }
    @media only screen and (max-width: $mobileScreen) {
      font-size: 3.2vw;
    }
  }
}

.cardWrapper {
  margin-top: 2vw;
  @media only screen and (max-width: $mobileScreen){
   display: none;
  }
  @media only screen and (max-width: $tabletScreen){
    display: none;
   }
  .blogsCard {
    margin: 1vw 1vw 4vw;
    display: grid;
    border-radius: 20px;
    box-shadow: 0px 1px 10px $colorViolet;
    position: relative;
    background-color: $white;
    cursor: pointer;
    .headerText {
      font-size: 1.2vw;
      padding: 1vw 1vw 0vw;
      color: $colorViolet;
      font-weight: bold;
      min-height: 8.5vw !important;
      display: flex;
      align-items: center;
    }
    .blogImageWrap {
      margin-top: 1vw;
      .blogImage {
        width: 100%;
        height: 16vw !important;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
    .arrowIconwrap{
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0vw 1vw 1vw 1vw;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .latestBlog{
        background-color: $primaryColor;
        border-radius: 20px;
        color: $white;
        padding: .2vw 1.5vw .3vw;
        font-size: 1.3vw;
        font-weight: bold;
      }
      .arrowIcon{
        width: 4vw !important;
        cursor: pointer;
      }
    }
  }
  .activeblogsCard{
    box-shadow: 0px 1px 12px $primaryColor;
  }
}

.cardWrappermobile{
  display: none;
  margin: 6vw 0vw 6vw 15vw;
  width: 100%;
  @media only screen and (max-width: $mobileScreen){
    display: block;
  }
  @media only screen and (max-width: $tabletScreen){
    display: block;
  }
  .blogsCard {
    margin: 1vw 2vw 4vw;
    display: grid;
    border-radius: 10px;
    box-shadow: 0px 1px 3px $colorViolet;
    position: relative;
    cursor: pointer;
    .headerText {
      font-size: 2.6vw;
      padding: 2vw 1.5vw 0vw;
      color: $colorViolet;
      font-weight: bold;
      min-height: 22vw !important;
      display: flex;
      align-items: center;
      line-height: 1.4;
    }
    .blogImageWrap {
      margin-top: 1vw;
      .blogImage {
        width: 100%;
        height: 28vw;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    .arrowIconwrap{
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0vw 1vw 1vw 1vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .latestBlog{
        background-color: $primaryColor;
        border-radius: 20px;
        color: $white;
        padding: .5vw 1.6vw .8vw;
        font-size: 2.3vw;
        font-weight: bold;
      }
      .arrowIcon{
        width: 5vw !important;
        cursor: pointer;
      }
    }
  }
  .activeblogsCard{
    box-shadow: 0px 1px 5px $primaryColor;
  }
}

.nextArrowWrap{
  position: absolute;
  left: 52%;
  top: 100%;
  cursor: pointer;
  .arrowImg{
    width: 70%;
    @media only screen and (max-width: $bigTabletScreen){
      width: 40%;
    }
  }
  @media only screen and (max-width: $mobileScreen){
    display: none;
  }
  @media only screen and (max-width: $tabletScreen){
    display: none;
  }
}

.prevArrowWrap{
  position: absolute;
  top: 100%;
  left: 38%;
  cursor: pointer;
  .arrowImg{
    width: 70%;
    @media only screen and (max-width: $bigTabletScreen){
      width: 40%;
    }
  }
  @media only screen and (max-width: $mobileScreen){
    display: none;
  }
  @media only screen and (max-width: $tabletScreen){
    display: none;
  }
}

.exploreMoreBtn{
  display: flex;
  justify-content: flex-end;
  font-size: 1.5vw;
  font-weight: bold !important;
  @media only screen and (max-width: $mobileScreen){
    justify-content: center;
  }
  @media only screen and (max-width: $tabletScreen){
    justify-content: center;
  }

}