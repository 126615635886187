@import "assets/scss/config.scss";

@media only screen and(max-width: 1200px) {
  .tagtext1 {
    position: absolute;
    color: #fff;
    right: 52px;
    top: 8px !important;
    transform: rotate(0deg);
    background: red;
    padding: 2px 22px;
    z-index: 1;
    font-size: 15px;
    border-radius: 4px;
  }

  .sectionpara1 {
    color: #7c7ca2;
    font-size: 1rem;
    line-height: 24px;
    margin-top: 12px !important;
    margin-bottom: 0px !important;
  }
  .btn.btn_primary.enrollbuttongetme {
    min-width: 13px !important;
    height: 43px !important;
    font-size: 0.75rem !important;
  }
}

@media only screen and(max-width: 991px) {
  .getmeplaced {
    .pgmlistsection:after {
      background-image: url(/assets/images/programs/getmecard.png);
      background-size: 100%;
      left: 0px;
      background-position: top;
    }
    .request-form {
      margin: 10% 0px;
    }

    .webView {
      display: none;
    }

    .mobileView {
      display: block;
    }

    .getmeplaced .rec-carousel .carsouelArrow {
      top: 50px;
    }

    .getmeplaced .tagline {
      margin-bottom: 50px;
    }

    .btn_round {
      padding: 10px 15px;
      font-size: 14px;
    }

    .topSection {
      .section_title {
        width: 100%;
        font-size: 35px;
        margin-top: 0rem;
        line-height: 1.5;
        margin-bottom: 2rem;
      }

      .section_title2 {
        text-align: left;
        width: 100%;
        font-size: 20px;
      }
    }

    .request-form .form-group {
      padding: 0rem 0rem;
    }
  }

  .placementCard .officerDesignation {
    height: auto;
  }

  .placementOfficers .slick-slider {
    margin-top: 50px;
  }

  .getmeplaced {
    .bannerText {
      padding-top: 0%;
    }

    .pgmlistsection {
      padding: 40px 0px;
    }

    .talkbtn {
      display: block;
    }
  }

  .cardRow {
    margin-top: 0rem;
  }

  .placementPacks {
    background: #fff;
  }

  .batch-modal {
    .sectionTitle2 {
      width: 80%;
      margin: auto;
      line-height: 1.5;
    }

    .closeIcon {
      transform: scale(0.6);
      top: 10px;
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }

  .form-modal.getmeplaced .modal-content {
    width: 100%;
    margin: auto;
  }

  .tagtext1 {
    position: absolute;
    color: #fff;
    right: -31px;
    transform: rotate(45deg);
    background: red;
    padding: 2px 22px;
    top: 22px !important;
    font-size: 15px;
    z-index: 0;
  }

  .testimonialsection {
    background: #fff;
  }

  .testimonial-block {
    margin-top: 0px;
  }

  .getmeplaced {
    .testimonial-block {
      margin-top: 12px;
    }
    .top-arrows {
      .slick-arrow {
        top: -30px;
        bottom: inherit;

        &.slick-next {
          left: 80px;
        }

        &.slick-prev {
          left: 20px;
        }
      }
    }
  }
}

@media only screen and(max-width: 768px) {
  .getmeplaced {
    .sectionTitle {
      font-size: 1.5rem;
      line-height: 41px;
    }

    .packNavs .nav-tabs {
      .nav-link {
        font-size: 12px;

        &:nth-child(1),
        &:nth-child(2) {
          padding-top: 20px;
        }
      }
    }
  }

  .courseModal {
    .modal-dialog {
      max-width: 100%;
    }

    .included {
      .placement-flex {
        display: block;
      }

      .placement-item {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
        margin-right: 0px;
        width: 100%;
      }
    }

    .modal-top .courseclose {
      width: 13px;
    }
  }

  .topicslist li {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .carsouel-card ul li span {
    flex-grow: 1;
  }

  .getmeplaced {
    .benefit_Card.mobileappcard {
      background-image: none;
      padding-left: 30px;
      height: auto;

      p {
        flex-grow: initial;
      }

      .google-badge {
        display: block;
      }
    }

    .commonsection {
      padding: 40px 10px;

      &.learnerTestimonial {
        padding-bottom: 90px;
      }
    }

    .phone {
      margin-top: 20px;
    }
  }

  .batchCarsouel.custom-arrows .slick-arrow.slick-next {
    right: 30%;
  }

  .batchCarsouel.custom-arrows .slick-arrow.slick-prev {
    left: 35%;
  }

  .h_para1 {
    font-size: 0.875rem;
    color: #7c7ca2;
    margin-bottom: 1.5rem;
    text-align: justify;
    width: 100% !important;
  }
  .btn.btn_primary_outer.contactbutton {
    min-width: 13px !important;
    height: 43px !important;
    font-size: 0.75rem !important;
  }
  .sectionpara1 {
    font-size: 14px !important;
  }
  .getmeplaced .testimonial-card .testimonial-details h4 {
    font-size: 16px;
  }
}

@media only screen and(max-width: 600px) {
  .getmeplaced .carsouel-card {
    width: 92% !important;
  }

  .enroll_button .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 11px !important;
    height: 40px !important;
    margin: auto;
  }

  .col-sm-12.custom-arrows.top-arrows {
    flex: 0 0 100%;
    max-width: 99%;
    margin-top: 0px;

    &.enroll {
      margin-top: 0px;
    }
  }
}
