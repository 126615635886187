.notfound-layout {
  text-align: center;
  padding: 6rem 2.5rem 2.5rem 2.5rem;
  h1 {
    font-size: 24px;
  }
  p {
    font-size: 16px;
  }
  .contact-btn {
    margin-right: 10px;
  }
  .image {
    max-width: 100%;
  }
  .btn-area {
    margin: 3rem 0px;
    .btn {
      margin-right: 10px;
    }
  }
}
