@media only screen and (max-width: 991px){
  .commonsection.evangelist:after {
      background-size: 500px 100%;
  }
  .spreadFlex {
    margin: 0px;
    flex-wrap: wrap;
    .spreadText {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 2rem;
    }
    .shareIcons {
      max-width: 60%;
      flex: 0 0 60%;
    }
    .copyIcon {
      max-width: 35%;
      flex: 0 0 35%;
      margin-left: 5%;
  
    }
  }
  .spreadFlex {
    .shareIcons, .copyIcon, .spreadText{
      padding: 0px; 
    }
  }
  .center-form {
    width: 100%;
    float: none;
    margin-top: 3%;
  }
}

@media only screen and (max-width: 768px){
  .commonsection.evangelist:after {
    opacity: 0.1;
    left: 0px;
  }
  .stepsection .step-card .stepDesc .step-title {
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: normal;
    margin: 0.5rem 0px;
  }
  .stepsection .col-sm-6.form-col {
    width: 100%;
    float: none;
    max-width: 100%;
    flex: 0 0 100%;
    .center-form{
      margin-top: 1rem;
      width: 100%;
      float: none;
      padding: 30px 30px;
    }
  }
  .spreadFlex {
    flex-direction: column;
    .shareIcons, .copyIcon{
      max-width: 100%;
      flex: 0 0 100%;
    }
    .shareIcons ul li {
      margin: 1rem 0px;
      padding-right: 15px;
      max-width: initial;
      flex: 0 0 auto;
      &:last-child{
        padding-right: 0px;
      }
    }
    .copyIcon {
      flex-direction: column;
      align-items: flex-start;
      text-align: center;
      margin: 0px;
      margin-top: 1rem;
      .orpart {
        top: 0px;
        left: 0px;
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
  .callFlex {
    flex-direction: column;
    .sectionTitle2 {
      text-align: center;
      margin: 0px;
      margin-bottom: 1rem;
    }
  }
  .stepsection .step-card {
    text-align: center;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0px 3rem;
    .step-image {
      margin: 0px;
    }
  }
  .evangelist{
    .sectionpara{
      margin-bottom: 2rem;
    }
  }
}