@import "assets/scss/config.scss";

.learnsection {
  .step-card {
    text-align: left;
    display: flex;
    align-items: center;
    width: 95%;
    margin: 1rem 0px;
    .step-title {
      font-size: 1.25rem;
      line-height: 30px;
      font-family: $fontName;
      font-weight: $bold;
      margin: 0px 0px 0.5rem;
    }
    .step-image {
      background: $grayColor6;
      max-width: 90px;
      flex: 0 0 90px;
      height: 90px;
      margin-right: 2rem;
      border-radius: 100%;
      padding: 10px;
    }
    .step-para {
      font-size: 1rem;
      color: $paraColor;
      line-height: 24px;
      width: 100%;
      margin: auto;
    }
  }
}

.learnbox_light {
  .learnimg {
    .learnSprite {
      margin: auto;
    }
  }
}

.learnimg{
  .learnSprite{
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 100%;
  }
}

.learnSprite{
  background-image: url('/assets/images/sprite_learn.webp');
  background-repeat:  no-repeat;
  height: 75px;
  width: 60px;
  &.iLearn{
    background-position: -329px -7px;
  }
  &.iDesign{
    background-position: -159px -13px;
  }
  &.iExplore{
    background-position: -240px -8px;
  }
  &.iAssess{
    background-position: -80px -7px;
  }
  &.iAnalyse{
    background-position: -8px -3px;
  }
  &.learn1{
    background-position: -419px 3px;
  }
  &.learn2{
    background-position: -519px 3px;
  }
  &.learn3{
    background-position: -619px 3px;
  }
  &.learn4{
    background-position: -719px 3px;
  }
  &.learn5{
    background-position: -813px 3px;
  }
  &.learn6{
    background-position: -909px -2px;
  }
  &.learn7{
    background-position: -1008px 1px;
  }
  &.learn8{
    background-position: -1107px 8px;
  }
  &.highlight1{
    background-position: -251px -88px;
    margin: auto;
  }
  &.highlight2{
    background-position: -159px -86px;
    margin: auto;
  }
  &.highlight3{
    background-position: -736px -11px;
    margin: auto;
  }
  &.highlight4{
    background-position: -428px -5px;
    margin: auto;
  }
  &.highlight5{
    background-position: -77px -89px;
    margin: auto;
  }
  &.highlight6{
    background-position: -627px -5px;
    margin: auto;
  }
  &.highlight7{
    background-position: -330px -90px;
    margin: auto;
  }
  &.highlight8{
    background-position: 0px -90px;
    margin: auto;
  }
  &.highlight10{
    background-position: -826px -95px;
    margin: auto;
  }
  &.highlight11{
    background-position: -1010px -95px;
    margin: auto;
  }
  &.highlight12{
    background-position: -1090px -95px;
    margin: auto;
  }
  &.highlight_1{
    background-position: -1015px -189px ;
    margin: auto;
  }
  &.highlight_2{
    background-position: -336px -3px;
    margin: auto;
  }
  &.highlight_3{
    background-position: -735px -3px;
    margin: auto;
  }
  &.highlight_4{
    background-position: -250px -273px;
    margin: auto;
  }
  &.highlight_5{
    background-position: -90px -88px;
    margin: auto;
  }
  &.highlight_6{
    background-position: -836px -8px;
    margin: auto;
  }
  &.highlight_7{
    background-position: -664px -90px;
    margin: auto;
  }
  &.highlight_8{
    background-position: -1019px -90px;
    margin: auto;
  }
  &._highlight_1{
    background-position: -259px -101px;
    margin: auto;
  }
  &._highlight_2{
    background-position: -168px -103px;
    margin: auto;
  }
  &._highlight_3{
    background-position: -735px -11px;
    margin: auto;
  }
  &._highlight_4{
    background-position: -435px -21px;
    margin: auto;
  }
  &._highlight_5{
    background-position: -90px -99px;
    margin: auto;
  }
  &._highlight_6{
    background-position: -636px -16px;
    margin: auto;
  }
  &._highlight_7{
    background-position: -341px -105px;
    margin: auto;
  }
  &._highlight_8{
    background-position: -831px -102px;
    margin: auto;
  }
  &.coding-1{
    background-position: -654px -91px;
  }
  &.coding-2{
    background-position: -573px -95px;
  }
  &.coding-3{
    background-position: -487px -94px;
  }
  &.coding-4{
    background-position: -410px -92px;
  }
}
// .enggTuitionHighlightProgram{
//   background-image: url('/assets/images/engineeringtuitions/sprite_learn.webp');
//   background-repeat:  no-repeat;
//   height: 75px;
//   width: 75px;
//   &.iLearn{
//     background-position: -329px -7px;
//   }
//   &.iDesign{
//     background-position: -159px -13px;
//   }
// }

@media only screen and (max-width: $tabletScreen) {
  .learnsection {
    .step-card {
      flex-direction: row;
      text-align: left;
      width: 100%;
      .step-image {
        margin-right: 0;
      }
      .stepDesc{
        padding-left: 15px;
      }
      .step-title{
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
      }
      .step-para{
        font-size: $font12px;
        line-height: 18px;
      }
    }
  }
}

.highlightabcdsection{
  margin: 0px !important;
}