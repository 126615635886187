@import "~assets/scss/config";

.df_loading_grid {
  // display: inline-block;
  // position: absolute;
  display: flex;
  width: 80px;
  height: 80px;
  flex-wrap: wrap;
  margin: auto;
  // top: calc(50% - 40px);
  // left: calc(50% - 40px);
}
.df_loading_grid div {
  // position: absolute;
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $secondaryColor;
  animation: df_loading_grid 1.2s linear infinite;
}
.df_loading_grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.df_loading_grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.df_loading_grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.df_loading_grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.df_loading_grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.df_loading_grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.df_loading_grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.df_loading_grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.df_loading_grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes df_loading_grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
