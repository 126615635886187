@import "assets/scss/config.scss";
.roleCard {
  background: #f9fbff;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  a {
    color: $grayColor1;
    &:hover {
      text-decoration: none;
    }
  }
  .roleImage {
    background: #fff;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px 8px 0px 0px;
    position: relative;
    overflow: hidden;
    img {
      min-height: 100%;

      @media only screen and (max-width: $tabletScreen) {
        width: 100%;
      }
    }
  }
  .roledetail {
    height: 108px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 24px;
    font-family: $fontName;
  }
}
