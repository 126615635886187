@import "assets/scss/config.scss";

@media only screen and(max-width: 1200px) {
  .NeetPage {
    .sectionTitle {
      font-size  : $font20px;
      line-height: 1.5;
    }

    .bannerText {
      p {
        margin-bottom: 20px;
        font-size    : $font14px;
      }
    }
  }
}

@media only screen and(max-width: 991px) {
  .NeetPage {
    .request-form {
      margin: 10% 0px;
    }

    .webView {
      display: none;
    }

    .mobileView {
      display: block;
    }

    .NeetPage .rec-carousel .carsouelArrow {
      top: 50px;
    }

    .NeetPage .tagline {
      margin-bottom: 50px;
    }

    .btn_round {
      padding  : 10px 15px;
      font-size: 14px;
    }

    .topSection {
      .section_title {
        width        : 100%;
        font-size    : 35px;
        margin-top   : 0rem;
        line-height  : 1.5;
        margin-bottom: 2rem;
      }

      .section_title2 {
        text-align: left;
        width     : 100%;
        font-size : 20px;
      }
    }

    .request-form .form-group {
      padding: 0rem 0rem;
    }

    .bottom-arrows .slick-arrow {
      &.slick-next {
        right: 43%;
      }
    }
  }

  .placementCard .officerDesignation {
    height: auto;
  }

  .placementOfficers .slick-slider {
    margin-top: 50px;
  }

  .NeetPage {
    .pgmlistsectionneet:after {
      background-image   : url("/assets/images/neet/bannermobile.png");
      background-repeat  : no-repeat;
      background-size    : 100%;
      left               : 0px;
      background-position: top;
    }

    .bannerText {
      padding-top: 0%;
    }

    .pgmlistsectionneet {
      padding: 40px 0px;
    }

    .talkbtn {
      display: block;
    }
  }

  .highlightsSection {
    background: $bodyBg;
  }

  .cardRow {
    margin-top: 0rem;
  }

  .placementPacks {
    background: #fff;
  }

  .batch-modal {
    .sectionTitle2 {
      width      : 80%;
      margin     : auto;
      line-height: 1.5;
    }

    .closeIcon {
      transform: scale(0.6);
      top      : 10px;
      position : absolute;
      right    : 10px;
      cursor   : pointer;
    }
  }

  .form-modal.engineerForm-modal .modal-content {
    width : 100%;
    margin: auto;
  }
}

@media only screen and(max-width: 768px) {
  .NeetPage {
    .sectionTitle {
      font-size  : 1.5rem;
      line-height: 41px;
      padding-top: 10px;
    }

    .bottom-arrows .slick-arrow {
      &.slick-next {
        right: 5%;
      }

      &.slick-prev {
        right: 80px;
        left : inherit;
      }
    }
    .learnerCard .learnerDesc .label {
      text-align: left;
    }
    .name-cmp {
      text-align: left;
    }
  }

  .courseModal {
    .modal-dialog {
      max-width: 100%;
    }

    .included {
      .placement-flex {
        display: block;
      }

      .placement-item {
        flex         : 0 0 100%;
        max-width    : 100%;
        margin-bottom: 20px;
        margin-right : 0px;
        width        : 100%;
      }
    }

    .modal-top .courseclose {
      width: 13px;
    }
  }

  .topicslist li {
    flex     : 0 0 100%;
    max-width: 100%;
  }

  .carsouel-card ul li span {
    flex-grow: 1;
  }

  .NeetPage {
    .benefit_Card.mobileappcard {
      background-image: none;
      padding-left    : 30px;
      height          : auto;

      p {
        flex-grow: initial;
      }

      .google-badge {
        display: block;
      }
    }

    .commonsection {
      padding: 40px 10px;

      &.learnerTestimonial {
        padding-bottom: 90px;
      }
    }

    .phone {
      margin-top: 20px;
    }
  }

  .batchCarsouel.custom-arrows .slick-arrow.slick-next {
    right: 30%;
  }

  .batchCarsouel.custom-arrows .slick-arrow.slick-prev {
    left: 35%;
  }

  .neetprogram {
    padding-top: 30px;

    .center-navs {
      display      : block;
      width        : 100%;
      border-radius: 0px;
      background   : transparent;
      box-shadow   : none;
      padding-top  : 0px;

      .nav-pills {
        .nav-item {
          margin-right: 20px;

          &:last-child {
            margin-right: 0px;
          }

          .nav-link {
            border   : none;
            font-size: 12px;
          }
        }
      }
    }

    .center-navs .nav-pills {
      border       : 1px solid #f0eff8;
      border-radius: 1px !important;
      background   : white !important;
    }
  }

  .rowLearncontent {
    padding   : 0px 0px;
    margin-top: 20px;
    max-width : 50%;
    flex      : 0 0 50%;
  }

  .imageloader {
    height       : 100%;
    width        : 100%;
    margin-bottom: 12px;
    border-radius: 10px;
    position     : relative;
  }

  .subjectcard {
    margin-top: 0px !important;
  }

  .callbackbtneet {
    display: none !important;
  }

  .neetload {
    padding-bottom: 30px !important;
  }

  .emiTextneetmobile {
    position     : fixed;
    bottom       : 0px;
    z-index      : 1;
    display      : block;
    font-size    : 12px;
    margin-bottom: 8px;
  }

  .neetlearnCard.card {
    .highlightCardlearn {
      .h_title {
        margin     : 10px 0px 0px 0px;
        height     : 40px;
        font-size  : 14px;
        padding-top: 10px;
      }
    }
  }

  .highlightCardneethighlights {
    min-height: 180px !important;
  }
}

@media only screen and(max-width: 600px) {
  .NeetPage .carsouel-card {
    width: 92% !important;
  }

  .emiTextneet {
    font-size: 12px;
  }
}

@media only screen and(max-width: 500px) {
  .highlightCardneethighlights {
    min-height: 230px !important;
  }
}