@import "assets/scss/config.scss";

@media only screen and(max-width: 991px) {
  .placementPage {
    .pgmlistsection:after {
      background-image: url(/assets/images/programs/placementcard.png);
      background-size: 100%;
      left: 0px;
      background-position: top;
    }
    .request-form {
      margin: 10% 0px;
    }
    .webView {
      display: none;
    }
    .mobileView {
      display: block;
    }
    .placementPage .rec-carousel .carsouelArrow {
      top: 50px;
    }
    .placementPage .tagline {
      margin-bottom: 50px;
    }
    .btn_round {
      padding: 10px 15px;
      font-size: 14px;
    }
    .topSection {
      .section_title {
        width: 100%;
        font-size: 35px;
        margin-top: 0rem;
        line-height: 1.5;
        margin-bottom: 2rem;
      }
      .section_title2 {
        text-align: left;
        width: 100%;
        font-size: 20px;
      }
    }
    .request-form .form-group {
      padding: 0rem 0rem;
    }
  }
  .placementCard .officerDesignation {
    height: auto;
  }
  .placementOfficers .slick-slider {
    margin-top: 0px !important;
  }
  .placementPage {
    .bannerText {
      padding-top: 0%;
    }
    .talkbtn {
      display: none;
    }
  }
  .cardRow {
    margin-top: 0rem;
  }
  .batch-modal {
    .sectionTitle2 {
      width: 80%;
      margin: auto;
      line-height: 1.5;
    }
    .closeIcon {
      transform: scale(0.6);
      top: 10px;
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }
  .form-modal.engineerForm-modal .modal-content {
    width: 100%;
    margin: auto;
  }
  main.placementPage .highlightCard {
    text-align: center;
    width: 92%;
    margin: 15px 8px 18px 14px;
    padding: 5px 15px;
    min-height: 200px;
    .h_title {
      font-size: 12px !important;
      margin: 0.5rem auto 4px !important;
      font-weight: bold !important;
    }
    .h_para {
      font-size: 12px !important;
      color:$paraColor !important;
      margin-bottom: 0.5rem !important;
    }
  }
}

@media only screen and(max-width: 768px) {
  .placementPage {
    .sectionTitle {
      font-size: 1.5rem;
      line-height: 41px;
      padding-top: 36px;
    }
  }
  .courseModal {
    .modal-dialog {
      max-width: 100%;
    }
    .included {
      .placement-flex {
        display: block;
      }
      .placement-item {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
        margin-right: 0px;
        width: 100%;
      }
    }
    .modal-top .courseclose {
      width: 13px;
    }
  }
  .topicslist li {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .carsouel-card ul li span {
    flex-grow: 1;
  }
  .placementPage {
    .benefit_Card.mobileappcard {
      background-image: none;
      padding-left: 30px;
      height: auto;
      p {
        flex-grow: initial;
      }
      .google-badge {
        display: block;
      }
    }
    .commonsection {
      padding: 40px 10px;
      &.learnerTestimonial {
        padding-bottom: 90px;
      }
    }
    .phone {
      margin-top: 20px;
    }
  }
  .batchCarsouel.custom-arrows .slick-arrow.slick-next {
    right: 30%;
  }
  .batchCarsouel.custom-arrows .slick-arrow.slick-prev {
    left: 35%;
  }
  .placementPage .bottom-arrows .slick-arrow {
    top: inherit;
    bottom: -70px !important;
  }
  .commonsection.placementOfficers.bgWhite {
    padding-bottom: 60px;
  }
  .placementPage .tagline {
    font-size: 16px;
    font-weight: bold;
    line-height: 48px;
    margin-bottom: 30px;
  }
  .faqAccordion.accordion .card .card-header button span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    padding-right: 20px;
    display: inline-block;
  }
  .faqAccordion.accordion .card .card-body {
    padding: 1rem 0px;
    color: #7c7ca2;
    font-size: 14px;
    line-height: 24px;
  }
  .placementPage {
    .talkbtn {
      display: block;
    }
  }
  main.placementPage .highlightCard {
    min-height: 250px;
    margin: 15px 8px 20px 8px;
  }
  .placementPage .testimonial-card .owner-cmp {
    padding: 7px 0px;
    justify-content: left;
  }
}

@media only screen and(max-width: 600px) {
  .placementPage .carsouel-card {
    width: 92% !important;
  }
}
  