@import "assets/scss/config";

.main-header {
  background: #fff;
  &.no-menu-header{
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 2;
    background: transparent;
  }
  &.vlsiheader{
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 2;
    background: transparent;
  }
  .navbar {
    padding: 15px 0px;
    .navbar-brand img {
      width: 100px;
    }
  }
  .navbar-nav .nav-link {
    color: $grayColor1;
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    min-width: auto;
    &.btn {
      padding-left: 25px;
      padding-right: 25px;
      height: 46px;
    }
    &.btn_primary {
      color: #fff !important;
    }
  }
  .univ-drop .dropdown-item {
    text-transform: uppercase;
  }
}

.no-menu-header .navbar {
  justify-content: space-between;
}

.main-header .navbar-nav .nav-link:hover {
  box-shadow: none;
}

.mobileHeader {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .closemenu {
    display: flex;
    margin-right: 0px;
    border: 0px;
    box-shadow: 0px 4px 8px #d0d0d0;
    right: 20px;
    z-index: 2;
    background: #fff;
    height: 42px;
    width: 42px;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  }
}

.vlsiheader a{
  text-decoration: none !important;
  .springbokspan {
    color: #000;
    display: block;
    margin-top: 10px;
    font-size: $font14px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 991px) {
  .mobileHeader {
    display: flex;
  }
  .navbar-collapse {
    position: fixed;
    height: 100vh !important;
    background: #fff;
    z-index: 2;
    width: 100%;
    top: 0px;
    right: 0px;
    padding: 20px 30px;
    border-left: 1px solid #ccc;
    overflow: auto;
    transition: all 0.3s linear;
    &.collapse:not(.show) {
      right: -100%;
      display: block !important;
    }
  }
  .navbar-nav .btn {
    width: 160px !important;
    height: 42px;
    border-radius: 4px;
    border: 1px solid $primaryColor;
    color: $primaryColor !important;
    margin-top: 1rem;
  }
  .main-header {
    .navbar-nav .nav-link {
      padding: 15px 20px;
    }
    &.vlsiheader{
      background: #fff;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer .logo-footer {
    flex-direction: column;
    align-items: flex-start !important;
    .moto-title {
      margin-top: 0px;
      margin-bottom: 1rem;
    }
    img {
      margin-bottom: 1rem;
    }
  }
  .main-header .container {
    padding: 0px 25px;
  }
  .no-menu-header .btn {
    min-width: auto;
    height: 40px;
    border-radius: 4px;
  }
  .vlsiheader a{
    .springbokspan {
      font-size: $font12px;
    }
  }  
}


