@import "assets/scss/config.scss";
.placementPage {
  .bannerText {
    padding-top: 10%;
  }
  .btn_primary {
    color: #fff !important;
  }
  .commonsection {
    padding: 80px 0px;
  }
  .pgmlistsection {
    min-height: auto;
    &:after {
      content: "";
      position: absolute;
      background-image: url("/assets/images/placement/banner-1.webp");
      background-repeat: no-repeat;
      background-position: right;
      height: 100%;
      width: 100%;
      background-size: 700px 100%;
      top: 0px;
    }
  }
  .sectionTitle {
    font-size: 2rem;
    line-height: 51px;
  }
  .center-show {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom-arrows .slick-arrow {
    top: inherit;
    bottom: -70px;
  }

  .placementsection {
    .slick-slider {
      margin-top: 80px;
    }
  }

  .top-arrows {
    .slick-arrow {
      top: -40px;
      bottom: inherit;
      &.slick-next {
        right: 10px;
      }
      &.slick-prev {
        right: 60px;
        left: inherit;
      }
    }
  }

  .imagesection {
    display: block;
    width: 100%;
    background-position: 0px 0px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .section_title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .section_title2 {
    font-size: 24px;
    text-align: center;
    line-height: 32px;
    margin-bottom: 30px;
    color: $paraColor;
  }

  .secondtxt {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .benefit_Card {
    padding: 30px;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 8px;
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      margin-top: 10px;
    }
    p {
      font-size: 14px;
      color: $paraColor;
      margin-top: 10px;
      line-height: 24px;
      &.bandwidth_p {
        min-height: 73px;
      }
    }
    &.card-color1 {
      background: #fdf3da;
    }
    &.card-color2 {
      background: #eec2ff;
    }
    &.card-color3 {
      background: #d4f5ff;
    }
    &.card-color4 {
      background: #dce1ff;
    }
    &.card-color5 {
      background-color: #c2ffd0;
    }
    &.mobileappcard {
      background-image: url("/assets/images/placement/mobile-app-support.webp");
      height: 230px;
      background-repeat: no-repeat;
      padding-left: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-position: 0px 30px;
    }
  }

  .benefit_C_content {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    background: #f7f7f7;
    padding: 20px 0px;
    margin-top: 50px;
  }

  /* For Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* Webkit browsers like Safari and Chrome */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .card {
    background: #fff;
    box-shadow: 0 11px 11px #e6e6e6b8;
  }
  .padding_card {
    padding: 30px 25px;
  }

  .commontbl {
    width: 65%;
    margin: 0px auto;
  }

  .table-bordered {
    border: 1px solid #ddd;
    th {
      background-color: #ceccdb;
      font-size: 13px;
      line-height: 15px;
      color: #482c7d;
      padding: 10px 19px;
      border-color: #c2c0ce;
      text-transform: uppercase;
      vertical-align: middle;
    }
    td {
      background-color: #fff;
      font-size: 15px;
      color: #000;
      line-height: 20px;
      padding: 10px 15px;
      font-weight: 400;
      vertical-align: middle;
    }
  }

  .tagline {
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 30px;
  }

  .whatsection {
    padding: 100px 0px;
  }

  img {
    max-width: 100%;
  }

  .contact-detail .contact-row {
    width: 850px;
    margin: 0 auto;
  }

  .contact-info {
    h1 {
      font-size: 32px;
      font-weight: 600;
      line-height: normal;
    }
    ul {
      list-style: none;
      margin: 30px 0 0;
      li {
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 15px;
        span {
          margin-right: 20px;
        }
      }
    }
  }

  .salient-features {
    margin-top: 35%;
    width: 80%;
  }

  .number-card {
    background: #ffffff;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px #00000014;
    padding: 30px;
    text-align: center;
    h3 {
      font-size: 28px;
      font-weight: 600;
      margin-top: 25px;
      margin-bottom: 0px;
    }
    p {
      color: $paraColor;
      font-size: 20px;
      margin-top: 20px;
    }
  }

  li {
    list-style: none;
  }

  .subpara {
    color: $paraColor;
    font-size: 20px;
    text-align: center;
    line-height: 27px;
    margin: 30px 0 100px;
  }

  .number_p {
    text-align: left !important;
    margin-bottom: 0px;
  }

  .grey_section {
    background: $bodyBg;
  }

  .app-link a {
    color: #000 !important;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none !important;
    span {
      margin-right: 10px;
    }
  }
  .planCost{
    font-size: 2rem !important;
    font-weight: bold !important;
  }

  .mobPlanCost{
    font-size: 1rem !important;
    font-weight: bold !important;
  }

  .disableSelectBatch{
    cursor: not-allowed !important;
    opacity: 0.5 !important;
    pointer-events: none !important;
  }

  .placements--lists {
    overflow: hidden;
    position: relative;
    .wrapper {
      -webkit-animation: 10s merchantlist linear infinite;
      animation: 10s merchantlist linear infinite;
      width: 2500px;
      margin: 0px;
      &:after {
        content: "";
        clear: both;
        display: table;
      }
      li {
        height: 55px;
        float: left;
        width: 150px;
        margin: 0px 30px;
        position: relative;
        padding: 10px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  @-webkit-keyframes merchantlist {
    from {
      -webkit-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
    }

    to {
      -webkit-transform: translate3d(-20%, 0px, 0px);
      transform: translate3d(-20%, 0px, 0px);
    }
  }

  @keyframes merchantlist {
    from {
      -webkit-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
    }

    to {
      -webkit-transform: translate3d(-20%, 0px, 0px);
      transform: translate3d(-20%, 0px, 0px);
    }
  }

  .imginfo {
    cursor: pointer;
    margin-top: 4px;
    margin-left: 10px;
    max-width: 14px;
    flex: 0 0 14px;
  }

  .btn-pack {
    margin: 1rem 0px;
    text-align: center;
  }

  .placementTextBlock {
    padding: 0px 10px;
    display: flex;
    height: 95%;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    .placementText {
      font-size: 24px;
      line-height: 36px;
      color: #39424e;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  .placements-card {
    padding: 20px 0px;
    position: relative;
    box-shadow: 0px 3px 6px #00000029;
    background: #fff;
    border-radius: 8px;
    margin-top: 2rem;
    .packName {
      font-size: 24px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      padding-top: 10px;
      margin-bottom: 1rem;
    }
    .placement-middle {
      color: #fff;
      font-size: $font24px;
      line-height: 36px;
      text-align: center;
      font-weight: bold;
    }
    .packUl {
      margin-bottom: 40px;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 0.6rem;
        &:last-child {
          margin-bottom: 0rem;
        }
        .packIcon {
          width: 28px;
          height: 25px;
          text-align: center;
          background-image: url("/assets/images/sprite_icons.webp");
          &.calendar {
            background-position: -4px -80px;
          }
          &.mentor {
            background-position: -294px -47px;
          }
          &.interview {
            background-position: -73px -84px;
          }
        }
        span {
          font-size: 32px;
          line-height: 36px;
          color: rgb(255, 255, 255);
          font-weight: 800;
          max-width: 50px;
          text-align: right;
          padding-right: 15px;
          flex: 0 0 50px;
        }
        p {
          font-size: 12px;
          line-height: 16px;
          color: rgb(255, 255, 255);
          max-width: 100px;
          margin: 0px;
          flex: 0 0 100px;
        }
      }
    }
    .placement-row {
      display: flex;
      &:after {
        content: "";
        clear: both;
        display: block;
      }
    }
    .placement-th,
    .placement-td {
      flex: 0 0 22%;
      max-width: 22%;
      padding-left: 1%;
      &:first-child {
        flex: 0 0 33%;
        max-width: 33%;
        padding: 0px;
      }
    }
    .placement-head {
      padding: 0px 15px;
      background: #fff;
      border-radius: 32px;
      position: sticky;
      top: 0px;
      &:after {
        content: "";
        clear: both;
        display: table;
      }
    }
    .placement-body {
      margin-bottom: 20px;
      .placement-row {
        padding: 0px 25px;
        &:last-child {
          .placement-td {
            border: 0px;
          }
        }
        .placement-td {
          color: $paraColor;
          padding: 13px 0px;
          border-bottom: 1px solid #e7e7e7;
          &.diamond-column {
            border-left: 1px solid #e7e7e7;
            border-right: 1px solid #e7e7e7;
          }
          &:first-child {
            font-size: $font16px;
            line-height: 20px;
          }
        }
      }
      .tickRound {
        background-image: url("/assets/images/sprite_icons.webp");
        background-position: -179px -79px;
        background-repeat: no-repeat;
        height: 23px;
        width: 30px;
        margin: auto;
      }
    }
  }

  .intern-card {
    width: 90%;
    min-height: 160px;
    margin-bottom: 30px;
    .intern-img {
      margin-bottom: 30px;
    }
    label {
      font-size: 20px;
      line-height: 20px;
    }
    p {
      margin: 0px;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .panel-group .panel + .panel {
    margin: 0px;
  }

  .panel-group {
    .panel {
      margin: 0px;
      border: 0px;
      border-bottom: 1px solid #bcbdc2;
      background: #fafbfc;
      .panel-title {
        color: #000;
        padding: 25px 15px;
        font-size: 20px;
      }
      > .panel-heading {
        cursor: pointer;
        position: relative;
        background: #fafbfc;
        border-radius: 4px;
        & + .panel-collapse > .panel-body {
          padding: 15px;
          border: 0px;
          font-size: 16px;
        }
      }
      &.active {
        .caret-awesome {
          transform: rotate(180deg);
          top: 25px;
        }
      }
    }
    .caret-awesome {
      top: 35px;
      position: absolute;
      right: 20px;
      font-size: 20px;
      color: #000;
      // background-image: url('assets/images/placement/caret-down.svg');
      background-size: contain;
      background-repeat: no-repeat;
      height: 15px;
      width: 15px;
    }
  }
  .carsouel-card {
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    width: 97% !important;
    margin: 15px;
    ul {
      li {
        display: flex;
        .imginfo {
          max-width: 16px;
          flex: 0 0 16px;
        }
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      width: auto;
      span {
        margin-right: 15px;
      }
      img {
        width: auto;
      }
    }
    ul {
      li {
        padding: 15px;
      }
    }
    .btn-section {
      padding: 20px;
    }
  }

  .google-badge {
    margin-top: 20px;
    width: 50%;
  }

  .btn span {
    margin-right: 8px;
  }

  #placements-Carsouel {
    display: none;
  }

  .svgwhite {
    display: inline-flex;
    align-items: center;
  }

  #testimonial-modal .modal-content {
    overflow: hidden;
    width: 550px;
    margin: 0 auto;
    background: #fff;
    padding-bottom: 50px;
    box-shadow: 0 4px 8px #ccc;
    margin-top: 50px;
    border: 0px;
    border-radius: 0px;
    padding: 0px;
  }
  #testimonial-modal .modal-content {
    width: 100%;
    box-shadow: none;
    padding: 35px;
  }
  #testimonial-modal .modal-header {
    border: 0px !important;
    padding: 0px;
    right: 0px;
    display: block;
    position: static;
  }
  #testimonial-modal button.close {
    position: absolute;
  }
  #testimonial-modal .modal-body {
    padding: 0px;
    text-align: left;
  }
  #testimonial-modal .success-block {
    display: flex;
    flex-direction: column;
  }
  #testimonial-modal .short-desc p {
    height: auto;
  }

  #testimonial-modal .modal-content button.close {
    position: absolute;
    z-index: 99;
    color: #717171;
    right: 10px;
    top: 10px;
    opacity: 0.6;
    font-size: 25px;
    border: 2px solid #717171;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  #testimonial-modal .modal-content button.close span {
    display: block;
    margin-top: -3px;
  }
  .placement-track-info {
    background-color: transparent;
    border: none;
    &:focus {
      outline: none;
    }
    .glyphicon {
      color: #a0a0a0;
      font-size: 13px !important;
      margin-left: 6px;
    }
  }
  .popover {
    border-radius: 3px;
  }
  .info-text {
    color: #4d4d4d;
    font-size: 15px;
  }

  .numberlist {
    margin: 0px;
    padding-left: 15px;
    li {
      list-style: decimal;
      line-height: 26px;
    }
  }

  .courseclose {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .placement-head {
    .gold-block,
    .diamond-block,
    .platinum-block {
      border-radius: 8px;
      padding: 15px;
      position: relative;
      overflow: hidden;
    }
  }

  .breadcrumb-section {
    padding: 20px 0;
    background: #ebf3ff;
    label {
      margin: 0px;
      font-size: 16px;
    }
  }

  .btn_round {
    display: inline-block;
    background: #ec5578;
    color: #fff;
    border-radius: 108px;
    padding: 20px 25px;
    font-size: 20px;
    font-weight: 600;
  }

  .phone {
    margin-top: 40px;
    display: flex;
    align-items: center;
    font-weight: 700;
    a {
      font-size: 25px;
      color: #000000;
      text-decoration: none !important;
    }
    img {
      width: 30px;
      margin-right: 5px;
    }
    .text-wrapper {
      position: relative;
      display: inline-block;
      padding-top: 0.1em;
      padding-right: 0.05em;
      padding-bottom: 0.15em;
    }
    .line {
      opacity: 0;
      position: absolute;
      left: 0;
      height: 100%;
      width: 3px;
      background-color: #ec5578;
      transform-origin: 0 50%;
    }
    .line1 {
      top: 0;
      left: 0;
    }
    .letter {
      display: inline-block;
      line-height: 1em;
      color: #39424e !important;
    }
  }

  .talkbtn {
    display: none;
    position: fixed;
    bottom: 60px;
    left: 0px;
    text-align: center;
    background: #fff;
    width: 100%;
    padding: 10px 0px;
    z-index: 3;
    box-shadow: 0px -6px 8px #00000036;
    .btn {
      margin: 0px;
    }
    .testimonialsection {
      padding: 0px;
      .slick-track {
        .slick-slide {
          &:nth-child(3n - 3) {
            .ctc {
              color: $yellowColor;
              background: #fffaeb;
            }
          }
          &:nth-child(3n - 2) {
            .ctc {
              color: $diamond-color;
              background: #dad5ff;
            }
          }
        }
      }
      .testimonial-card {
        display: flex !important;
        flex-direction: column-reverse;
        margin: 15px auto;
        padding: 10px 20px;
        .testi-desc {
          display: none;
        }
        .ctc {
          padding: 5px 14px;
          background: #dcf2ff;
          border-radius: 4px;
          color: #067bc2;
          font-size: $font16px;
          font-weight: normal;
          &:before {
            content: none;
          }
        }
        .testimonial-details {
          display: flex !important;
          align-items: center;
          .testimonial-image {
            height: 56px;
            max-width: 56px;
            margin: 0px;
            flex: 0 0 56px;
          }
          h3 {
            font-size: $font16px;
            text-align: left;
          }
        }
      }
      .slick-arrow {
        display: none !important;
      }
    }
  }

  .limelink {
    text-align: center;
    margin-bottom: 1rem;
  }

  .request-form {
    padding: 20px;
    background: $bodyBg;
    .navbar-brand {
      display: none;
      margin-right: 10px !important;
    }
    .alert {
      width: 90%;
      margin: 0px auto 20px;
    }
    .form-group label {
      color: #1b1820;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 0.75rem;
    }
    .form-group {
      padding: 0rem 2rem;
      margin: 1rem 0px;
    }
    .form-control {
      border-radius: 4px;
      outline: none;
      box-shadow: none;
      background: #f5f5f5;
      &:focus {
        background: #fff;
        border-color: #ccc;
      }
      &.error {
        border-color: red;
      }
    }
    &.active {
      top: 10%;
      .formbg {
        top: 0px;
      }
      .registerClass {
        display: block;
      }
    }
    .registerClass {
      display: none;
      position: fixed;
      cursor: pointer;
      right: 30px;
      width: 24px;
      top: 30px;
    }
    .formTitle {
      color: #0c0c0c;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      margin-top: 20px;
    }
    .formPara {
      color: #1b1820;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      width: 80%;
      margin: 20px auto 0px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
  }

  .btn-section {
    text-align: center;
    margin-bottom: 1rem;
    .btn {
      height: 40px;
      width: 180px;
      line-height: 20px;
      padding: 8px 15px;
      border-radius: 4px;
      font-size: 16px;
    }
  }

  .topSection {
    display: flex;
    align-items: center;
  }
  .bannersection {
    padding: 0px;
  }

  .request-form {
    .register-details {
      &.float-form {
        position: fixed;
        top: 0px;
        width: 100%;
        left: 0px;
        z-index: 3;
        box-shadow: 0px 3px 8px #e7e7e7;
        .navbar-brand {
          display: block;
        }
        .form {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          .formtexts {
            .formTitle {
              margin: 0px;
              text-align: left;
              font-size: 16px;
              padding-left: 5px;
            }
            .formPara {
              display: none;
            }
          }
        }
        .formSets {
          display: flex;
          margin: 8px -15px;
        }
        .form-wrapper {
          display: flex;
          justify-content: space-between;
        }
        .form-group {
          margin: 0px;
        }
        .btn-section {
          display: flex;
          align-items: flex-end;
        }
        .alert {
          width: 90%;
          margin: 0px auto 20px;
          position: fixed;
          width: auto;
          right: 20px;
        }
      }
    }
  }

  .topSection {
    .section_title {
      width: 70%;
    }
    .section_title2 {
      text-align: left;
      width: 85%;
    }
  }

  // .btn {
  //   outline: none !important;
  //   border: 0;
  //   font-size: 14px;
  //   padding: 9px 20px;
  //   height: 48px;
  //   line-height: 28px;
  //   background: transparent;
  //   overflow: hidden;
  //   position: relative;
  //   border-radius: 4px;
  //   min-width: 150px;
  //   border: 1px solid transparent;
  //   cursor: pointer;
  //   color: #ec5578;
  //   &.btn_grey {
  //     background-color: $primaryColor;
  //     color: #fff;
  //   }
  // }

  .bgBody {
    &,
    .bgWhite {
      background-color: $bodyBg;
    }
  }

  .testimonial-card {
    width: 93% !important;
    margin: 15px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    .testimonial-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .testimonial-details {
      .testimonial-image {
        height: 96px;
        width: 96px;
        background: #fbe99f;
        border-radius: 100%;
        overflow: hidden;
        margin: 1rem auto;
      }
      h3 {
        margin: 0px;
        font-size: $font16px;
        color: #39424e;
        line-height: 24px;
        text-align: center;
        padding: 10px;
        font-weight: bold;
      }
      p {
        font-size: $font14px;
        line-height: 24px;
        color: $paraColor;
        text-align: center;
        padding: 0px 25px;
      }
    }
    .testi-desc {
      min-height: 200px;
      p {
        margin: 0px;
      }
    }
    .owner-cmp {
      width: 100px;
      padding: 7px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 100%;
      }
    }
  }
  .rotateLeft {
    transform: rotate(180deg);
  }
  .rec-carousel {
    .carsouelArrow {
      position: absolute;
      height: 40px;
      width: 40px;
      background: #fff;
      border-radius: 100%;
      opacity: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -60px;
      &.carsouelNext {
        right: 25px;
      }
      &.carsouelPrev {
        right: 75px;
      }
      &:hover {
        box-shadow: $boxShadow;
      }
    }
    .rec-slider-container {
      margin: 0px;
    }
  }

  .accordion {
    .card {
      box-shadow: none;
    }
  }
  .form-group .form-control.error,
  .form-group .form-control.is-invalid {
    border: 1px solid #ff0043 !important;
  }
  .mobileView {
    display: none;
  }

  .packNavs {
    border-radius: 8px;
    background: #fff;
    box-shadow: $boxShadow;
    .nav-tabs {
      background: $bodyBg;
      border: 0px;
      border-radius: 10px;
      .nav-link {
        font-size: 14px;
        color: $darkColor !important;
        font-weight: bold;
        max-width: 33.33%;
        flex: 0 0 33.33%;
        text-align: center;
        border: 0px;
        border-radius: 0px;
        padding: 10px;
        &.active {
          color: #fff !important;
          &:first-child {
            background: $yellowColor;
            border-top-left-radius: 8px;
          }
          &:nth-child(2) {
            background: $diamond-color;
          }
          &:last-child {
            background: $corporateTag;
            border-top-right-radius: 8px;
          }
        }
      }
    }
    .tab-content {
      .packName,
      .placement-middle,
      .emiText {
        display: none;
      }
      .packBtn {
        .emiText {
          display: block;
        }
      }
      .placement-item {
        background: transparent;
        padding: 15px;
      }
      .tab-pane {
        overflow: hidden;
        position: relative;
      }
      .packUl {
        li {
          display: flex;
          align-items: center;
          margin: 1rem 10px;
          span,
          p {
            color: $darkColor;
            font-size: $font16px;
            color: #39424e;
          }
          p {
            margin: 0px;
          }
          span {
            margin-right: 5px;
            margin-left: 10px;
          }
          .packIcon {
            width: 28px;
            height: 25px;
            text-align: center;
            background-image: url("/assets/images/sprite_icons.webp");
            opacity: 0.6;
            transform: scale(0.7);
            &.calendar {
              background-position: -40px -80px;
            }
            &.mentor {
              background-position: -147px -82px;
            }
            &.interview {
              background-position: -110px -83px;
            }
          }
        }
      }
    }
  }
}

.courseModal {
  .modal-content {
    border-radius: 4px;
  }
  .modal-dialog {
    max-width: 80%;
  }
  .modal-top {
    background: #4a187e;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 40px 30px;
    display: flex;
    align-items: center;
    .modal-img {
      max-width: 90px;
      flex: 0 0 90px;
    }
    .courseclose {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }
  .course-top {
    color: #fff;
    .label {
      font-size: 20px;
      line-height: normal;
      font-weight: bold;
      margin: 0px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      margin-bottom: 0px;
      margin-top: 20px;
    }
  }
  .modal-body {
    padding: 20px 30px;
    .mod-tit {
      font-size: 20px;
      line-height: 56px;
      font-weight: 600;
      color: #212b48;
    }
    .mod-p {
      margin: 0px;
      color: #111111;
      font-size: 14px;
      line-height: 28px;
    }
  }
  .panel-group .panel .panel-title {
    color: #212b48;
    padding: 13px;
    font-size: 16px;
    font-weight: bold;
    background: #fafbfc;
    padding-right: 45px;
    .caret-awesome {
      top: 12px;
      right: 20px;
    }
  }
}

.include-ul {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    border-left: 1px solid #f7f7f7;
    height: 100%;
    left: -20px;
  }
  li {
    font-size: 14px;
    line-height: 30px;
    color: #212b48;
    position: relative;
    padding-left: 25px;
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0px;
      background-image: url("/assets/images/placement/greentick.svg");
      height: 15px;
      width: 15px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.grey-tit {
  font-size: 12px;
  line-height: 46px;
  color: #9aa1a9;
  text-transform: uppercase;
}

.big-tit {
  color: $darkColor;
  font-size: 24px;
  text-align: center;
  line-height: 20px;
  margin-bottom: 2rem;
  font-weight: bold;
}

.included {
  .placement-item {
    margin-bottom: 20px;
    flex: 0 0 32%;
    max-width: 32%;
    border-radius: 4px;
    padding: 15px;
    margin-right: 15px;
    &:last-child {
      margin-right: 0px;
    }
  }
  .placement-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0px 15px;
    .placement-bottom {
      text-align: center;
      margin: 20px 0px;
    }
    .gold-block {
      .placement-bottom {
        margin-top: 50px;
      }
    }
  }
}

.gold-block {
  .placement-bottom {
    margin-top: 40px;
  }
}

.topicslist {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -15px;
  li {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0px 15px;
    margin-bottom: 4px;
    span {
      color: $darkColor;
      padding: 13px;
      font-size: $font14px;
      font-weight: bold;
      background: #fafbfc;
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
}
.gold-block {
  background: $yellowColor;
}
.diamond-block {
  background: $diamond-color;
}
.platinum-block {
  background: $corporateTag;
}

.placement-bottom {
  .btn {
    span {
      margin-right: 8px;
    }
  }
}

.placement-bottom {
  .btn_grey {
    background-color: $primaryColor;
    color: #fff;
  }
}

.clr20 {
  height: 20px;
}

.placementCard {
  border-radius: 8px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000029;
  width: 92% !important;
  margin: 15px;
  .placementImage {
    height: 96px;
    width: 96px;
    margin: auto;
    margin-bottom: 1rem;
    img {
      border-radius: 100%;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
    }
  }
  .officerName {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    img {
      height: 13px;
      margin-left: 5px;
    }
  }
  .officerDesignation {
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
    height: 70px;
    color: $paraColor;
  }
}

.center-arrows {
  .slick-arrow {
    &.slick-next {
      right: -14px;
    }
    &.slick-prev {
      left: -14px;
      z-index: 1;
    }
  }
}

.ctc {
  background: #067bc2;
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  position: relative;
  padding: 5px 15px 4px 40px;
  margin-top: 10px;
  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 25px solid #fff;
    left: 0px;
    top: 0px;
  }
}

.cardRow {
  margin-top: 3rem;
}

.courseOffer {
  color: $darkColor;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2.4px;
  background: $bodyBg;
  padding: 10px 25px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.testimonial-block {
  .slick-track {
    .slick-slide {
      &:nth-child(3n - 3) {
        .ctc {
          background: $yellowColor;
        }
      }
      &:nth-child(3n - 2) {
        .ctc {
          background: $diamond-color;
        }
      }
    }
  }
}

.slick-slider.batchCarsouel .slick-current .modal-batch-card {
  background: #ff416e;
  color: #fff;
  transform: scale(1);
}

.slick-slider.batchCarsouel .slick-current .batch-title,
.slick-slider.batchCarsouel .slick-current ul li p {
  color: #fff;
}

.slick-slider.batchCarsouel .slick-current .modal-batch-card .btn {
  display: block;
  background: #fff !important;
  color: #ff416e;
}

.testimonialsection {
  margin-top: -15px;
  background: #fff;
  padding-bottom: 50px;
}

.highlightsSection {
  background: #fff;
  padding-top: 15px;
}

.form-modal .engineerForm {
  width: 100%;
  background: transparent;
  box-shadow: none;
  position: static;
}
.engg_tution_modal.form-modal .engineerForm {
  .formTitle {
    color: #000;
  }
  .label,
  .formPara {
    color: #756f6f;
  }
}

.placementPacks .placementsection {
  background: $bodyBg;
}

.packQuote {
  padding: 25px 65px 0px 25px;
  font-size: 0.875rem;
  color: $paraColor !important;
}

.rateText {
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
}

.packBtn {
  padding: 20px 30px;
  border-top: 1px solid #ccc;
  text-align: center;
  .btn {
    margin: 1rem 0px;
  }
}

.batchCarsouel {
  &.slick-slider {
    margin: 0px;
    margin-bottom: 60px;
  }
  &.custom-arrows .slick-arrow {
    &.slick-prev {
      left: 42%;
      right: inherit;
    }
    &.slick-next {
      right: 42%;
    }
  }
}

.topics-modal .topicsUl {
  margin: 2rem 0px;
}

.tagtext {
  position: absolute;
  color: #fff;
  right: -31px;
  transform: rotate(45deg);
  background: red;
  padding: 2px 22px;
  top: 22px;
  font-size: 15px;
}
.form-modal {
  &.engineerForm-modal {
    .modal-content {
      width: 60%;
      margin: auto;
    }
  }
}

.cardplacement {
  border-radius: 0.5rem !important;
  margin-top: 87px !important;
  border: none !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14) !important;
  .sectionTitlemobile {
    text-align: center;
    color: #241f48;
    margin-top: 12px;
    font-weight: bold;
    font-size: 16px;
  }
  .sectionparamobile {
    padding-top: 14px;
    text-align: center;
    font-size: 12px;
    color: #7c7ca2;
  }
  .button_load {
    display: flex;
    justify-content: space-around;
    .btn.btn_primary.call {
      margin-top: 20px;
      min-width: 13px !important;
      height: 43px !important;
      background: none !important;
      color: #ff416e !important;
      border: 1px solid #f5f8ff !important;
      font-size: 0.75rem !important;
      span {
        background-position: -152px -114px;
        transform: scale(0.7);
        position: relative;
        top: 1px;
        height: 30px;
      }
    }
    .btn.btn_primary.courseshow {
      margin-top: 20px;
      min-width: 13px !important;
      height: 43px !important;
      font-size: 11px !important;
    }
  }
}
.spiriteicon {
  background-image: url("/assets/images/sprite_icons.webp");
  background-repeat: no-repeat;
  &.phonecallicon {
    background-position: -102px -43px;
    width: 28px;
    height: 17px;
    margin-left: -6px;
  }
}
.highlightCardmobileschool {
  width: 90% !important;
  text-align: center;
  margin: 20px;
  border: none !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14) !important;
}
.col-sm-12.sliderparent {
  padding-bottom: 3rem;
}

.highlightCardmobileplacement.card {
  width: 90% !important;
  text-align: center;
  margin: 20px;
  border: none !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14) !important;
}
.highlightsSection {
  padding-bottom: 2rem;
}

.button_load .btn {
  max-width: 47%;
  flex: 0 0 47%;
  padding: 10px 1rem;
}

.placement-item {
  .emiText {
    color: #fff;
    text-align: center;
    &.batchEmiText {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.sliderabcdhighlights {
  .slick-arrow.slick-prev, .slick-next {
    position: relative !important;
  }
}

.batch-modal .modal-body {
  padding:1rem 1rem 4rem 1rem;
}