@import 'assets/scss/config.scss';
.cardStyle{
  background: #fff;
  border-radius: 8px;
  box-shadow: $boxShadow;
  margin-bottom: 2rem;
}


.hero-bkg-animated {
  background-image: url('/assets/images/placementLogos.webp');
  width: 100%;
  background-repeat-y: no-repeat;
  margin: 0;
  text-align: center;
  height: 70px;
  background-size: cover;
  margin-top: 2rem;
  box-sizing: border-box;
  animation: slide 100s linear infinite;
}
.ebox_clients_list_scroll {
  background-image: url('/assets/images/eboxClientsLogo.webp');
  width: 100%;
  background-repeat-y: no-repeat;
  margin: 0;
  text-align: center;
  height: 70px;
  background-size: cover;
  margin-top: 2rem;
  box-sizing: border-box;
  animation: slide 100s linear infinite;
}

@keyframes slide {
    from { background-position: 0 0; }
    to { background-position: -6363px 0; }
}


.learner_txt{
  margin-top: 3rem;
}


.learnerCard {
  background: #fff;
  border-radius: 8px;
  box-shadow: $boxShadow;
  padding: 20px;
  display: flex !important;
  margin: 15px;
  width: 92% !important;
  .learnerDesc{
     flex-grow: 1;
     .label{
        color: $paraColor;
        font-size: $font16px;
        margin-bottom: 0.5rem;
     }
     p{
        margin: 0px;
        color: $darkColor;
        font-weight: 700;
     }
  }
  .name-cmp {
    display: flex;
    flex-direction: column;
    .cmp{
      margin-left: 5px;
    }
  }
  .learnerImage{
     img{
        height: 120px;
        max-width: 120px;
        flex: 0 0 120px;
        border-radius: 100%;
        margin-left: 15px;
     }
  }
}

.custom-arrows{
  .slick-arrow{
    &.slick-prev, &.slick-next {
      position: absolute;
      height: 40px;
      width: 40px;
      background: #fff;
      border-radius: 100%;
      opacity: 1;
      box-shadow: $buttonShadow;
      &:hover{
        box-shadow: none;
      }
      &:before{
        content: '';
        background-image: url('/assets/images/carsouelArrow.png');
        height: 20px;
        width: 10px;
        display: block;
        background-repeat: no-repeat;
        margin: auto;
      }
    }
    &.slick-prev{
      right: 300px;
      left: inherit;
      &:before{
        transform: rotate(180deg);
      }
    }
    &.slick-next{
      right: 240px;
    }
  }
}

.commonsection{
  position: relative;
  &.learnerTestimonial {
    padding-bottom: 5rem;
  }
}


.limecard{
  margin: 15px;
  @extend .cardStyle;
  padding: 20px;
  padding-bottom: 30px;
  width: 92% !important;
  .limeImage {
     padding-bottom: 20px;
  }
  .limetext {
     font-weight: 600;
     font-size: $font16px;
     line-height: 24px;
     margin-bottom: 1rem;
     min-height: 130px;
  }
  .limetextpara{
    font-size: 1rem;
    color: #7C7CA2;
    margin: 0px;
    line-height: 1.5;
  }
}

#learnerTesti{
  .carousel.slide {
    margin: 0px -15px;
  }
}

.bottom-arrows .slick-arrow {
  bottom: -70px;
  top: inherit;
}

.commonsection.limelight {
  padding-bottom: 100px;
}

.mbarrows .limecard .limetextpara{
  height: 180px !important;
}


@media only screen and (max-width: 768px){
  .custom-arrows{
    .slick-arrow{
      &.slick-prev {
        left: 35%;
      }
      &.slick-next {
        right: 30%;
      }
    }
  }
  footer .footer-flex .footerRight ul li {
    margin: 1rem 20px 2rem 0px;
  }
  .learner_txt{
    margin-top: 3rem;
    text-align: center;
  }
  .mbarrows .limecard .limetextpara{
    margin-bottom: 20px;
    height: auto !important;
  }
}
