/* Custom Ebox */
@import "./icon/path.scss";

$svgIconColor: "%234d4d4d";
$svgActiveColor: "%23471e7f";

$grayColor1: #1b1c1e;
$grayColor2: #4d4d4d;
$grayColor3: #808080;
$grayColor4: #c7c7c7;
$grayColor5: #d6d6d6;
$grayColor6: #f5f5f5;
$grayColor7: #5E5E5E;

$yellowColor: #ffa022;
$purchasedColor: $yellowColor;
$completedColor: #169a48;
$premiumColor: #a34099;
$scheduleColor: #e0f973;

$layoutPadding: 30px;
$layoutPaddingTablet: 15px;
$layoutPaddingMobile: 0;

$borderRadius: 2px;

/*-------------------
       Fonts
--------------------*/

$fontName: "Nunito Sans";
$googleFontName: "Nunito+Sans";

$headerFont: $fontName, Arial, Helvetica, sans-serif;
$pageFont: $fontName, Arial, Helvetica, sans-serif;

$googleFontSizes: "400,700,900,400italic,700italic,900italic";

$black: black;
$white: white;
$bold: bold;
$normal: normal;

/*-------------------
      Base Sizes
--------------------*/

/* This is the single variable that controls them all */
$emSize: 14px;

/* The size of page text  */
$largeSize: 16px;
$fontSize: 14px;
$smallSize: 12px;

/*-------------------
    Brand Colors
--------------------*/


//Color Theme
$bodyBg : #FFFFFF;
$primaryColor: #FF416E;
$secondaryColor: #522e8f;
$darkColor: #1B1C1E;
$darkColor2: #050A0E;
$paraColor: #4F4F4F;
$blogTextColor: #5F6368;
$paraColorMobile: #939393;
$placeholderColor: #D8D8D8;
$errorColor: #FF0043;
$collegeTag: #FD9B39;
$corporateTag: #067BC2;
$schoolTag: $secondaryColor;
$allTag: #3A9F7E;
$footerBg: #241F48;
$yellowColor: #FFC300;
$courseBg: #F9FBFF;
$diamond-color: #463A9F;
$planActive: $corporateTag;
$factBg: #F0EFF8;
$colorRed1: #e85a50;
$colorYellow1 : #fea64c;
$colorYellow2 : #F4A953;
$colorBlue1   : #1c7094;
$colorGray3   : #eae8dc;
$colorWhite1  : #D9D9D9;
$colorWhite   : #ffffff;
$colorGray1   : #a9a79f;
$colorBlue2: #1EC3CD;
$colorVoilet: #6F545B;
$colorBlack: #000;
$colorGray2: rgba(99, 99, 99, 0.85);
$colorViolet: #48237e;
$colorPurple: #88888d;
$lightPrimaryColor:#FFF2F5;
$lightSecondaryColor:#F9F5FF;


//Card Theme
$borderRadius: 8px;
$boxShadow: 0px 0px 16px rgba(0,0,0,0.14);
$borderColor: #dee2e6;


//Button Theme
$buttonShadow: 0px 2px 4px rgba(0,0,0,0.24);


//Font Theme
$font48px: 3rem;
$font44px: 2.75rem;
$font40px: 2.5rem;
$font34px: 2.125rem;
$font28px: 1.75rem;
$font24px: 1.5rem;
$font20px: 1.25rem;
$font16px: 1rem;
$font14px: 0.875rem;
$font12px: 0.75rem;
$font10px: 0.625rem;
$font8px: 0.5rem;

/*--------------
  Page Heading
---------------*/

$headerFontWeight: $bold;

$h1: 50px;
$h2: 40px;
$h3: 30px;
$h4: 20px;
$h5: 15px;
$h6: 10px;

/*-------------------
        Page
--------------------*/

$pageBackground: #ffffff;

$lineHeight: 1.5;
$textColor: rgba(0, 0, 0, 0.87);

/*-------------------
      Paragraph
--------------------*/

$paragraphMargin: 0em 0em 1em;
$paragraphLineHeight: $lineHeight;

$mediumScreen: 1200px;
$bigTabletScreen: 992px;
$tabletScreen: 768px;
$mobileScreen: 575px;

/*-------------------
      digital
--------------------*/
$accordianColor: #532e8f;
$accordianBgColor: #feeffb;

/*-------------------
       Fonts
--------------------*/

$primaryFontName          : 'Robot Slab';
$secondaryFontName          : 'Brother';
$black2            :#000000;
$normal            : normal;

/*-------------------
      Base Sizes 
--------------------*/

/*------------------
    Brand Colors
--------------------*/
$secondaryColor      : #00B1E5;
$whiteColor          : #FFFFFF;
$grayLight1          : #969696;
$grayLight2          : #D9D9D9;
$grayLight3          : #F5F5F5;
$grayLight4          : #F9D5BC;
$blueVariant         : #20A0D3;
$redVariant          : #EA443E;
$redVariant2         : #FFD2D0;
$steelBlue           : #2A87A2;
$warmPeach           : #FFF6E6;

/*-------------------
     Breakpoints
--------------------*/

$mobileBreakpoint             : 480px;
$tabletBreakpoint             : 768px;
$bigTabletBreakpoint          : 992px;
$mediumMonitorBreakpoint       : 1200px;

$media_queries : (
    'mobileScreen'       : #{"only screen and (max-width: #{$mobileBreakpoint})"},
    'tabletScreen'       : #{"screen and (min-width: #{$mobileBreakpoint + 1}) and (max-width: #{$tabletBreakpoint}) "},
    'bigTabletScreen'    : #{"screen and (min-width: #{$tabletBreakpoint + 1}) and (max-width: #{$bigTabletBreakpoint}) "},
    'mediumScreen'       : #{"screen and (min-width: #{$bigTabletBreakpoint + 1}) and (max-width: #{$mediumMonitorBreakpoint}) "},
    'computer'           : #{"only screen and (min-width: #{$mediumMonitorBreakpoint + 1})"}
);


$boxshadowcolor1 : rgba(201, 201, 201, 0.2);
$boxshadowcolor2 : rgba(212, 212, 212, 0.19);