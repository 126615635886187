@import 'assets/scss/config.scss';

.cardStyle{
  background: #fff;
  border-radius: 8px;
  box-shadow: $boxShadow;
  margin-bottom: 2rem;
}

.pgmsection{
  .programCard{
    min-height: 430px;
    display: block;
    text-decoration: none !important;
  }
}

.programCard {
  @extend .cardStyle;
  a:hover{
    text-decoration: none;
    .pgmTitle{
      text-decoration: underline;
    }
  }
  .programImage {
    position: relative;
    height: fit-content;
    img {
      width: 100%;
      border-radius: 8px 8px 0px 0px;
    }
    .pbmtype {
      position: absolute;
      bottom: 20px;
      left: 20px;
      border-radius: 26px;
      padding: 5px 15px;
      font-size: .875rem;
      line-height: 22px;
      color: #fff;
      text-transform: capitalize;
      &.collegespgm {
        background: $collegeTag;
      }
      &.corporatespgm{
          background: $corporateTag;
      }
      &.schoolspgm{
          background: $schoolTag;
      }
      &.allpgm{
        background: $allTag;
      }
    } 
  }
  .pgmSet {
      padding: 20px;
      .pgmTitle {
         font-size: $font20px;
         color: $darkColor;
         font-weight: 600;
         line-height: 1.5;
         margin-bottom: 0.5rem;
      }
      p{
         font-size: $font16px;
         color: $paraColor;
         margin: 0px;
         line-height: 1.5;
      }
   }
}
.pgmSets {
  padding: 20px;
  margin-top: 70px;
}