.form-group {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  label{
    font-size: $font14px;
    line-height: 21px;
    color: $paraColor;
  }
  .form-control{
    font-size: $font14px;
    line-height: 21px;
    border: 1px solid $placeholderColor;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    height: 48px;
    &.error,
    &.is-invalid{
      border-bottom: 1px solid $errorColor !important;
    }
    &::-webkit-input-placeholder {
      color: $placeholderColor;
    }
    &:-moz-placeholder {
      color: $placeholderColor;
    }
    &::-moz-placeholder {
      color: $placeholderColor;
    }
    &::placeholder {
      color: $placeholderColor;
    }
    &:-ms-input-placeholder {
      color: $placeholderColor;
    }
  }
  .float-label {
    position: absolute;
    right: 0px;
    top: 7px;
    font-size: $font12px;
    color: $placeholderColor;
  }
}

.error-text {
  margin: 0px;
  margin-top: 5px;
  color: $errorColor;
  font-size: $font12px;
}