@import "assets/scss/config";

.mentorScreen {
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background: #fff;
    overflow: hidden;
    .headerNav{
        background-color: #fff !important;
        .headerNavContainer {
            background-color: #fff;
            .eboxLogo {
                width: 50%;
            }
        }
    }
    .carouselSection {
        position: relative;
        padding-top: 120px;
        min-height: 100vh;
        .carouselContainer {
            text-align: center;
            .sectionquote{
                color: #b9b9b9;
                font-style: italic;
                text-align: center;
                font-size: 22px;
                margin-bottom: 20px;
            }
            .section_centerquote{
                color : #000000;
                font-size: 32px;
                font-weight: bold;
            }
            .btnsection {
                text-align: center;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .btn {
                transition: all 0.2s linear;
                outline: none !important;
                border: 0;
                font-size: 16px;
                padding: 7px 20px;
                border-radius: 2px;
                height: 35px;
                background: transparent;
                margin: 10px;
                min-width: auto;
            }
            .btn_primary {
                background-color: #ec5578;
                position: relative;
            }
            .btn_primary,.btn_primary:hover, .btn_primary:focus, .btn_primary.focus {
                color: #fff !important;
            }
            .btn_secondary {
                background: #532e8f;
                color: #fff !important;
            }   
            img {
                max-width: 50%;
                margin: 0px auto;
                margin-top: 30px;
            }
            .mr10 { margin-right: 10px !important; }
                    
        }
    }
    .btnsection {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .btn {
        transition: all 0.2s linear;
        outline: none !important;
        border: 0;
        font-size: 16px;
        padding: 7px 20px;
        border-radius: 2px;
        height: 35px;
        background: transparent;
        margin: 10px;
        min-width: auto;
    }
    .btn_primary {
        background-color: #ec5578;
        position: relative;
    }
    .btn_primary,.btn_primary:hover, .btn_primary:focus, .btn_primary.focus {
        color: #fff !important;
    }
    .btn_secondary {
        background: #532e8f;
        color: #fff !important;
    }  
    .carousel-control-prev-icon,
    .carousel-control-next-icon{
        content: ' ';
        position: absolute;
        border-radius: 100%;
        height: 30px;
        width: 30px;
        text-align: center;
        font-size: 68px;
        padding-top: 3px;
        top: 40%;
        background-repeat: no-repeat;
    }
    .carousel-control-prev-icon{
        margin-left: 11%;
        transform: rotate(-90deg);
        background-image: url(../../assets/images/mentor/indicatearrow.svg);
    }
    .carousel-control-next-icon{
        margin-right: 11%;
        transform: rotate(90deg);
        background-image: url(../../assets/images/mentor/indicatearrow.svg);
    }
    .regeistersection:before {
        content: '';
        background-image: url(../../assets/images/mentor/register_bg.png);
        display: block;
        width: 100%;
        min-height: 100vh;
        position: absolute;
        left: 0px;
        top: 0px;
        background-repeat: no-repeat;
    }    
    .carouselSection:before {
        content: '';
        background-image: url(../../assets/images/mentor/cloud-1.png);
        display: block;
        width: 100%;
        height: 90vh;
        position: absolute;
        left: -150px;
        top: 60px;
        background-repeat: no-repeat;
    }
    .carouselSection:after {
        content: '';
        background-image: url(../../assets/images/mentor/cloud-2.png);
        display: block;
        width: 25%;
        height: 100vh;
        position: absolute;
        right:0px;
        top:100px;
        background-repeat: no-repeat;
    }
    .commonsection{
        padding: 80px 0px;
        .registerImage {
            max-width: 100%;
        }
    }
    .form-title {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        color: #000;
        margin-bottom: 20px;
    }
    .form-control {
        outline: none;
        box-shadow: 1px 1px 15px #e6e6e6 !important;
        border: 0px;
        border-radius: 0px;
        height: 45px;
    }
    .section_title {
        font-size: 30px;
        font-weight: 700;
    }
    
    .blinkcircle {
        list-style: none;
        line-height: 40px;
        padding: 0px;
    }
    .blinkcircle li{
        font-size: 16px;
        color: #adadad;
        position: relative;
        padding-left: 40px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .blinkcircle li:before{
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        background: #532e8f;
        border-radius: 100%;
        left: 8px;
        top: 11px;
    }
    .blinkcircle li:after{
        content: '';
        position: absolute;
        height: 22px;
        width: 22px;
        background: #532e8f;
        border-radius: 100%;
        left: 2px;
        top: 5px;
        opacity: 0.2;
    }
    .mentor-1 {
        margin-top: -170px;
        margin-left: -65px;
    }
    .mentor-2 {
        margin-left: -170px;
        margin-top: -150px;
    }
    .parastyle{
        font-size: 16px;
        color: #adadad;
        position: relative;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .breadcrumb {
        background: transparent;
        margin: 0;
        padding: 0;
        border-radius: 0;
        display: flex;
        justify-content: center;
    }
    .breadcrumb li.active {
        background: #48237e;
        color: #fff;
    }
    .breadcrumb>li+li:before{
        content: none;
    }
    .subcrumb li {
        border: 1px solid #f3f3f4;
    }
    .breadcrumb li {
        font-size: 16px;
        padding: 15px 20px;
        background: #efefef;
        cursor: pointer;
        color: #000;
        margin: 5px;
        border-radius: 5px;
    }
    .subcrumb2 {
        margin-left: -5px;
    }
    .onlineimg {
        max-width: 100%;
    }
    .onlineimg1{
        margin-top: 100px;
    }
    .onlineimg2{
        margin-top: 50px;
    }
    .onlineimg3{
        margin-top: -50px;
    }
    
    .registerprocess:before {
        content: '';
        background-image: url(../../assets/images/mentor/largecloud-1.png);
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 5%;
        background-repeat: no-repeat;
    }
    .registerprocess:after {
        content: '';
        background-image: url(../../assets/images/mentor/largecloud-2.png);
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 30%;
        background-repeat: no-repeat;
    }
    .leftprocess, .rightprocess{    
        margin-bottom:50px;
        width:50%;
    }
    .leftprocess,
    .leftprocess .processdetail,
    .leftprocess .numberslot,
    .leftprocess .processdetail {
        float: left;
    }
    .rightprocess,
    .rightprocess .processdetail,
    .rightprocess .numberslot,
    .rightprocess .processdetail {
        float: right;
    }
    .leftprocess .numberslot{
        margin-right: 20px;
    }
    .rightprocess .numberslot{
        margin-left: 20px;
    }
    .numberslot {
        background: #ec5578;
        color: #fff;
        font-size: 58px;
        border-radius: 15px;
        text-align: center;
        width: 100px;
        height: 100px;
        padding: 10px;
    }
    .processdetail {
        width: calc(100% - 130px);
        background: #fff;
        padding: 25px;
        box-shadow: 0px 0px 12px #ececec;
    }
    .processdetail h3{
        margin-top:0px;
        color:#532e8f;
        font-size: 20px;
    }
    .processdetail p{
        color:#000;
        font-size: 16px;
        margin-bottom:0px;
        margin-top: 15px;
    }
    .card {
        background: #fff;
        padding: 23px;
        box-shadow: 0px 0px 12px #ececec;
        text-align: center;
        margin-bottom:25px;
    }
    .cardimg img {
        max-width: 70%;
        max-height: 100%;
    }
    .card-header {
        margin-top: 30px;
        font-size: 20px;
        margin-bottom: 20px;
    }
    .custom-file {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-bottom: 0;
        outline: none;
        box-shadow: 1px 1px 15px #e6e6e6 !important;
        border: 0px;
        border-radius: 0px;
        height: 45px;
        cursor: pointer !important;
    }
    .custom-file-input {
        position: relative;
        z-index: 2;
        width: 100%;
        margin: 0;
        cursor: pointer !important;
        opacity: 0;
    }
    .custom-control-label::before, .custom-file-label, .custom-select {
        transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .custom-file-label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        padding: 5px 12px;
        font-weight: 400;
        line-height: 2.5;
        color: #ababab;
    }
    .custom-file-input:lang(en)~.custom-file-label::after {
        content: "Browse";
    }
    .custom-file-label::after {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        display: block;
        height: calc(1.5em + .75rem);
        padding: .375rem .75rem;
        line-height: 1.5;
        color: #495057;
        content: "Browse";
        background-color: #e9ecef;
        border-left: inherit;
        border-radius: 0 .25rem .25rem 0;
    }
    .section-block {
        position: relative;
        padding: 25px 0 0;
        background-color: #fff;
    }
    .contact-box-place-office.cbe-1 {
        background-image: url(../../assets/images/mentor/cbe-1.jpg);
    }
    
    .contact-box-place-office {
        float: left;
        width: 25%;
        position: relative;
        background: top center no-repeat #292929;
        padding: 20px 20px 20px 20px;
        border-radius: 0;
        margin-bottom: 0;
        min-height: 210px;
        background-size: cover;
    }
    .contact-box-place-office.blr-1, .contact-box-place-office.blr-2 {
        background-image: url(../../assets/images/mentor/blr-1.jpg);
    }
    .contact-box-place-office.blr-3 {
        background-image: url(../../assets/images/mentor/blr-3.jpg);
    }
    .contact-box-place-office h4 {
        color: #d2d2d2;
        font-size: 14px;
        font-weight: 700;
        margin-top: 10px;
        border-bottom: 1px solid #dcdcdc;
        padding-bottom: 10px;
        display: inline-block;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    .contact-box-place-office h6 {
        color: #d2d2d2;
        font-size: 13px;
        font-weight: 400;
        padding: 0;
        margin-top: 7px;
        min-height: 60px;
        line-height: 22px;
    }
    button.navbar-toggle {
        margin-top: 30px;
    }
    
    .margin0 { margin: 0px !important }

.mt0 { margin-top: 0px !important; }
.mt5 { margin-top: 5px !important; }
.mt10 { margin-top: 10px !important; }
.mt15 { margin-top: 15px !important; }
.mt20 { margin-top: 20px !important; }

.mr0 { margin-right: 0px !important; }
.mr5 { margin-right: 5px !important; }
.mr10 { margin-right: 10px !important; }
.mr15 { margin-right: 15px !important; }
.mr20 { margin-right: 20px !important; }

.mb0 { margin-bottom: 0px !important; }
.mb5 { margin-bottom: 5px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb20 { margin-bottom: 20px !important; }

.ml0 { margin-left: 0px !important; }
.ml5 { margin-left: 5px !important; }
.ml10 { margin-left: 10px !important; }
.ml15 { margin-left: 15px !important; }
.ml20 { margin-left: 20px !important; }

.left { float: left !important; }
.right { float: right !important; }
.rel { position: relative !important; }
.abs { position: absolute !important; }

.pad0 { padding: 0px !important; }
.pad5 { padding: 5px !important; }
.pad10 { padding: 10px !important; }
.pad15 { padding: 15px !important; }
.pad20 { padding: 20px !important; }

.tpad0 { padding-top: 0px !important; }
.bpad0 { padding-bottom: 0px !important; }
.lpad0 { padding-left: 0px !important; }
.rpad0 { padding-right: 0px !important; }

.clr,
.clr5,
.clr10,
.clr15,
.clr20 {
    clear: both;
    display: block;
    width: 100%;
}

.clr5 { height: 5px; }
.clr10 { height: 10px; }
.clr15 { height: 15px; }
.clr20 { height: 20px; }

html{overflow-x: hidden !important;}

.pointer { cursor: pointer !important; }
.block{
    display:block;
    width:100%;
}
}
.toast .toast-header {
    margin-top: 50px;
    z-index: 100 !important;
  }


  .modalHeader {
    background: #47c9a2;
    .modalImageWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        img {
            color: #fff;
            width: 95px;
            height: 95px;
            display: inline-block;
            border-radius: 50%;
            z-index: 9;
            border: 5px solid #fff;
            padding: 15px;
            text-align: center;
        }
    }
    button {
        position: absolute;
        top: 15px;
        right: 15px;
        color: #fff;
        text-shadow: none;
        opacity: 0.5;
    }
}

@media(max-width:1024px){
    .registerprocess:after{
        content: none;
    }
    .mentorScreen .mentor-1 {
        max-width: none;
    }
    .mentorScreen .carouselSection {
        min-height: 55vh !important;
    }

    .mentorScreen .headerNav .headerNavContainer {
        margin-left: 35px;
    }

    .mentorScreen .commonsection {
        padding: 10px;
    }
    .mentorScreen .mentor-1 {
        max-width: 200%;
        margin-left: -65px;
        margin-top: 0px;
    }
}
@media(max-width:991px){
    .mentor-1,
    .mentor-2,
    .onlineimg3{
        margin:0px;
    }
    .onlineimgdiv{
        text-align: center;
    }
    .leftprocess, .rightprocess {
        width: 85%;
    }
    .mentor-1,
    .mentor-2{
        max-width: 100%;
    }
    .mentorScreen .carouselSection {
        min-height: 55vh !important;
    }

    .mentorScreen .headerNav .headerNavContainer {
        margin-left: 35px;
    }

    .mentorScreen .commonsection {
        padding: 10px;
    }
    .mentorScreen .mentor-1 {
        max-width: 200%;
        margin-left: -65px;
        margin-top: 0px;
    }
    .mentorScreen .mentor-2 {
        margin-left: -170px;
        margin-top: 0;
        max-width: 220%;
    }
    .mentorScreen .processdetail {
        width: 100%;
        margin-top: 10px;
    }
    .navbar-nav .btn {
        width: 100px;
        height: 42px;
        border-radius: 4px;
        border: 1px solid $primaryColor;
        color: $primaryColor !important;
        margin-top: 1rem;
      }
}
@media(max-width:768px){
    .leftprocess, .rightprocess {
        width: 100%;
    }
    .cardimg img {
        max-width: 30%;
        max-height: 100%;
    }
    
    .corporate-header .navbar-nav {
        float: left !important;
        width: 100%;
        text-align: left;
        margin: 0px;
    }
    .contact-box-place-office {
        width: 100%;
        min-height: auto;
    }
    .owl-carousel .owl-item img {
        display: none;
    }
    .breadcrumb li {
        width: 100%;
        margin-bottom: 10px;
    }
    .mentorScreen .carouselSection .carouselContainer img {
        display: none;
    }
    .mentorScreen .commonsection {
        padding: 0px;
        padding-top: 15px;
    }
    .mentorScreen .onlineimg1 {
        margin-top: 0px;
        margin-bottom: 50px;
    }
    .mentorScreen .mentor-2 {
        margin-left: -170px;
        margin-top: 0;
        max-width: 220%;
      }
    .mentorScreen .processdetail {
        width: 100%;
    }
    .mentorScreen .headerNav {
        width: 100%;
      }
}
@media(max-width:767px){
    .row {
        margin-right: 0px;
        margin-left: 0px;
    }
    .numberslot{
        display: none;
    }
    .processdetail {
        width: 100%;
    }
    .mentorScreen .carouselSection {
        min-height: 25vh !important;
    }

    .mentorScreen .leftprocess {
        width: 100%;
    }

    .mentorScreen .rightprocess {
        width: 100%;
    }

    .mentorScreen .processdetail {
        width: 100%;
    }
    .mentorScreen .mentor-1 {
        margin-top: auto;
        margin-left: auto;
        max-width: 115%;
    }
    .mentorScreen .mentor-2 {
        display: none;
    }
    .mentorsection_1 .commonsection {
        margin-top: 5px;
    }
    .mentorScreen .headerNav {
        width: 100%;
      }
    
}
