@import "assets/scss/config.scss";
@import "assets/scss/main.scss";
.skillSection{ 
  @include screenPaddingTop;
  margin-top: 1em;
}
.numberSection {
  padding: 20px 0px;
}
.center-navs {
  text-align: center;
  .nav-pills {
    border: 0px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
    padding: 20px;
    display: inline-flex;
    .nav-link.active,
    .show > .nav-link {
      color: #fff;
    }
    .nav-link {
      color: $secondaryColor;
      padding: 10px 25px;
      border-radius: 0.25rem;
      white-space: nowrap;
      &:hover {
        border-color: transparent;
      }
    }
  }
}
.clr20 {
  clear: both;
  height: 20px;
}

.program-group {
  margin-top: 3rem;
}

.skillcol .nav-link {
  padding: 0px;
}
.skillcol{
  @include for_media(mobileScreen) {
    padding: 0;
  }
}
.slick-slider {
  margin: 0px -15px;
}

.programImage {
  height: 165.683px;
}

.courseDesp {
  font-size: medium;
}
@media screen {
  .courseDesp {
    font-size: large;
  }
}
.programRow {
  display: flex;
  justify-content: center;
}
