@import "assets/scss/config.scss";
body {
  font-family: $pageFont !important;
}

.blog-modal {
  .modal-dialog {
    max-width: 85%;
  }

  .modal-body {
    padding: 2.1rem;
  }
}

.blogLink {
  a {
    color: $darkColor;
    font-size: $font16px;
    margin-bottom: 1rem;
    text-decoration: none !important;
  }

  span {
    margin: 0px 1rem;
  }
}

.blogTitle {
  color: $darkColor;
  font-size: $font34px;
  line-height: 51px;
  font-weight: bold;
}

.blogType {
  display: flex;
  justify-content: space-between;

  label {
    margin: 0px;
    color: $darkColor;
    font-size: $font16px;
  }
  .bulletin:before{
    content: "•";
    color: #888888;
    margin: 0px 5px;
  }
}

.blogsubtitle {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 30px;
  color: black;
  margin-bottom: 8px;
}

b.blogsubpara {
  color: $darkColor;
  margin: 1rem 0px;
  display: inline-block;
}

.blogsubpara {
  color: $paraColor;
  font-size: $font16px;
}

.commonArticle {
  margin: 2rem 0px;

  &:last-child {
    margin-bottom: 1rem;
  }
}

.blogImage {
  position: relative;

  img {
    width: 100%;
    border-radius: 1rem;
  }
}


.blogfinal {
  margin: 2rem 0px;

  img {
    width: 100%;
  }
}

.musicimages img {
  margin: 1rem 0px;
}

.blogsubparahighlight {
  font-size: $font28px;
  color: $darkColor;
  padding: 20px;
  margin: 2rem 0px;
  background: $bodyBg;
  font-weight: 100;
}

.factsDiv {
  background: $bodyBg;
  padding: 1.5rem;
  border-radius: 4px;

  .blogsubpara {
    font-size: 16px;
    margin-bottom: 0px;
    color: black;
  }
  &.factsTop {
    margin: 1rem 0px;
  }
}

.balloonimg,
.balloontext {
  margin-top: 1rem;
}

.blogTags {
  // font-style: italic;
  font-size: $font16px;
  color: $paraColor;
  margin-top: 1rem;
}

.blogClose {
  width: 23px;
  background-position: -190px -119px;
  position: fixed;
  right: 6%;
  top: 8%;
  cursor: pointer;
}

.closebtn {
  position: fixed;
  bottom: 1rem;
  padding: 10px;
  min-width: auto;
  height: 40px;
  z-index: 1;
  left: 43%;
}

.planSection {
  padding-bottom: 4rem;
}

.blogOuterTitle {
  margin-bottom: 2rem;
}

.assessImg {
  text-align: center;
  margin: 3rem 0px;
}

.honeyComb {
  background-color: #1a4171;
  background-image: url(/assets/images/niche-it-skill-training-programs/honeycomb.png);
  display: flex;
  align-items: center;
  padding: 1.5rem 2.5rem;
  border-radius: 8px;
  margin: 3rem 0px;

  span {
    color: #fff;
    font-size: $font40px;
    margin-left: 1rem;
  }
}

.article-col-2 .blogTitle {
  margin: 1rem 0px;
}
.shared-links{
  text-align: left;
  img{
    height: 35px;
    margin-right: 10px;
  }
}

.codeVideo {
  max-width: 100%;
}

.schoolTuitionBlog,
.corporateBlog {
  .slick-slide img {
    border-radius: 8px;
  }
  .pgmlistsection {
    padding-top: 10px;
  }
}

.article-row .blogImage {
  margin-top: 2rem;
}

.schoolTuitionBlog {
  .commonsection.learnerTestimonial {
    padding: 30px;

    .sectionTitleMedium {
      margin: 0px;
    }

    .learnerCard {
      flex-direction: column;
      margin: 15px auto;
      width: 92% !important;
      box-shadow: none;
      border: 1px solid #e7e7e7;
      align-items: flex-start;
    }
  }

  .custom-arrows .slick-arrow {
    &.slick-next {
      right: -20px;
    }

    &.slick-prev {
      left: -20px;
      right: inherit;
      z-index: 1;
    }
  }

  .learnerCard {
    .learnerBottom {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      .learnerImage img {
        height: 40px;
        max-width: 40px;
        flex: 0 0 40px;
        margin-left: 0px;
        margin-right: 15px;
      }

      .name-cmp {
        p {
          margin: 0px;
          font-size: $font14px;
          font-weight: bold;
          color: $darkColor;
        }
      }
    }

    .name-cmp .cmp {
      margin: 0px;
    }
  }
}

.blogMonial {
  background: $bodyBg;
  border-radius: 4px;
}

.formDesign {
  padding: 15px 30px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
  position: sticky;
  top: 10px;

  .request-formData .label {
    color: #7c7ca2;
    font-size: 0.75rem;
    line-height: 21px;
  }

  .form-group .form-control {
    height: 38px;
  }

  .formtexts .formPara {
    width: 100%;
  }
}

.playIcon {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .playBg {
    background: #067bc2cf;
    height: 120px;
    width: 120px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    img {
      height: 50px;
      width: 40px;
    }
  }
}

.videoTime {
  font-size: 1.25rem;
  color: #fff;
  background: #0000008a;
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 0px 8px;
  border-radius: 4px;
}

.sliders {
  .slick-slider {
    margin: 0px;
    margin-bottom: 2rem;
  }
  .slick-arrow {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .blog-modal .modal-dialog {
    max-width: 95%;
  }

  .formDesign .formtexts .formTitle {
    font-size: $font16px;
  }
  .playIcon .playBg {
    transform: scale(0.5);
  }

  .blogTitle {
    font-size: $font24px;
    line-height: 1.5;
  }

  .blogType {
    margin: 1rem 0px;
  }

  .videoTime {
    font-size: $font12px;
  }

  .blogImage {
    img {
      width: 100%;
    }
  }

  .blogsubtitle {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 30px;
    color: black;
    margin-bottom: 8px;
  }

  .factsDiv .blogsubpara,
  .blogsubpara,
  .blogsubparahighlight {
    font-size: 18px;
    color: $darkColor;
    line-height: 1.5;
  }

  .blogsubparahighlight {
    margin: 1rem 0px;
  }

  .musicimages {
    text-align: center;

    img {
      margin: 1rem 0px;
    }
  }

  .factsDiv {
    padding: 1rem 1rem;
  }

  .blogOuterTitle {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .assessImg {
    margin: 1.5rem 0px !important;
  }

  .honeyComb {
    flex-wrap: wrap;
    padding: 1.5rem 1.5rem;

    span {
      margin: 0px;
      margin-top: 1rem;
      line-height: 1.5;
      font-size: $font24px;
    }
  }

  .schoolTuitionBlog {
    .commonsection.learnerTestimonial {
      padding: 20px;
    }
  }
}

.schoolTuitionBlog {
  .clr {
    clear: both;
    display: block;
    width: 100%;
    height: 1px;
  }
}


@media only screen and (max-width: 768px){
  .blogsubtitle{
    font-size: $font20px;
    line-height: 1.5;
  }
  .factsDiv {
    padding: 1rem;
  }
  .article-row .blogImage {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .blogType{
    display: block;
    .labelDiv{
      text-align: left;
      label {
        margin: 0px;
        color: $darkColor;
        font-size: $font16px;
      }
    }
   .bulletin:before{
      content: "•";
      color: #888888;
      margin: 0px 5px;
    }
    .shared-links{
      text-align: left;
    }
  }
}